import React, { useEffect, useState } from 'react'
import { Title, Row, Col, Button, Toggle, Textarea, Text, Alert, Badge } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFax,
  faLevelUpAlt,
  faMobile,
  faPhone,
  faTimes,
  faTty,
  faExclamation,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { isValidServiceProviderPhoneNumber, SPUDInputField } from '../../helpers/record'
import styled from 'styled-components'
import { AngledArrowContainer } from './AngledArrowContainer'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'

export type ContactType = {
  comment?: string,
  confidential: boolean
  phone_type: 'phone'|'freecall'|'mobile'|'email'|'fax'|'tty',
  number: string
}

export type SPUDContactProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  highlight?: boolean,
  disabled: boolean,
}

type ContactFieldTypes = {
  phone_type: 'phone'|'freecall'|'mobile'|'email'|'fax'|'tty',
  icon: IconDefinition,
  label: string,
  comment: boolean,
}

const CONTACT_TYPES: Array<ContactFieldTypes> = [
  { phone_type: 'phone', icon: faPhone, label: 'Telephone', comment: true },
  { phone_type: 'freecall', icon: faPhone, label: 'Free Call', comment: false },
  { phone_type: 'mobile', icon: faMobile, label: 'Mobile', comment: true },
  { phone_type: 'fax', icon: faFax, label: 'Fax', comment: false },
  { phone_type: 'tty', icon: faTty, label: 'TTY', comment: false },
]

const SPUDContactCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const ContactCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContactConfidentialToggle = styled(Toggle)`
  label {
    padding: 0;
  }
`
function SPUDContact ({ title, siteValues, recordType, highlight, disabled }: SPUDContactProps): React.ReactElement {
  const { control, getValues, setValue, formState: { errors } } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phones',
  })

  const [siteContacts, setSiteContacts] = useState<Array<{
    number: string,
    comment?: string
  }>>([])

  let disableAutoFocus = true
  const formErrors = errors as {
    phones?: Array<{
      number: {
        message: string
      }
    }>
  }

  useEffect(() => {
    if (recordType === 'service' && siteValues?.phones) {
      setSiteContacts(siteValues?.phones)
    } else {
      disableAutoFocus = false
      setSiteContacts([])
    }
  }, [siteValues?.phones, recordType])

  const RequiredBadge = () => {
    if (!getValues('phones.0.number') && highlight) {
      return <Badge danger content={<FontAwesomeIcon icon={faExclamation as IconProp}/>}/>
    }
    return null
  }

  const showComment = (contact: string): boolean => {
    return CONTACT_TYPES.find(type => type.phone_type === contact)?.comment || false
  }

  const formatContactLabel = (contact: string) => {
    if (contact) {
      if (contact === 'tty') {
        return 'TTY'
      } else if (contact === 'freecall') {
        return 'Free Call'
      } else {
        return contact.charAt(0).toUpperCase() + contact.slice(1)
      }
    }
    return ''
  }

  return (
    <div>
      <Row>
        <Col direction='row'>
          <Title level={4}>
            {title}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row align='center'>
            <Title level={5} marginTop='0'>Contact:</Title>
            <div style={{ margin: '0 10px' }}>
              {CONTACT_TYPES.map(contactType => (
                <Button
                  key={contactType.label}
                  id={contactType.label}
                  onClick={() => {
                    append({
                      confidential: false,
                      comment: '',
                      phone_type: contactType.phone_type,
                      number: '',
                    }, {
                      shouldFocus: false,
                    })
                  }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={contactType.icon as IconProp}/>
                  <Text margin='0 2em 0 4px'>{contactType.label}</Text>
                  {contactType.phone_type === 'phone' && <RequiredBadge/>}
                </Button>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
      {fields.map((field, index) => (
        <Row key={field.id} align='center'>
          <Col direction='row'>
            <AngledArrowContainer>
              <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
            </AngledArrowContainer>
            <SPUDContactCard>
              <ContactCardHeader>
                <Controller
                  name={`phones.${index}.confidential`}
                  control={control}
                  rules={{ required: false }}
                  render={() =>
                    <ContactConfidentialToggle
                      padding='0'
                      label={<Title level={4} marginTop='0'>Confidential</Title>}
                      offColor="#E3EDFA"
                      handleChange={(checked: boolean) => {
                        setValue(`phones.${index}.confidential`, checked)
                      }}
                      checked={getValues(`phones.${index}.confidential`) || false}
                      onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                        const checked = getValues(`phones.${index}.confidential`) || false
                        if (key.key === 'Enter') {
                          setValue?.(`phones.${index}.confidential`, !checked)
                        }
                      }}
                      disabled={disabled}
                    />
                  }
                />
                <Button onClick={() => remove(index)} active={!disabled}>
                  <FontAwesomeIcon icon={faTimes as IconProp}/> Remove
                </Button>
              </ContactCardHeader>
              <Row>
                <Col>
                  <Controller
                    name={`phones.${index}.number`}
                    control={control}
                    rules={{ validate: isValidServiceProviderPhoneNumber }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='number'
                        placeholder="Number"
                        type='text'
                        highlight={highlight && (index === 0 && !getValues('phones.0.number'))}
                        label={<Title level={4}>
                          {formatContactLabel(getValues(`phones.${index}.phone_type`))} Number
                        </Title>}
                        fullwidth
                        // to prevent the form scrolling down
                        // when adding a new Service from a site
                        // we need to disable autofocus
                        autoFocus={!disableAutoFocus}
                        {...field}
                      />
                    }
                  />
                  {
                    siteContacts?.[index]?.number &&
                    <Text>Site number {index + 1}: {siteContacts?.[index]?.number}</Text>
                  }
                  {formErrors?.phones?.[index]?.number &&
                    <Alert type="error" aria-label={getValues(`phones.${index}.phone_type`)}>
                      {formErrors.phones[index].number.message}
                    </Alert>}
                  {showComment(getValues(`phones.${index}.phone_type`)) && (
                    <>
                      <Controller
                        name={`phones.${index}.comment`}
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) =>
                          <>
                            <Title level={4}>{recordType === 'service' ? 'Contact info' : 'Comment'}</Title>
                            <Textarea
                              {...field}
                            />
                          </>
                        }
                      />
                      {
                        siteContacts?.[index]?.comment &&
                        <Text>
                          Site {
                            recordType === 'service' ? 'contact info' : 'comment'
                          } {index + 1}: {siteContacts?.[index]?.comment}</Text>
                      }
                    </>
                  )}
                </Col>
              </Row>
            </SPUDContactCard>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default SPUDContact
