import React, { useState } from 'react'
import { Col, Container, Header, Row } from '@ix/ix-ui'
import axios, { AxiosPromise } from 'axios'
import { SPUDRecord } from '../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../@types/Service.type'
import QuickSearchList from './QuickSearchList'
import styled from 'styled-components'
import _ from 'lodash'
import { SPUDQuickSearchButton, SPUDQuickSearchInputField } from './SearchResultsPage'

type SPUDHeadingProps = {
  searchRecords: (
    searchTerm: string,
    params: {
      seeMore: boolean,
      limit: number,
      offset: number,
    }
  ) => AxiosPromise<{
      results: Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>,
      next: string | null,
      count: number,
  }>
}
const QuickSearchListWrapper = styled.div`
  position: relative;
`

function SPUDHeading ({ searchRecords }: SPUDHeadingProps) {
  const [returnedValues, setReturnedValues] = useState<
    {
      [x: string]:Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>
    }>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [totalNoRecords, setTotalNoRecords] = useState(0)

  const searchForRecords = async () => {
    setShowResults(false)
    setLoading(true)
    setReturnedValues({})
    try {
      const response = await searchRecords(searchTerm, {
        seeMore: false,
        limit: 150,
        offset: 0,
      })
      setTotalNoRecords(response.data.count)
      setLoading(false)
      const filteredResponse = response.data.results.filter(result => result.update?.data.name)
      const groupedRecords = _.groupBy(filteredResponse, 'record_type')
      setShowResults(true)
      setReturnedValues(groupedRecords)
    } catch (error) {
      // If it's not an aborted request we stop it from loading
      if (!axios.isCancel(error)) {
        setLoading(false)
      }
    }
  }

  const onDismiss = () => {
    setShowResults(false)
    setLoading(false)
  }

  return <div>
    <Header style={{ padding: '1em 0.75em', margin: '1em' }}>
      <Container style={{ paddingRight: '0' }}>
        <Row>
          <Col direction='row' justify='center'>
            <SPUDQuickSearchInputField
              type='text'
              value={searchTerm}
              name='global-quick-search'
              placeholder="Search by name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setLoading(false)
                  setReturnedValues({})
                  setShowResults(false)
                }
                setSearchTerm(event.target.value)
              }}
              fullwidth
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  searchForRecords()
                }
              }}
              onClick={() => {
                if (Object.keys(returnedValues).length) {
                  setShowResults(true)
                }
              }}
            />
            <div style={{ margin: '7px' }}>
              <SPUDQuickSearchButton
                onClick={() => searchForRecords()}
                loading={loading}
                disabled={!searchTerm}
              >
                Quick search
              </SPUDQuickSearchButton>
            </div>
          </Col>
        </Row>
        <QuickSearchListWrapper>
          {showResults &&
            <QuickSearchList
              searchResults={returnedValues}
              loading={loading}
              onDismiss={onDismiss}
              totalNoRecords={totalNoRecords}
              searchTerm={searchTerm}
            />}
        </QuickSearchListWrapper>
      </Container>
    </Header>
  </div>
}

export default SPUDHeading
