import { FormConfig, Option, Payload } from './AddEditRecord.type'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { spudAPI } from '../../services/spud.service'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const SERVICE_FORM_ELEMENTS: Array<FormConfig> = [
  {
    title: 'Essential',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faList as IconProp} />,
    fields: [
      {
        name: 'date_last_updated',
        label: 'Last updated date',
        type: 'hidden',
        defaultValue: '',
        rules: { required: false },
      },
      {
        name: 'name',
        label: 'Service Name',
        type: 'text',
        rules: { required: true },
        defaultValue: '',
        highlight: true,
        hideAsterisk: true,
        bulkUpdateEnabled: true,
      },
      {
        name: 'datasets',
        label: 'Dataset',
        type: 'multiselect',
        bulkAddEnabled: true,
        rules: {
          required: {
            value: true,
            message: 'Please select at least one database to which this service should export.',
          },
        },
        highlight: true,
        defaultValue: [],
        optionFunction: spudAPI.fetchDatasets,
        asyncParams: { recordType: 'service' },
      },
      {
        name: 'description',
        label: 'Service Description',
        type: 'textarea',
        rules: { required: true },
        defaultValue: '',
        canShowLineBreaks: true,
        highlight: true,
        bulkUpdateEnabled: true,
      },
      {
        name: 'crisis_keywords',
        label: 'Crisis Keywords',
        type: 'text',
        rules: { required: false },
        defaultValue: '',
        authorisedUserTypes: ['Administrator'],
      },
      {
        name: 'funding_body',
        label: 'Funding Body',
        type: 'text',
        rules: { required: false },
        defaultValue: '',
        hideAsterisk: true,
        bulkUpdateEnabled: true,
      },
      {
        name: 'eligibility_info',
        label: 'Eligibility',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'ineligibility_info',
        label: 'Ineligibility',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'service_types',
        label: 'Service Types',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDServiceTypeClassification',
          props: {
            title: 'Service Types',
          },
        },
      },
      {
        name: 'age_groups',
        label: 'Age Groups',
        type: 'multiselect',
        rules: { required: false },
        defaultValue: [],
        optionFunction: spudAPI.fetchAgeGroups,
        // Because the Age group data structure relies on the description
        // to display detailed information about the age_groups,
        // value that gets displayed in the dropwdown should reflect that.
        formatDisplayName: <T extends Record<string, string>>(value : Option & T): string => {
          return value.description || value.name
        },
        // Because the value that is set must be the 'name' of the age group we need to covert it back
        // to the name.
        // for e.g. Adult is displayed in dropdown as "25 - 40 years (adult)" but should be set against the record
        // as "Adult"
        formatValueName: (value: Option): string => {
          return value?.id?.toString() || value.name
        },
      },
      {
        name: 'target_gender',
        label: 'Gender',
        type: 'dropdown',
        rules: { required: false },
        defaultValue: 'unspecified',
        options: [
          { name: 'Female', id: 'female' },
          { name: 'Male', id: 'male' },
          { name: 'Transgender', id: 'other' },
          { name: 'No specific gender selected', id: 'unspecified' },
        ],
      },
      {
        name: 'indigenous_classification',
        label: 'Indigenous Classification',
        type: 'dropdown',
        rules: { required: false },
        defaultValue: 'mainstream',
        options: [
          { name: 'Aboriginal (indigenous) specific', id: 'specific' },
          { name: 'Culturally safe for Aboriginal (indigenous)', id: 'culturally_safe' },
          { name: 'Mainstream who cater for Aboriginal (indigenous)', id: 'mainstream_who_cater' },
          { name: 'No Indigenous classification selected', id: 'mainstream' },
        ],
      },
      {
        name: 'is_cald_specific',
        label: 'CALD Specific',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'is_lgbtiqa_plus_specific',
        label: 'LGBTIQA+ Specific',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'is_ndis_approved',
        label: 'NDIS Approved',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'languages',
        label: 'Languages',
        type: 'multiselect',
        rules: { required: false },
        defaultValue: [],
        optionFunction: spudAPI.fetchLanguages,
      },
    ],
  },
  {
    title: 'Contact',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faList as IconProp}/>,
    fields: [
      {
        name: 'phones',
        label: 'Contact',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDContact',
          props: {
            title: 'Contact Information',
            socialFormKey: 'social_media',
            contactFormKey: 'phones',
            recordType: 'service',
          },
        },
      },
      {
        name: 'emails',
        label: 'Emails',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDEmails',
          props: {
            title: 'Emails',
            recordType: 'service',
          },
        },
      },
      {
        name: 'web',
        label: 'Website',
        type: 'text',
        rules: {
          required: false,
          pattern: {
            value: /(www\.|http:\/\/|https:\/\/)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
            message: 'That does not look like a valid web address, please try again.',
          },
        },
        defaultValue: '',
        hideAsterisk: true,
      },
      {
        name: 'social_media',
        label: 'Socials',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDSocials',
          props: {
            title: 'Social Media',
            recordType: 'service',
          },
        },
      },
      {
        name: 'opening_hours',
        label: 'Opening Hours',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDOpeningHours',
          props: {
            title: 'Opening Hours',
            recordType: 'service',
          },
        },
      },
      {
        name: 'operates_after_hours',
        label: 'After Hours',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'availability',
        label: 'Availability',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
    ],
  },
  {
    title: 'Operational',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faList as IconProp}/>,
    fields: [
      {
        name: 'is_statewide',
        label: 'Statewide',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'catchment_description',
        label: 'Catchment Description',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
        canShowLineBreaks: true,
      },
      {
        name: 'catchment',
        label: 'Geographic Coverage',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDGeographicCoverage',
          props: {
            title: 'Geographic Coverage',
            recordType: 'service',
          },
        },
      },
      {
        name: 'referral_info',
        label: 'Referral Info',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'special_requirements',
        label: 'Special Requirements',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'assessment_criteria',
        label: 'Assessment Criteria',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'intake_info',
        label: 'Intake Information',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'intake_point',
        label: 'Intake Point',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'cost',
        label: 'Cost',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'is_free_or_low_cost',
        label: 'Free or Low Cost',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'is_bulk_billing',
        label: 'Provides Bulk Billing',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'accepts_healthcare_cards',
        label: 'Accepts Health Care Cards',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'billing_method',
        label: 'Billing Method',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        bulkUpdateEnabled: true,
      },
      {
        name: 'location_type',
        label: 'Location Type',
        type: 'dropdown',
        rules: { required: false },
        defaultValue: null,
        options: [
          { name: 'Permanent', id: 'Permanent' },
          { name: 'Mobile', id: 'Mobile' },
          { name: 'Temporary', id: 'Temporary' },
          { name: 'Statewide', id: 'Statewide' },
          { name: 'National', id: 'National' },
        ],
      },
      {
        name: 'location_expiry',
        label: 'Temporary Until',
        type: 'date',
        dateFormat: 'dd/MM/yyyy',
        rules: { required: false },
        defaultValue: '',
      },
      {
        name: 'accreditation',
        label: 'Accreditation',
        type: 'customComponent',
        rules: { required: false },
        defaultValue: [],
        customComponent: {
          name: 'SPUDAccreditation',
          props: {
            title: 'Accreditation',
            formKey: 'accreditation',
          },
        },
      },
      {
        name: 'practitioner_gender',
        label: 'Practitioner Gender',
        type: 'radio',
        rules: { required: false },
        defaultValue: null,
        options: [
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' },
          { id: 'unspecified', name: 'Unspecified' },
        ],
      },
      {
        name: 'show_in_askizzy_health',
        label: 'Show in Ask Izzy Categories',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
      {
        name: 'sa_priority_service',
        label: 'SA Priority Service',
        type: 'toggle',
        rules: { required: false },
        defaultValue: false,
      },
    ],
  },
  {
    title: 'Legacy',
    ref: React.createRef(),
    icon: <FontAwesomeIcon icon={faList as IconProp} />,
    fields: [
      {
        name: 'details',
        label: 'Details',
        type: 'textarea',
        rules: { required: false },
        defaultValue: '',
        canShowLineBreaks: true,
      },
      // The Following field is added as hidden,
      // due to the ISS4 field requirement
      {
        name: 'postal_addresses',
        label: 'Postal Address',
        type: 'hidden',
        defaultValue: [],
        rules: { required: false },
        hideFromExport: true,
      },
    ],
  },
]

export const SERVICE_FORM_DATA: Payload = {
  record_type: 'service',
  iss_id: null,
  status: 'draft',
  data: {},
}
