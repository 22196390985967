import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, PopoutMenu, PopoutList, Title, Text } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisH,
  faSave,
  faTrash,
  faCopy,
  faCheckCircle,
  faBars,
  faTrashRestore,
  faExclamationTriangle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { getScrollPosition } from '../../effects/scrollPosition'
import { format, isDate } from 'date-fns'
import { IsMobile } from '../../effects/IsMobile'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDToggle } from '../../helpers/record'
import { formatDatetimeToLocal } from '../../helpers/datetime'
import DatePicker from 'react-datepicker'
import { withContext } from '../../context/AppContext'
import { DialogOptions } from '../../context/AppContext.type'
import { useAuth } from '../../helpers/auth'
import { FormState } from 'react-hook-form/dist/types/form'

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #3a8ae8;
`

const ControlBarContainer = styled.div<{ sticky: boolean }>`
  background-color: #E3EDFA;
  width: inherit;
  padding: 1em;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  > button {
    font-size: 14px
  }
  transition: background-color 0.05s ease;
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  z-index: 2;
  margin-left: 0;
  top: 0;
  height: 75px;
`

const RightHandSideControls = styled.div<{showRightPanel: boolean}>`
  display: flex;
  width: 53em;
  justify-content: ${props => props.showRightPanel ? 'space-between' : 'flex-end'};
  align-items: center;
`

const LeftHandSideControls = styled.div`
  display: flex;
  width: 26em;
  justify-content: flex-start;
  align-items: center;
`

const SavedDateText = styled.div`
  font-size: 14px;
  color: #808080;
`

type Props = {
  onSaveRecord: () => void
  onShowRightPanel: (show: boolean) => void
  onSubmitForReview: () => void
  onPublish: () => void
  onCanChangeStatus: (changeStatus: boolean) => void
  recordId?: number | null | string,
  lastUpdated: Date | undefined,
  loading: boolean,
  pageLoading: boolean,
  hasSaved: boolean,
  changeStatus: boolean,
  isNewRecord: boolean,
  formState: FormState<Record<string, unknown>>,
  setDialogOptions?: (options: DialogOptions) => Dispatch<SetStateAction<DialogOptions>>,
  onUpdateToggle: (validated: boolean) => void
  status: string | undefined,
  recordType: string | undefined,
  onLastUpdateChange: (validated: boolean, date: Date) => void
  onDeleteRecord: () => void,
  onRestoreRecord: () => void,
  onCopyRecord: () => void,
  canRecordBePublished: () => boolean,
  actionButtonsDisabled: {
    copy: boolean,
    delete: boolean,
    restore: boolean,
    eReferral: boolean,
    active_iss_entities: Array<{
      id: string,
      name: string
    }>
  },
  active: boolean,
  actionButtonValidationLoading: boolean,
}

const PublishButton = styled(Button)<{showRightPanel: boolean}>`
  margin: 10px 0x;

  @media (max-width: 1300px){
    margin: ${props => props.showRightPanel ? '0px' : '10px 0px'};
  }
`

function AddEditRecordControlBar (
  {
    onSaveRecord,
    onSubmitForReview,
    onPublish,
    onCanChangeStatus,
    recordId,
    loading,
    pageLoading,
    onShowRightPanel,
    hasSaved,
    changeStatus,
    formState,
    setDialogOptions,
    isNewRecord,
    onUpdateToggle,
    lastUpdated,
    onLastUpdateChange,
    status,
    recordType,
    onCopyRecord,
    onDeleteRecord,
    onRestoreRecord,
    actionButtonsDisabled,
    canRecordBePublished,
    active,
    actionButtonValidationLoading,
  }: Props): React.ReactElement {
  const scrollPosition = getScrollPosition()
  const AddEditRecordControlBarRef = useRef<HTMLDivElement>(null)
  const [showRightPanel, setShowRightPanel] = useState(false)

  const isMobile = IsMobile()

  const [savedDate, setSavedDate] = useState<Date | undefined>()
  const [toggleUpdateDate, setToggleUpdateDate] = useState(false)

  const { userRole, updaterThatCanPublish } = useAuth()
  const { errors } = formState
  useEffect(() => {
    if (hasSaved) {
      setSavedDate(new Date())
      setToggleUpdateDate(false)
    }
  }, [hasSaved])

  useEffect(() => {
    if (isNewRecord || pageLoading) {
      setToggleUpdateDate(false)
      setSavedDate(undefined)
    }
  }, [isNewRecord, pageLoading])

  const disableButtons = pageLoading || (!!(recordId && !active))

  useEffect(() => {
    if (changeStatus && setDialogOptions && recordId && !Object.keys(errors).length) {
      if ((userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater') {
        if (canRecordBePublished()) {
          setDialogOptions({
            onConfirm: onPublish,
            onDismiss: () => {
              onCanChangeStatus(false)
            },
            title: !toggleUpdateDate ? 'Publish record' : 'Publish record and change update date',
            description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
              <li>{!toggleUpdateDate
                ? 'Are you sure you want to publish?'
                : 'Are you sure you want to publish and change the update date'
              }
              </li>
              {!toggleUpdateDate && <li><strong>Note: the update date has not changed</strong></li>}
            </ul>,
            show: true,
            type: 'confirm',
          })
        } else {
          setDialogOptions({
            onConfirm: () => {
              onCanChangeStatus(false)
            },
            onDismiss: () => {
              onCanChangeStatus(false)
            },
            title: 'Record can\'t be published',
            description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
              <li aria-label={`failed to publish ${recordType}`}>
                {recordType === 'site' && 'The organisation selected has not been published'}
                {recordType === 'service' && 'The linked site has not been published'}
              </li>
            </ul>,
            show: true,
            type: 'acknowledge',
          })
        }
      } else {
        setDialogOptions({
          onConfirm: onSubmitForReview,
          onDismiss: () => {
            onCanChangeStatus(false)
          },
          title: !toggleUpdateDate ? 'Submit for review' : 'Submit for review and change update date',
          description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
            <li>{!toggleUpdateDate
              ? 'Are you sure you want to submit for review?'
              : 'Are you sure you want to submit for review and change update date?'
            }</li>
            {!toggleUpdateDate && <li><strong>Note: the update date has not changed</strong></li>}
          </ul>,
          show: true,
          type: 'confirm',
        })
      }
    } else {
      onCanChangeStatus(false)
    }
  }, [changeStatus, Object.keys(errors).length])

  return (
    <ControlBarContainer ref={AddEditRecordControlBarRef} sticky={scrollPosition > 100}>
      <LeftHandSideControls>
        <Button
          active={!disableButtons}
          disabled={disableButtons}
          onClick={() => {
            onCanChangeStatus(false)
            onSaveRecord()
          }}
          loading={loading}
        >
          {!loading && <FontAwesomeIcon icon={faSave as IconProp}/>}  Save
          {((userRole === 'Updater' && !updaterThatCanPublish) || (!status || status === 'draft')) && ' (draft)'}
        </Button>
        <SavedDateText>
          {savedDate && `Saved ${format(savedDate, 'h:mm aaa')}`}
        </SavedDateText>
      </LeftHandSideControls>
      <RightHandSideControls showRightPanel={isMobile}>
        {toggleUpdateDate && (
          <div style={{ width: '23em' }}>
            <CustomDatePicker
              selected={(lastUpdated && new Date(lastUpdated))}
              dateFormat='dd/MM/yyyy hh:mm aaa'
              name='Updated date datepicker'
              maxDate={new Date()}
              showTimeSelect
              onChange={(date: Date) => {
                if (date && isDate(date)) {
                  onLastUpdateChange(false, date)
                }
              }}
            />
          </div>
        )}
        {!toggleUpdateDate && !pageLoading && !isNewRecord &&
          <Text aria-label='Update date'>{formatDatetimeToLocal(lastUpdated?.toString())}</Text>}
        <SPUDToggle
          label={<Title level={4} marginTop='0px'>Updated</Title>}
          disabled={!recordId || pageLoading || !active}
          checked={toggleUpdateDate} handleChange={(checked: boolean) => {
            setToggleUpdateDate(checked)
            onUpdateToggle(checked)
          }}
          onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
            const checked = toggleUpdateDate
            if (key.key === 'Enter') {
              setToggleUpdateDate(!checked)
              onUpdateToggle(!checked)
            }
          }}
          offColor="#F4F6F9"
        />
        <PublishButton
          showRightPanel={showRightPanel}
          active={!disableButtons}
          disabled={!recordId || disableButtons}
          title='The form will be validated prior to submission'
          onClick={() => {
            onCanChangeStatus(true)
          }}
          loading={loading}
          aria-label={(userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater'
            ? 'Publish'
            : 'Submit for review'}
        >
          {!loading && <FontAwesomeIcon icon={faCheckCircle as IconProp}/>}{' '}
          {(userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater'
            ? 'Publish'
            : 'Submit for review'}
        </PublishButton>
        {!actionButtonValidationLoading &&
          <PopoutMenu
            label={<FontAwesomeIcon icon={faEllipsisH as IconProp} color='#222' aria-label="More Actions" />}
            buttonBackgroundColour='#e3edfa'
            buttonBackgroundHoverColour='#e0e6ee'
            menuItems={[
              // Methods are placeholders for now until the copy and delete functionality has been created
              { label: 'Copy', icon: faCopy, method: onCopyRecord, disabled: !actionButtonsDisabled.copy },
              {
                label: actionButtonsDisabled.restore ? 'Restore' : 'Delete',
                icon: actionButtonsDisabled.restore ? faTrashRestore : faTrash,
                method: actionButtonsDisabled.restore ? onRestoreRecord : onDeleteRecord,
                disabled: actionButtonsDisabled.restore ? false : !actionButtonsDisabled.delete,
              },
            ]}
          />}
        {actionButtonValidationLoading && <FontAwesomeIcon icon={faCircleNotch as IconProp} fixedWidth spin />}
        {actionButtonsDisabled.active_iss_entities.length > 0 && !actionButtonsDisabled.delete &&
          <PopoutList
            label={<FontAwesomeIcon icon={faExclamationTriangle as IconProp} color='red'/>}
            contentLabel={'Active records in iss4:'}
            listItems={actionButtonsDisabled.active_iss_entities.map(activeEntity => (
              <li key={activeEntity.id}>
                {activeEntity.name} ({activeEntity.id})
              </li>
            ))}
          />
        }
        {isMobile && (
          <Button
            primary
            title='The form will be validated prior to submission'
            onClick={() => {
              onShowRightPanel(!showRightPanel)
              setShowRightPanel(!showRightPanel)
            }}
          >
            <FontAwesomeIcon icon={faBars as IconProp}/>
          </Button>
        )}
      </RightHandSideControls>
    </ControlBarContainer>
  )
}

export default withContext(AddEditRecordControlBar)
