import React from 'react'
import {
  Row,
  Col,
  RadioButton,
  Button,
} from '@ix/ix-ui'
import { Option } from '../forms/AddEditRecord.type'
import { ControllerRenderProps, useFormContext } from 'react-hook-form'

type SPUDRadioButtonGroupProps = {
  title: React.ReactElement,
  options: Array<Option> | undefined,
  name: string,
  field?: ControllerRenderProps | null
}

function SPUDRadioButtonGroup (
  {
    title,
    options,
    name,
    field,
  }: SPUDRadioButtonGroupProps): React.ReactElement {
  const { trigger, getValues, setValue } = useFormContext()
  return <div>
    <Row>
      <Col direction='row' justify='space-between'>
        {title}
        <Button
          onClick={() => {
            setValue(name, null)
          }}
        >
          Clear selection
        </Button>
      </Col>
    </Row>
    <Row>
      <Col direction='row' style={{ padding: '1em' }}>
        {options?.map((option: {id: string | null | number, name: string }) => (
          <RadioButton
            key={option.id}
            name={name}
            label={option.name}
            checked={getValues(name) === option.id}
            value={option.id}
            onChange={() => {
              setValue(name, option?.id)
              trigger(name)
            }}
            onBlur={field?.onBlur}
            ref={field?.ref}
          />
        ))}
      </Col>
    </Row>
  </div>
}

export default SPUDRadioButtonGroup
