import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Title, Alert, Toggle, Textarea, Text } from '@ix/ix-ui'
import { useFieldArray, useFormContext, Controller } from 'react-hook-form'
import { SPUDInputField } from '../../helpers/record'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faLevelUpAlt, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AngledArrowContainer } from './AngledArrowContainer'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'

export type SPUDEmailsProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  disabled: boolean,
}

export interface SPUDEmailType {
  email: string,
  confidential: boolean,
  comment: string
}

const SPUDEmailCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const EmailCardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

const EmailConfidentialToggle = styled(Toggle)`
  label {
    padding: 0;
  }
`

function SPUDEmails ({ title, recordType, siteValues, disabled }: SPUDEmailsProps): React.ReactElement {
  const { control, setValue, getValues, formState: { errors } } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  })

  const [siteRecordEmails, setSiteRecordEmails] = useState<Array<{
    email: string,
    comment?: string
  }>>([])

  let disableAutoFocus = true
  const formErrors = errors as {
    emails?: Array<{
      email: {
        message: string
      }
    }>
  }

  useEffect(() => {
    if (recordType === 'service' && siteValues?.emails) {
      setSiteRecordEmails(siteValues?.emails)
    } else {
      disableAutoFocus = false
      setSiteRecordEmails([])
    }
  }, [siteValues?.emails, recordType])

  return (
    <div>
      <Row style={{ margin: '0.5rem 0' }}>
        <Col>
          <Title level={4}>
            {title}
          </Title>
        </Col>
        <Col flex={12}>
          <Button active={!disabled} onClick={() => append({ email: '' }, { shouldFocus: false })}>
            <FontAwesomeIcon icon={faPlus as IconProp}/> Add an Email
          </Button>
        </Col>
      </Row>
      {fields.map((item, index) => (
        <Row key={item.id}>
          <Col direction='row'>
            <AngledArrowContainer>
              <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
            </AngledArrowContainer>
            <SPUDEmailCard>
              <EmailCardHeader>
                <Button onClick={() => remove(index)} active={!disabled}>
                  <FontAwesomeIcon icon={faTimes as IconProp}/> Remove
                </Button>
              </EmailCardHeader>
              <Row>
                <Col>
                  <Controller
                    name={`emails.${index}.email`}
                    control={control}
                    rules={{
                      pattern: {
                        // eslint-disable-next-line max-len
                        value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        message: 'That does not look like an email address, please try again.',
                      },
                    }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='email'
                        // to prevent the form scrolling down
                        // when adding a new Service from a site
                        // we need to disable autofocus
                        autoFocus={!disableAutoFocus}
                        placeholder="Email"
                        required={true}
                        label={<span><FontAwesomeIcon icon={faAt as IconProp}/> Email{' '}</span>}
                        fullwidth
                        {...field}
                      />
                    }
                  />
                  {
                    siteRecordEmails?.[index]?.email &&
                    <Text>Site email {index + 1}: {siteRecordEmails?.[index]?.email}</Text>
                  }
                  {formErrors?.emails?.[index]?.email &&
                    <Alert type="error" aria-label={`emails-${index}`}>
                      {formErrors?.emails?.[index]?.email?.message}
                    </Alert>}
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 25, marginLeft: -5 }}>
                  <Controller
                    name={`emails.${index}.confidential`}
                    control={control}
                    rules={{ required: false }}
                    render={() =>
                      <EmailConfidentialToggle
                        padding='0'
                        id='confidential'
                        label={<Title level={4} marginTop='0'>Confidential</Title>}
                        fullwidth
                        offColor="#E3EDFA"
                        handleChange={(checked: boolean) => {
                          setValue(`emails.${index}.confidential`, checked)
                        }}
                        checked={getValues(`emails.${index}.confidential`) || false}
                        onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                          const checked = getValues(`emails.${index}.confidential`) || false
                          if (key.key === 'Enter') {
                            setValue?.(`emails.${index}.confidential`, !checked)
                          }
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row style={{ display: 'none' }}>
                <Col>
                  <Controller
                    name={`emails.${index}.comment`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <>
                        <Title level={4}>Comment</Title>
                        <Textarea
                          {...field}
                        />
                      </>
                    }
                  />
                  {
                    siteRecordEmails?.[index]?.comment &&
                    <Text>Site email {index + 1} comment: {siteRecordEmails?.[index]?.comment}</Text>
                  }
                </Col>
              </Row>
            </SPUDEmailCard>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default SPUDEmails
