import React, { useEffect, useState } from 'react'
import QuickFilterPresets from './QuickFilterPresets'
import { Container, Row, Col, Button } from '@ix/ix-ui'
import styled from 'styled-components'
import FilterChips from './FilterChips'
import {
  AdvancedFilterType, allocatedUserFilterSwitches,
  fetchQuickFilterPresetByRole, getFilterIndex,
  quickFilterPresetToObj,
  QuickFilterPresetType, statusQuickFilterSwitches,
} from './RecordFilter.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type FilteringComponentProps = {
  userRole: 'Administrator'|'Updater'|'Editor',
  filterCallback: (setFilters: {[x: string]: AdvancedFilterType}) => void
  pageFilters?: {[x: string]: AdvancedFilterType},
  onOpenAdvancedFilters?: (openFilter: boolean, editingFilter?: string) => void,
  openAdvancedFilters?: boolean
  pageType: 'dashboard'|'record-list',
}

const FilterContainer = styled(Container)`
  padding: 0px;
`

function FilteringComponent (
  {
    userRole,
    filterCallback,
    pageFilters,
    onOpenAdvancedFilters,
    openAdvancedFilters,
    pageType,
  }: FilteringComponentProps) {
  const [quickPresets, setQuickPresets] = useState<Array<QuickFilterPresetType>>([])
  const [activeFilters, setActiveFilters] = useState<{[x: string]: AdvancedFilterType}>({})

  useEffect(() => {
    const presets = fetchQuickFilterPresetByRole(userRole, pageType)
    setQuickPresets(presets.filter(preset => preset.position === 'above-list'))
  }, [userRole])

  useEffect(() => {
    if (pageFilters && Object.keys(pageFilters).length) {
      const quickPresetObj = quickFilterPresetToObj(quickPresets)
      let currentPageFilters = {}
      Object.entries(pageFilters).forEach(([pageFilter, filterValue]) => {
        if (quickPresetObj?.[pageFilter]) {
          currentPageFilters = { ...currentPageFilters, [pageFilter]: quickPresetObj[pageFilter] }
        } else {
          currentPageFilters = { ...currentPageFilters, [pageFilter]: filterValue }
        }
      })
      setActiveFilters(currentPageFilters)
    } else {
      setActiveFilters({})
    }
  }, [pageFilters])

  const onAddNewFilter = (activeFilter: AdvancedFilterType, filterKey?: string) => {
    if (activeFilter?.name) {
      if (activeFilter?.value === undefined) {
        activeFilter = { ...activeFilter, value: activeFilter.defaultValue }
      }
      let filterFieldKey
      if (filterKey) {
        filterFieldKey = filterKey
      } else {
        const nextIndex = getFilterIndex(activeFilters, activeFilter, false)
        filterFieldKey = `${activeFilter?.name}-${nextIndex[activeFilter.name]}`
      }
      let newFilters = {
        ...activeFilters,
        [filterFieldKey]: { ...activeFilter },
      }
      newFilters = allocatedUserFilterSwitches(newFilters, activeFilter)
      newFilters = statusQuickFilterSwitches(newFilters, activeFilter, userRole, pageType)
      setActiveFilters(newFilters)
      filterCallback(newFilters)
    }
  }

  const onDeleteFilter = (activeFilter: AdvancedFilterType) => {
    if (activeFilter?.name) {
      const nextIndex = getFilterIndex(activeFilters, activeFilter, true)
      const tempFilterValues = { ...activeFilters }
      delete tempFilterValues[`${activeFilter.name}-${nextIndex[activeFilter.name]}`]
      setActiveFilters(tempFilterValues)
      filterCallback(tempFilterValues)
    }
  }

  return (
    <FilterContainer>
      <Row>
        <Col>
          <QuickFilterPresets
            presets={quickPresets}
            activeFilters={activeFilters}
            onPresetChange={(preset) => {
              if (preset.value) {
                onAddNewFilter(preset, `${preset.name}-0`)
              } else {
                onDeleteFilter(preset)
              }
            }}
          />
        </Col>
        {onOpenAdvancedFilters && <Col direction='row' justify='flex-end' style={{ padding: 0 }}>
          <Button primary onClick={() => onOpenAdvancedFilters?.(!openAdvancedFilters)}>
            Filter <FontAwesomeIcon icon={faPlus as IconProp}/>
          </Button>
        </Col>}
      </Row>
      <Row>
        <Col>
          <FilterChips
            activeFilters={activeFilters}
            onChipClick={(chip) => {
              console.log('chip')
              console.log(chip)
              chip ? onOpenAdvancedFilters?.(true, chip) : onOpenAdvancedFilters?.(false, '')
            }}
            filterPanelOpen={openAdvancedFilters}
          />
        </Col>
      </Row>
    </FilterContainer>
  )
}

export default FilteringComponent
