import React from 'react'
import { Page, Row, Col, Content } from '@ix/ix-ui'
import { Route, Redirect } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { useKeycloak } from '@react-keycloak/web'
import SideNav from '../components/SideNav'
import { AppContextType } from '../context/AppContext.type'
import BackgroundTaskProgress from '../pages/RecordsPage/ListView/BackgroundTaskProgress'
import SPUDHeading from '../pages/SPUDHeading'
import { PopUpBackdrop } from '../pages/RecordsPage/ListView/ListView.service'

type Props = RouteProps & {
  appContext: AppContextType | null,
  pageType: string,
}
function PrivateRoute (props: Props): React.ReactElement {
  const { keycloak } = useKeycloak()

  if (keycloak.authenticated) {
    sessionStorage.setItem('redirectTo', props.location?.pathname as string)
    sessionStorage.setItem('filterHash', props.location?.hash as string)
    if (props.appContext) {
      return <>
        <Page>
          <SideNav
            {...props.appContext}
            onClearFailedMessages={() => props.appContext?.clearWsMessages()}
          />
          <Content>
            <Row>
              <Col>
                <BackgroundTaskProgress
                  lastMessage={props.appContext.lastMessage as {
                    type: string
                    message: {
                      total_records: 0
                      processed: 0
                    }
                  }}
                />
                {props.pageType !== 'search' && props.pageType !== 'administration' &&
                  props.pageType !== 'emailcampaign' && <SPUDHeading searchRecords={props.appContext.searchRecords} />}
                <Route {...props} />
              </Col>
            </Row>
          </Content>
        </Page>
        {props.appContext.popupType && props.appContext.displayPopUp()}
        {props.appContext.popupType &&
          <PopUpBackdrop containerHeight={`${document.documentElement.scrollHeight}px`}/>}
      </>
    } else {
      return <Route {...props} />
    }
  }

  return <Redirect to={{ pathname: '/login' }} />
}

export default PrivateRoute
