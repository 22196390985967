import React, { useState } from 'react'
import { Button, Col, Row, Title } from '@ix/ix-ui'
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import SPUDDatePicker from '../../../../components/General/SPUDDatePicker'
import { SPUDTextArea, CommentIconButton } from './SPUDCommentHelperComponents'
import styled from 'styled-components'
import { CommentType, saveComment } from '../../../../services/comment.service'
import toast from 'react-hot-toast'
import SPUDToastNotification from '../../../../components/General/SPUDToastNotification'
import { AxiosErrorWithObjectResponse } from '../../../../helpers/api'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isAfter } from 'date-fns'

const CommentFormFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`

type SPUDAddNewCommentProps = {
  loading: boolean
  setComments: (comments: Array<CommentType>) => void,
  recordId: string | undefined,
  disabled: boolean,
  comments: Array<CommentType>
  processError: (error: AxiosErrorWithObjectResponse | Error) => void,
  tableOrdering: {
     starred: string,
    // eslint-disable-next-line camelcase
     commenting_user: string,
    // eslint-disable-next-line camelcase
     comment_date: string,
  }
}

function SPUDAddNewComment (
  {
    setComments,
    loading,
    recordId,
    comments,
    processError,
    tableOrdering,
    disabled,
  }: SPUDAddNewCommentProps): React.ReactElement {
  const [addNewLoading, setAddNewLoading] = useState(false)

  const methods = useForm<CommentType>({
    mode: 'all',
  })

  const sortNewlyAddedComment = (comments: Array<CommentType>) => {
    return comments.sort(
      (commentA, commentB) => {
        if (tableOrdering.comment_date === '-comment_date' &&
          isAfter(
            new Date(commentB.comment_date),
            new Date(commentA.comment_date),
          )) {
          return 1
        } else if (tableOrdering.comment_date === 'comment_date' &&
          isAfter(
            new Date(commentA.comment_date),
            new Date(commentB.comment_date),
          )) {
          return 1
        }
        return -1
      })
  }

  const onSaveNewComment: SubmitHandler<CommentType> = async (data: CommentType) => {
    try {
      setAddNewLoading(true)
      const newComment = await saveComment(recordId, data)
      let newComments = [newComment.data, ...comments]
      if (tableOrdering.starred === '-starred') {
        let starredComments = newComments.filter(
          result => result.starred)
        if (newComment.data.starred) {
          starredComments = sortNewlyAddedComment(starredComments)
        }
        const unStarredComments = newComments.filter(
          result => !result.starred)
        const sortedUnstarredComments = sortNewlyAddedComment(unStarredComments)
        newComments = starredComments.concat(sortedUnstarredComments)
      }

      setComments(newComments)
      methods.reset()
      toast.custom(
        <SPUDToastNotification
          title="Success"
          message={
            <span>
              New comment has been created
            </span>
          }
          success
        />,
        {
          position: 'bottom-right',
        },
      )
      setAddNewLoading(false)
    } catch (error) {
      if (error instanceof Error) processError(error)
      setAddNewLoading(false)
    }
  }

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={methods.handleSubmit(onSaveNewComment)}
    >
      <CommentFormFieldset disabled={loading}>
        <Col>
          <Row>
            <Col style={{ padding: 0 }}>
              <Controller
                name='starred'
                control={methods.control}
                defaultValue={false}
                render={({ field }) =>
                  <Row>
                    <Col direction='row' justify='flex-end' style={{ padding: 0 }}>
                      <Title level={4}>Star comment</Title>
                      <CommentIconButton
                        aria-label='Star comment'
                        onClick={() => methods.setValue('starred', !field.value) }
                        style={{ marginRight: '10px' }}
                        disabled={disabled}
                      >
                        {field.value
                          ? <FontAwesomeIcon color='#3a8ae8' icon={faStarSolid as IconProp} />
                          : <FontAwesomeIcon icon={faStar as IconProp} /> }
                      </CommentIconButton>
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name='comment_date'
                control={methods.control}
                defaultValue={new Date()}
                render={({ field }) =>
                  <Row>
                    <Col style={{ padding: 0 }} align='baseline'>
                      <div style={{ marginRight: '1em' }}>
                        <Title level={4} marginTop='0px'>Comment date</Title>
                      </div>
                      <SPUDDatePicker
                        formElement={{
                          name: 'comment_date',
                          label: 'Comment Date',
                          type: 'date',
                          rules: { required: false },
                          defaultValue: new Date(),
                          maxDate: new Date(),
                          dateFormat: 'dd/MM/yyyy',
                        }}
                        fullWidth={true}
                        field={field as unknown as ControllerRenderProps}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name='comment'
                control={methods.control}
                defaultValue={''}
                render={({ field }) =>
                  <SPUDTextArea placeholder='Enter a comment...' disabled={disabled} {...field} />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                aria-label='Add comment'
                active
                type='submit'
                loading={addNewLoading}
                disabled={disabled}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Col>
      </CommentFormFieldset>
    </form>
  )
}

export default SPUDAddNewComment
