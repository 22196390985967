import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../helpers/auth'
import {
  faChartLine,
  faTasks,
  faCog,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

import {
  Badge,
  Collapse,
  Dropdown,
  Footer,
  Logo,
  Menu,
  MenuItem,
  Sidebar,
  Text,
  Button,
  Row,
  Col,
} from '@ix/ix-ui'

import SpudAvatar from './Avatar'
import { withContext } from '../context/AppContext'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { AppContextType } from '../context/AppContext.type'
import styled from 'styled-components'
import axios from 'axios'
import { useKeycloak } from '@react-keycloak/web'

const DismissReviewBackgroundTaskButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`

const LogoutButton = styled(Button)`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
  :hover {
    background: none;
    border: none;
    span {
      text-decoration: none;
    }
  }
`

const WebsocketResultContainer = styled.div`
  padding: 1em;
  background-color: #fff;
  color: #fff;
  border-radius: 3px;
`

const SubmenuIcon = styled.div`
  position: absolute;
  right: 3px;
  top: 11px;
  font-size: 12px;
`

type SideNavProps = AppContextType & {
  onClearFailedMessages: () => void,
}

const SideNav = (props: SideNavProps): React.ReactElement => {
  const { user, userRole } = useAuth()
  const { keycloak } = useKeycloak()
  const [dashboardCount, setDashboardCount] = useState(0)
  const [displayWebsocketResults, setDisplayWebSocketResults] = useState(false)
  const [hasFailedResults, setHasFailedResults] = useState(false)
  const [updatedCount, setUpdatedCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [websocketAction, setWebsocketAction] = useState('')
  const [websocketActionInProgress, setWebsocketActionInProgress] = useState(false)

  useEffect(() => {
    const websocketMessage = props.lastMessage as {
      type: string,
      message: {
        action: string,
        failed: boolean
        updated: number
      }
    }
    if (websocketMessage) {
      if (websocketMessage.type === 'update_in_progress') {
        setWebsocketActionInProgress(true)
      }
      if (websocketMessage?.type === 'completed') {
        setWebsocketActionInProgress(false)
        setDisplayWebSocketResults(true)
        setWebsocketAction(websocketMessage.message.action)
        if (websocketMessage.message.failed) {
          websocketMessage.message.updated && setUpdatedCount(websocketMessage.message.updated)
          setHasFailedResults(true)
        } else {
          setHasFailedResults(false)
        }
      } else {
        setWebsocketAction('')
        setDisplayWebSocketResults(false)
        setHasFailedResults(false)
      }
    }
  }, [props.lastMessage])

  const logoutUser = () => {
    if (keycloak?.authenticated) {
      sessionStorage.clear()
      keycloak.logout()
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    const getCount = async (): Promise<void> => {
      setTimeout(async () => {
        try {
          const dueForUpdateFilter = new URLSearchParams()
          dueForUpdateFilter.append('due_for_update-0', 'true')
          dueForUpdateFilter.append('allocated-0', 'true')
          setLoading(true)
          const response = await props
            .recordList(
              {
                recordType: 'site',
                offset: 0,
                limit: 1,
                ordering: 'pk',
              },
              userRole === 'Administrator' ? null : dueForUpdateFilter,
              abortController,
            )
          setLoading(false)
          setDashboardCount(response.data.count)
        } catch (error) {
          // If it's not an aborted request we stop it from loading
          if (!axios.isCancel(error)) {
            setLoading(false)
          }
        }
      }, 0)
    }
    if (!dashboardCount) {
      getCount()
    }
  }, [])

  const Profile = () => (
    <div>
      <SpudAvatar user={{
        first_name: user.given_name,
        last_name: user.family_name,
        id: user.id,
      }}
      />
      <Text style={{ lineHeight: `${40}px` }}>{user?.name || 'SPUD User'}</Text>
    </div>
  )

  const [menuRecordsOpen, setMenuRecordsOpen] = useState(false)
  const [menuAdminOpen, setMenuAdminOpen] = useState(false)

  const handleMenuRecordsClick = () => {
    setMenuRecordsOpen(!menuRecordsOpen)
    setMenuAdminOpen(false)
  }

  const handleMenuAdminClick = () => {
    setMenuAdminOpen(!menuAdminOpen)
    setMenuRecordsOpen(false)
  }

  const handleNormalClick = () => {
    setMenuRecordsOpen(false)
    setMenuAdminOpen(false)
  }

  return (
    <Sidebar fixed>
      <Logo inverse compact />

      <Menu>
        <MenuItem>
          <NavLink to="/dashboard" onClick={handleNormalClick}>
            <FontAwesomeIcon icon={faChartLine as IconProp} fixedWidth />
            <Collapse>
              Dashboard
              <Badge content={loading ? 'loading' : dashboardCount} />
            </Collapse>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/records/site" onClick={handleMenuRecordsClick}>
            <FontAwesomeIcon icon={faTasks as IconProp} fixedWidth />
            <Collapse>Records</Collapse>
            <SubmenuIcon>
              {menuRecordsOpen
                ? <FontAwesomeIcon icon={faChevronUp as IconProp} fixedWidth />
                : <FontAwesomeIcon icon={faChevronDown as IconProp} fixedWidth />
              }
            </SubmenuIcon>
          </NavLink>
        </MenuItem>
        {menuRecordsOpen &&
          <>
            <MenuItem submenu>
              <NavLink to="/records/site">
                <Collapse>Sites</Collapse>
              </NavLink>
            </MenuItem>
            <MenuItem submenu>
              <NavLink to="/records/organisation">
                <Collapse>Organisation</Collapse>
              </NavLink>
            </MenuItem>
            <MenuItem submenu>
              <NavLink to="/records/service">
                <Collapse>Services</Collapse>
              </NavLink>
            </MenuItem>
          </>
        }
        {userRole === 'Administrator' && (
          <MenuItem>
            <NavLink to="/administration" onClick={handleMenuAdminClick}>
              <FontAwesomeIcon icon={faCog as IconProp} fixedWidth />
              <Collapse>Administration</Collapse>
              <SubmenuIcon>
                {menuAdminOpen
                  ? <FontAwesomeIcon icon={faChevronUp as IconProp} fixedWidth />
                  : <FontAwesomeIcon icon={faChevronDown as IconProp} fixedWidth />
                }
              </SubmenuIcon>
            </NavLink>
          </MenuItem>
        )}
        {menuAdminOpen && (
          <>
            <MenuItem submenu>
              <NavLink to="/administration/emailcampaign">
                <Collapse>Email campaign</Collapse>
              </NavLink>
            </MenuItem>
          </>
        )}
      </Menu>
      <Footer aria-label='Logged in user'>
        {(displayWebsocketResults || websocketActionInProgress) &&
          <WebsocketResultContainer>
            <Row>
              <Col direction='row' justify='flex-end'>
                <DismissReviewBackgroundTaskButton onClick={() => setDisplayWebSocketResults(false)}>
                  X
                </DismissReviewBackgroundTaskButton>
              </Col>
            </Row>
            <Row>
              <Col>
                {displayWebsocketResults && <Text padding="0 10px 0 0">
                  {hasFailedResults
                    ? websocketAction === 'Imported' && !updatedCount
                      ? 'Failed to import (see errors)'
                      : `${websocketAction} (completed with errors)`
                    : `${websocketAction} (completed)`
                  }
                  <Button active onClick={() => props.setPopupType('review')}>
                    See results
                  </Button>
                </Text>}
              </Col>
            </Row>
            {websocketActionInProgress && <span style={{ color: 'black' }}>loading...</span>}
          </WebsocketResultContainer>
        }
        <Dropdown up dark block title={<Profile />} width="100%">
          <LogoutButton
            onClick={() => logoutUser()}
            aria-label="logout"
          >
            <Text>Logout {user?.name}</Text>
          </LogoutButton>
        </Dropdown>
      </Footer>
    </Sidebar>
  )
}

export default withContext(SideNav)
