import React, { useRef, useState } from 'react'
import { Textarea, Title } from '@ix/ix-ui'
import { ControllerRenderProps } from 'react-hook-form'
import styled from 'styled-components'
import { SPUDToggle } from '../../helpers/record'

type SPUDTextAreaProps = {
  field: ControllerRenderProps | null | undefined | {
    value: string,
    name: string,
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
  },
  label: string,
  required?: boolean | {
    value: boolean,
    message: string
  }
  highlight?: boolean
  canShowLineBreaks?: boolean
  disabled: boolean
}

const TextArea = styled(Textarea)<{highlight: boolean | undefined}>`
  resize: vertical;
  height: ${props => props.height};
  border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  min-height: 150px;
  :hover {
    border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  }
  :active {
    border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  }
`

function SPUDTextArea (
  {
    field,
    label,
    required,
    highlight,
    canShowLineBreaks,
    disabled,
    ...rest
  }: SPUDTextAreaProps): React.ReactElement {
  const [fieldValue, setFieldValue] = useState('')
  const [showLineBreaks, setShowLineBreaks] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const formatFieldWithLineBreaks = (showLineBreak: boolean) => {
    let newVal
    if (showLineBreak) {
      newVal = field?.value.replaceAll('\n', '<br/>\n')
    } else {
      newVal = field?.value.replaceAll('<br/>\n', '\n')
    }
    setShowLineBreaks(!showLineBreaks)
    setFieldValue(newVal)
  }

  const calculateHeight = () => {
    if (textAreaRef.current) {
      return `${textAreaRef.current?.scrollHeight}px`
    }
    return 'inherit'
  }

  return <div>
    <Title level={4}>{label}</Title>
    <TextArea
      ref={textAreaRef}
      rows={4}
      name={field?.name}
      required={required}
      id={field?.name}
      highlight={highlight && !field?.value}
      height={calculateHeight()}
      disabled={(canShowLineBreaks && showLineBreaks) || disabled}
      value={showLineBreaks ? fieldValue : field?.value}
      onChange={field?.onChange}
      onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.style.height = 'inherit'
        event.target.style.height = `${event.target.scrollHeight}px`
      }}
      placeholder={label}
      {...rest}
    />
    {canShowLineBreaks && (
      <SPUDToggle
        padding='0'
        offColor="#E3EDFA"
        label={<Title level={4} marginTop='0'>Show line breaks</Title>}
        checked={showLineBreaks} handleChange={(checked: boolean) =>
          formatFieldWithLineBreaks(checked)
        }
        onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
          const checked = showLineBreaks
          if (key.key === 'Enter') {
            formatFieldWithLineBreaks(!checked)
          }
        }}
      />
    )}
  </div>
}

export default SPUDTextArea
