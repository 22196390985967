import React, { useEffect, useState } from 'react'
import { SPUDRecord } from '../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../@types/Service.type'
import { Col, Link, Row, Button, Title, Badge } from '@ix/ix-ui'
import styled from 'styled-components'
import pluralize from 'pluralize'
import {
  SearchListResultColumn,
  SearchListResultColumnSeperator,
  SearchListResultList,
  SearchListResultListItem,
} from './SearchResultsPage'
import { fetchCorrectIdToDisplay } from '../helpers/record'

type QuickSearchListProps = {
  searchResults: {
    [x: string]: Array<
      SPUDRecord<
        SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails
        >
      >
  },
  loading: boolean,
  onDismiss: () => void,
  totalNoRecords: number,
  searchTerm: string,
}

const QuickSearchListContainer = styled.div`
  position: absolute;
  z-index: 4;
  background-color: #fff;
  left: 10%;
  right: 10%;
  width: 80%;
  border: 2px solid ${props => props.theme.colors.accentColor};
  border-radius: 3px;
  top: 0;
`

const QuickSearchListHeader = styled.div`
  background-color: ${props => props.theme.colors.accentColor};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1em;
`

const QuickSearchListSeeMoreRow = styled(Row)`
  border-top: 1px solid ${props => props.theme.colors.accentColor};
`

function QuickSearchList (
  {
    searchResults,
    loading,
    onDismiss,
    totalNoRecords,
    searchTerm,
  }: QuickSearchListProps) {
  const [sites, setSites] = useState<Array<SPUDRecord<SPUDSiteRecordDetails>>>([])
  const [services, setServices] = useState<Array<SPUDRecord<SPUDServiceRecordDetails>>>([])
  const [organisations, setOrganisations] = useState<Array<SPUDRecord<SPUDOrganisationRecordDetails>>>([])

  useEffect(() => {
    if (Object.keys(searchResults).length > 0) {
      if (searchResults?.site?.length) {
        setSites(searchResults?.site.slice(0, 50) as Array<SPUDRecord<SPUDSiteRecordDetails>>)
      } else {
        setSites([])
      }
      if (searchResults?.service?.length) {
        setServices(searchResults?.service.slice(0, 50) as Array<SPUDRecord<SPUDServiceRecordDetails>>)
      } else {
        setServices([])
      }
      if (searchResults?.organisation?.length) {
        setOrganisations(searchResults?.organisation.slice(0, 50) as Array<SPUDRecord<SPUDOrganisationRecordDetails>>)
      } else {
        setOrganisations([])
      }
    }
  }, [searchResults?.site, searchResults?.service, searchResults?.organisation])

  return <QuickSearchListContainer>
    <Row>
      <QuickSearchListHeader>
        <Title level={4}>
          <span style={{ marginRight: 5 }}>Search results</span>
          <Badge
            active
            block
            content={`${totalNoRecords} ${pluralize('record', totalNoRecords)} found`}
          />
        </Title>
        <Button onClick={onDismiss}>
          X
        </Button>
      </QuickSearchListHeader>
    </Row>
    {!loading && <Row>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Sites{' '}
          <Badge
            active
            block
            content={`${sites.length} ${sites.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {sites.map((returnedValue: SPUDRecord<SPUDSiteRecordDetails>) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Row>
                <Col>
                  <Link
                    to={`/records/${returnedValue.record_type}/record/${
                      fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                    onClick={() => onDismiss()}
                  >
                    {returnedValue.update?.data.name || 'Unknown record name'}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  ({returnedValue.update?.data?.location?.suburb})
                </Col>
              </Row>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Services{' '}
          <Badge
            active
            block
            content={`${services.length} ${services.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {services.map((returnedValue: SPUDRecord<SPUDServiceRecordDetails>) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Row>
                <Col>
                  <Link
                    to={`/records/${returnedValue.record_type}/record/${
                      fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                    onClick={() => onDismiss()}
                  >
                    {returnedValue.update?.data.name || 'Unknown record name'}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  ({returnedValue.site?.name})
                </Col>
              </Row>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
      <SearchListResultColumn>
        <Title level={5} align='center'>
          Organisation{' '}
          <Badge
            active
            block
            content={`${organisations.length} ${organisations.length === 50 ? '+' : ''} found`}
          />
        </Title>
        <SearchListResultColumnSeperator />
        <SearchListResultList>
          {organisations.map((returnedValue: SPUDRecord<SPUDOrganisationRecordDetails>) =>
            <SearchListResultListItem key={returnedValue.id}>
              <Link
                to={`/records/${returnedValue.record_type}/record/${
                  fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                onClick={() => onDismiss()}
              >
                {returnedValue.update?.data.name || 'Unknown record name'}
              </Link>
            </SearchListResultListItem>)}
        </SearchListResultList>
      </SearchListResultColumn>
    </Row>
    }
    <QuickSearchListSeeMoreRow>
      <Col direction='row' justify='center'>
        <Link to={`/search/${searchTerm}`}>
          <Button onClick={onDismiss}>
            Expand search to see more results
          </Button>
        </Link>
      </Col>
    </QuickSearchListSeeMoreRow>
  </QuickSearchListContainer>
}

export default QuickSearchList
