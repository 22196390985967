import API, { getSPUDAPIUrl } from '../helpers/api'
import { AxiosResponse } from 'axios'
/* eslint-disable camelcase */
export type CommentType = {
  id: number,
  comment: string,
  comment_date: Date,
  starred: boolean,
  record: string | undefined,
  commenting_user: {
    username: string,
    id: string | null,
    first_name: string,
    last_name: string,
  }
}
/* eslint-enable camelcase */

export const fetchComments = async (recordId: string | undefined, tableOrdering: { [x: string] : string }): Promise<{
  results: Array<CommentType>
}> => {
  const orderingString = Object.keys(tableOrdering)
    .filter(key => tableOrdering[key])
    .map(key => `ordering=${tableOrdering[key]}`)
    .join('&')
  const response = await API.get(`${getSPUDAPIUrl()}comment/?record_id=${recordId}&${orderingString}`)
  return response.data
}

export const saveComment = async <T extends CommentType>(
  recordId: string | undefined,
  data: T,
  commentId?: number,
): Promise<AxiosResponse<T>> => {
  let response
  if (commentId) {
    response = await API.patch(`${getSPUDAPIUrl()}comment/${commentId}/`, data)
  } else {
    // The record id needs to be appended to the request body, so it gets
    // created against the record
    data.record = recordId
    response = await API.post(`${getSPUDAPIUrl()}comment/`, data)
  }
  return response
}

export const deleteComment = async (commentId: number): Promise<void> => {
  await API.delete(`${getSPUDAPIUrl()}comment/${commentId}/`)
}
