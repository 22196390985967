import React, { useEffect, useRef, useState } from 'react'

import {
  Row,
  Col,
  Alert,
  Accordion,
  QuickAccessToolbar,
} from '@ix/ix-ui'
import {
  fetchCorrectComponent,
  fetchFormDataStructure, generateQuickAccessToolbar,
  getFormConfig, validateSections,
  getPageType, fetchCorrectIdToDisplay,
} from '../../helpers/record'
import { RouteComponentProps, useHistory } from 'react-router'
import { AppContextType } from '../../context/AppContext.type'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { SPUDOnSaveResponseDetails, SPUDRecordWithData } from '../../../@types/SPUDRecord.type'
import AddEditRecordControlBar from './AddEditRecordControlBar'
import styled from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
import SPUDToastNotification from '../General/SPUDToastNotification'
import { getScrollPosition } from '../../effects/scrollPosition'
import { Field } from './AddEditRecord.type'
import { useAuth } from '../../helpers/auth'
import { CheckRecordActionResponseType } from '../../services/transactions.service'
import { copyRecordHandler, deleteRecordHandler, restoreRecordHandler } from './AddEditRecord.utils'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { LinkedRecord } from '../../../@types/LinkedRecord.type'

type matchParams = {
  recordType?: string,
  action?: string,
  revisionId?: string,
}

type Props = RouteComponentProps<matchParams> & AppContextType & {
  recordId?: number|null|string,
  loadingForm: boolean,
  isNewRecord: boolean,
  action: string | undefined,
  recordType: string | undefined,
  formData: SPUDRecordWithData<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>,
  onSaveCallback: (
    response: SPUDOnSaveResponseDetails | null,
    refetch?: boolean | undefined) => void,
  appendValue: {
    site?: number | null | string,
    organisation?: number | null | string
  } | null,
  onShowRightPanel: (show: boolean) => void,
  linkedRecords: Array<LinkedRecord>
}

const SPUDFormFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`

const ControlBarContainer = styled.div<{sticky: boolean}>`
  transition: background-color 0.05s ease;
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  margin-top: 0;
  height: ${props => props.sticky ? 'auto' : 'inherit'};
  top: ${props => props.sticky ? '80px' : '0'};
`

function AddEditRecord (
  {
    recordType,
    appendValue,
    spudSave,
    spudUpdate,
    spudCopyService,
    spudDelete,
    spudRestore,
    onSaveCallback,
    formData,
    recordId,
    loadingForm,
    isNewRecord,
    onShowRightPanel,
    checkRecordActions,
    setDialogOptions,
    linkedRecords,
  }: Props): React.ReactElement {
  const [changeStatus, setChangeStatus] = useState(false)
  const [quickAccessExpanded, setQuickAccessExpanded] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [actionButtonValidationLoading, setActionButtonValidationLoading] = useState(false)
  const [quickAccessButtonClicked, setQuickAccessButtonClicked] = useState('')
  const [actionButtonsDisabled, setActionButtonsDisabled] = useState<CheckRecordActionResponseType>(
    {
      copy: false,
      delete: false,
      restore: false,
      eReferral: false,
      active_iss_entities: [],
    },
  )

  const formRef = useRef<HTMLDivElement>(null)

  const scrollPosition = getScrollPosition()
  const QuickAccessMenuContainerRef = useRef<HTMLDivElement>(null)

  const { userRole } = useAuth()
  const history = useHistory()

  /**
   * we need to specify default values for each field
   *
   * This returns an object of key value pairs where the key is name used
   * by react-hooks-form to keep track of the fields, and the value is default value of what that form should be
   * e.g:
   * {
   *   {'name': 'New name},
   *   {'phoneNumber', 123}
   * }
   * */
  // disabled because shape of the form isn't known till runtime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const constructDefaultFormData = (type?: string): { [x: string]: any; } => {
    const formConfig = getFormConfig(type || recordType) || []
    if (formConfig.length) {
      const formatFormConfigArray = formConfig?.map(
        formGroup => formGroup.fields.map(
          formComp => ({ [formComp?.name]: formComp?.defaultValue }),
        ),
      )

      return formatFormConfigArray.flat().reduce(
        (previousValue, currentValue) => (
          { ...previousValue, ...currentValue }
        ),
      )
    }
    return {}
  }

  const methods = useForm({
    defaultValues: constructDefaultFormData(),
    mode: 'all',
  })
  const [formRecordType, setFormRecordType] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(loadingForm)
  }, [loadingForm])

  // disabled because shape of the submitted data isn't known till runtime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildFormData = <T extends {[x: string]: any}>(data: T, newStatus?: string) => {
    const formConfig = getFormConfig(recordType)
    let payloadStructure = fetchFormDataStructure(recordType)
    const payloadSchema = formConfig?.flatMap(
      group => group.fields.map(field => field.name))
    const multiSelectFields = formConfig?.flatMap(
      group => group.fields.map(field => field.type === 'multiselect' && field.name))

    let payloadObj: Record<string, unknown> = {}
    if (payloadSchema) {
      for (const field of payloadSchema) {
        if (multiSelectFields &&
          multiSelectFields.includes(field) &&
          data &&
          data[field] &&
          Array.isArray(data[field])
        ) {
          payloadObj[field] = data[field].map((
            value: {id: number | string, name: string} | string) =>
            typeof value === 'string' ? value : value?.id,
          )
        } else {
          payloadObj[field] = data?.[field]
        }
      }
    }

    if (appendValue) {
      payloadObj = { ...appendValue, ...payloadObj }
    }

    // apply the new status to the payload
    if (newStatus && payloadStructure) {
      payloadStructure = { ...payloadStructure, status: newStatus }
    }

    if (!recordId && !payloadObj?.date_last_updated) {
      payloadObj.date_last_updated = new Date()
    }
    return { ...payloadStructure, data: { ...payloadObj } }
  }

  const onSubmit = <T extends {[x: string]: unknown}>(data: T, newStatus?: 'draft' | 'pending' | 'published') => {
    const generateSavedType = () => (
      newStatus && newStatus === 'pending'
        ? 'marked for review'
        : (newStatus || 'saved')
    )

    setLoading(true)
    setFormSubmitted(true)
    if (recordId && !isNewRecord) {
      spudUpdate(
        recordId,
        recordType,
        buildFormData(data, newStatus))
        .then((response) => {
          onSaveCallback(response.data, true)
          setLoading(false)
          setFormSubmitted(false)
          setChangeStatus(false)
          setHasSaved(true)
          toast.custom(
            <SPUDToastNotification
              title="Success"
              message={
                <span>
                  {getPageType(recordType)} <strong>{response.data.data.name}
                    {' '}({response.data.revision.record})</strong> has been {generateSavedType()}
                </span>
              }
              success
            />,
            {
              position: 'bottom-center',
            },
          )
        }).catch((error) => {
          setLoading(false)
          setFormSubmitted(false)
          setChangeStatus(false)
          toast.custom(
            <SPUDToastNotification
              title="Error"
              message={
                <span>
                  Something went wrong when saving <strong>{getPageType(recordType)} ({recordId})</strong>
                  {' '}please try again
                  <br/>
                  <strong>Error: </strong> {error?.response?.data?.detail}
                </span>
              }
              error
            />,
            {
              position: 'bottom-center',
            },
          )
        })
    } else {
      spudSave(
        recordType,
        buildFormData(data))
        .then((response) => {
          onSaveCallback(response.data)
          setLoading(false)
          setFormSubmitted(false)
          setHasSaved(true)
          toast.custom(
            <SPUDToastNotification
              title="Created"
              message={
                <span>
                  {getPageType(recordType)} <strong>{response.data.data.name}
                    {' '}({
                      fetchCorrectIdToDisplay(
                        response.data.revision.record,
                        response.data.revision.iss_id,
                      )})</strong> has been created
                </span>
              }
              success
            />,
            {
              position: 'bottom-center',
            },
          )
        }).catch((error) => {
          setLoading(false)
          setFormSubmitted(false)
          toast.custom(
            <SPUDToastNotification
              title="Error"
              message={
                <span>
                  Something went wrong on creating the <strong>{getPageType(recordType)}</strong>
                  {' '}please try again
                  <br/>
                  <strong>Error: </strong> {error?.message}
                </span>
              }
              error
            />,
            {
              position: 'bottom-center',
            },
          )
        })
    }
    setHasSaved(false)
  }

  const onSubmitForReview = <T extends {[x: string]: unknown}>(data: T) => {
    onSubmit(data, 'pending')
  }

  const onPublish = <T extends {[x: string]: unknown}>(data: T) => {
    onSubmit(data, 'published')
  }

  useEffect(() => {
    if (recordType !== formRecordType) {
      methods.reset(constructDefaultFormData(recordType))
      setFormRecordType(recordType)
    } else {
      if (typeof formData !== 'undefined' && formData !== null) {
        const data = formData.data
        if (data) {
          for (const formKey of Object.keys(data) as Array<
            keyof SPUDSiteRecordDetails & keyof SPUDOrganisationRecordDetails>) {
            methods.setValue(formKey, data[formKey])
          }
        }
      }
    }
  }, [recordType, formData?.data])

  const constructRules = (formElement: Field) => {
    if (!changeStatus) {
      switch (recordType) {
      case 'site':
      case 'service':
        if (['name', 'datasets'].includes(formElement.name)) {
          return { ...formElement.rules }
        } else {
          return { ...formElement.rules, required: false }
        }
      case 'organisation':
        if (['name'].includes(formElement.name)) {
          return { ...formElement.rules }
        } else {
          return { ...formElement.rules, required: false }
        }
      }
    }
    return { ...formElement.rules }
  }

  const hasFormBeenSubmitted = (): boolean => {
    if (isNewRecord) {
      return false
    } else if (methods.formState.isValidating || methods.formState.isSubmitted ||
      (typeof recordId === 'string' && recordId)) {
      return true
    }
    return false
  }

  // Some custom components include their own validation and errors,
  // and don't require the top level errors from being displayed
  const excludeTopLevelErrorsFromShowing = ['location', 'phones']

  const formatErrorMessage = (label: string, fieldName: string) => {
    if (!methods.formState.errors?.[fieldName]?.message) {
      return `${label} is ${methods.formState.errors?.[fieldName]?.type || 'required'}`
    } else {
      return `${methods.formState.errors?.[fieldName]?.message}`
    }
  }

  const onUpdateDateChange = (checked: boolean, newDate?: Date) => {
    let lastUpdated
    if (checked) {
      lastUpdated = new Date()
    } else if (formData.data.date_last_updated) {
      lastUpdated = formData.data.date_last_updated
    }
    if (newDate) {
      lastUpdated = newDate
    }
    methods.setValue('date_last_updated', lastUpdated)
    // Field needs to be triggered for the value to be set properly
    methods.trigger('date_last_updated')
  }

  const actionCheck = async () => {
    const response = await checkRecordActions({ recordId: recordId, recordType })
    setActionButtonsDisabled(response.data)
    setActionButtonValidationLoading(false)
  }

  useEffect(() => {
    if (recordId) {
      setActionButtonValidationLoading(true)
      actionCheck()
    }
  }, [recordId])

  /**
   * Check if a record can be published by
   * making sure the parent records have already been published
   * Exception is an Organisation
   */
  const canRecordBePublished = (): boolean => {
    switch (recordType) {
    case 'service':
      return !!formData?.site?.iss_id
    default:
      return true
    }
  }

  // Get the related focus field name when icon is clicked in Quick Access Bar.
  const getFocusFieldName = (quickAccessButtonClicked: string) => {
    switch (quickAccessButtonClicked) {
    case 'Location':
      return 'location'
    case 'Contact':
      return 'Telephone'
    case 'Operational':
      return 'catchment_description'
    case 'Legacy':
      return 'details'
    default:
      return 'name'
    }
  }

  const setLocationFieldFocus = () => {
    const searchForLocation = document.getElementById('spud-location-autocomplete-Search for Location')
    const locationInput = searchForLocation?.getElementsByTagName('input')[0]
    locationInput?.focus()
  }

  const setFieldFocus = (id: string) => {
    const fieldFocus = document.getElementById(id)
    fieldFocus?.focus()
  }

  // Jump to a related field and set focus when user clicks icon in Quick Access Bar.
  useEffect(() => {
    setTimeout(() => {
      switch (quickAccessButtonClicked) {
      case 'Location':
        return window.requestAnimationFrame(setLocationFieldFocus)
      case 'Contact':
      case 'Operational':
      case 'Legacy':
        return window.requestAnimationFrame(() => {
          setFieldFocus(getFocusFieldName(quickAccessButtonClicked))
        })
      default:
        return methods.setFocus(getFocusFieldName(quickAccessButtonClicked))
      }
    }, 500)
  }, [quickAccessButtonClicked])

  return (
    <div ref={formRef}>
      <div style={{
        height: 75,
        marginBottom: '1em',
        width: !(scrollPosition > 100) ? 'auto' : formRef?.current?.clientWidth || '100%',
      }}
      >
        <AddEditRecordControlBar
          recordId={recordId}
          onSaveRecord={methods.handleSubmit((data) => onSubmit(data))}
          loading={loading && !loadingForm}
          pageLoading={loadingForm}
          formState={methods.formState}
          onSubmitForReview={methods.handleSubmit(onSubmitForReview)}
          onPublish={methods.handleSubmit(onPublish)}
          onCanChangeStatus={(submitForReviewStatus: boolean) => {
            setChangeStatus(submitForReviewStatus)
            if (submitForReviewStatus) {
              methods.trigger()
            }
          }}
          changeStatus={changeStatus}
          onShowRightPanel={(show) => {
            // A really hacky way for the dom to refresh the clientWidth so then when scrolled down
            // and the top bar is sticky it will fill the space when the right panel is closed, or
            // it will shrink when the right panel is open
            // It checks if it's sticky then scrolls down one place to trigger an update
            if (scrollPosition > 100) {
              window.scrollTo(0, scrollPosition + 1)
            }
            onShowRightPanel(show)
          }}
          hasSaved={hasSaved}
          onUpdateToggle={onUpdateDateChange}
          lastUpdated={methods.getValues('date_last_updated')}
          isNewRecord={isNewRecord}
          onLastUpdateChange={onUpdateDateChange}
          status={formData?.revision?.status || 'published'}
          actionButtonsDisabled={actionButtonsDisabled}
          onCopyRecord={copyRecordHandler(
            recordId,
            recordType as string,
            formData?.organisation?.id || undefined,
            spudCopyService,
            setDialogOptions,
            history,
          )}
          canRecordBePublished={canRecordBePublished}
          recordType={recordType}
          onDeleteRecord={deleteRecordHandler(
            recordId as number,
            recordType as string,
            spudDelete,
            setDialogOptions,
            actionCheck,
            onSaveCallback,
            actionButtonsDisabled.eReferral,
          )}
          onRestoreRecord={restoreRecordHandler(
            recordId as number,
            recordType as string,
            spudRestore,
            setDialogOptions,
            actionCheck,
            onSaveCallback,
          )}
          active={(recordId && formData.is_active) || !recordId}
          actionButtonValidationLoading={actionButtonValidationLoading}
        />
      </div>
      <Row>
        <Col direction='row' style={{ paddingRight: 0 }}>
          <ControlBarContainer ref={QuickAccessMenuContainerRef} sticky={scrollPosition > 100}>
            <QuickAccessToolbar
              buttonList={generateQuickAccessToolbar(getFormConfig(recordType),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                methods.formState.errors,
                hasFormBeenSubmitted(),
              )}
              expandedCallback={(expanded: boolean) => setQuickAccessExpanded(expanded)}
              clickedOptionCallback={(optionClicked: string) => setQuickAccessButtonClicked(optionClicked)}
            />
          </ControlBarContainer>
          <Toaster />
          <FormProvider {...methods} >
            <form
              style={{
                width: '100%',
                marginLeft: scrollPosition > 100 ? quickAccessExpanded ? '13em' : '5em' : '0',
              }}
              onSubmit={(evt) => evt.preventDefault()}
            >
              <SPUDFormFieldset disabled={loading || (!!(recordId && !formData.is_active))}>
                {
                  getFormConfig(recordType)?.length &&
                  getFormConfig(recordType)?.map(formGroup => (
                    <Accordion
                      padding='0 0 10px 10px'
                      heading={formGroup.title}
                      key={formGroup.title}
                      innerRef={formGroup.ref}
                      status={validateSections(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        methods.formState.errors,
                        formGroup, hasFormBeenSubmitted(),
                      )}
                      defaultOpen={true}
                      open={quickAccessButtonClicked && quickAccessButtonClicked === formGroup.title}
                      scrollIntoViewOnOpen
                    >
                      {formGroup.fields.map(formElement => (
                        <>
                          <Controller
                            key={formElement.name}
                            name={formElement.name}
                            control={methods.control}
                            rules={ constructRules(formElement) }
                            render={({ field }) =>
                              fetchCorrectComponent(formElement,
                                !recordId || !changeStatus,
                                userRole, formData, recordType, linkedRecords,
                                field, methods.getValues, methods.setValue,
                                formSubmitted,
                              )
                            }
                          />
                          {formElement?.name &&
                          methods.formState.errors?.[formElement?.name] &&
                          !excludeTopLevelErrorsFromShowing.includes(formElement?.name) &&
                          <span>
                            <Alert type="error">
                              {formatErrorMessage(formElement.label, formElement.name)}
                            </Alert>
                          </span>
                          }
                        </>
                      ))}
                    </Accordion>
                  ))
                }
              </SPUDFormFieldset>
            </form>
          </FormProvider>
        </Col>
      </Row>
    </div>
  )
}

export default AddEditRecord
