var CSV_FILE_TYPE = "text/csv;charset=utf-8;";
var csvDownload = function (_a) {
    var data = _a.data, _b = _a.filename, filename = _b === void 0 ? "export.csv" : _b, _c = _a.delimiter, delimiter = _c === void 0 ? ";" : _c, headers = _a.headers;
    var formattedFilename = getFilename(filename);
    if (data.length === 0) {
        triggerCsvDownload(headers ? headers.join(delimiter) : "", formattedFilename);
        return;
    }
    var headerKeys = Object.keys(data[0]);
    var columnNames = headers !== null && headers !== void 0 ? headers : headerKeys;
    var csv = data.map(function (row) {
        return headerKeys
            .map(function (fieldName) { var _a; return JSON.stringify(row[fieldName] === 0 ? 0 : (_a = row[fieldName]) !== null && _a !== void 0 ? _a : ""); })
            .join(delimiter);
    });
    csv.unshift(columnNames.join(delimiter));
    var csvAsString = csv.join("\r\n");
    triggerCsvDownload(csvAsString, formattedFilename);
};
var triggerCsvDownload = function (csvAsString, fileName) {
    // BOM support for special characters in Excel
    var byteOrderMark = "\ufeff";
    var blob = new Blob([byteOrderMark, csvAsString], {
        type: CSV_FILE_TYPE,
    });
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
var getFilename = function (providedFilename) {
    return /csv$/i.test(providedFilename)
        ? providedFilename
        : "".concat(providedFilename, ".csv");
};
export default csvDownload;
