import React, { useState } from 'react'
import { withContext } from '../../context/AppContext'
import { AxiosPromise } from 'axios'
import { SPUDRecord } from '../../../@types/SPUDRecord.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import _ from 'lodash'
import styled from 'styled-components'
import {
  Badge,
  Button,
  Col,
  Input,
  Link,
  Row,
  Title,
  Page,
  Header,
  Container,
  Content,
  Pagination,
} from '@ix/ix-ui'
import pluralize from 'pluralize'
import { fetchCorrectHeading } from '../page.service'
import { fetchCorrectIdToDisplay } from '../../helpers/record'

type SearchResultsPageProps = {
  match: {
    params: {
     searchTerm: string,
    }
  },
  searchRecords: (
    searchTerm: string,
    params: {
      seeMore: boolean,
      limit: number,
      offset: number,
    }
  ) => AxiosPromise<{
      results: Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>>,
      next: string | null,
      count: number,
  }>
}

export const SearchListResultColumn = styled(Col)`
  padding: 1em;
`

export const SearchListResultColumnSeperator = styled.hr`
  width: 100%;
  border: 1px solid #8c9bad;
  height: 1px;
`

export const SearchListResultList = styled.ul`
  padding: 5px;
  margin: 0;
  list-style: none;
`

export const SearchListResultListItem = styled.li`
  padding: 5px;
  margin: 0;
  text-align: left;
`

export const SPUDQuickSearchInputField = styled(Input)`
  div {
    margin: 0;
  }
  border: 1px solid #3a8ae8;
  width: 30em;
  margin-bottom: 0;
  :hover {
    border: 1px solid #3a8ae8;
  }
`

export const SPUDQuickSearchButton = styled(Button)`
  margin:0;
`

const SearchResultsContent = styled(Row)`
  background-color: #fff;
`

const SearchResultsSearchBox = styled(Row)`
  margin: 1em 0;
`

function SearchResultsPage ({ match, searchRecords }: SearchResultsPageProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalNoRecords, setTotalNoRecords] = useState(0)
  const [sites, setSites] = useState<Array<SPUDRecord<SPUDSiteRecordDetails>>>([])
  const [services, setServices] = useState<Array<SPUDRecord<SPUDServiceRecordDetails>>>([])
  const [organisations, setOrganisations] = useState<Array<SPUDRecord<SPUDOrganisationRecordDetails>>>([])
  const [page, setPage] = useState<number>(1)
  const perPage = 200

  const searchForRecords = async (searchText = '', pageOverride?: number) => {
    setLoading(true)
    setSites([])
    setServices([])
    setOrganisations([])
    window.history.replaceState({ searchTerm: searchText || searchTerm }, 'SPUD', `/search/${searchText || searchTerm}`)
    const response = await searchRecords(searchText || searchTerm, {
      seeMore: true,
      limit: perPage,
      offset: ((pageOverride || page) - 1) * perPage,
    })
    setTotalNoRecords(response.data.count)
    setLoading(false)
    const filteredResponse = response.data.results.filter(result => result.update?.data.name)
    const groupedRecords = _.groupBy(filteredResponse, 'record_type')
    if (groupedRecords?.site) {
      setSites(groupedRecords?.site as Array<SPUDRecord<SPUDSiteRecordDetails>>)
    }
    if (groupedRecords.service) {
      setServices(groupedRecords?.service as Array<SPUDRecord<SPUDServiceRecordDetails>>)
    }
    if (groupedRecords.organisation) {
      setOrganisations(groupedRecords?.organisation as Array<SPUDRecord<SPUDOrganisationRecordDetails>>)
    }
  }

  const handlePage = (param: { currentPage: number }): void => {
    setPage(param.currentPage)
    setSearchTerm(match.params.searchTerm)
    searchForRecords(match.params.searchTerm, param.currentPage)
  }

  return <Page>
    <Content>
      <Header style={{ padding: '2em 0.75em' }}>
        <Container style={{ paddingRight: '0' }}>
          {fetchCorrectHeading('search', '')}
        </Container>
      </Header>
      <div style={{ margin: '0 1em' }}>
        <SearchResultsSearchBox>
          <Col direction='row' justify='center'>
            <SPUDQuickSearchInputField
              type='text'
              value={searchTerm}
              placeholder="Search by name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setLoading(false)
                }
                setSearchTerm(event.target.value)
              }}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  searchForRecords('', 1)
                }
              }}
              fullwidth
            />
            <div style={{ margin: 7 }}>
              <SPUDQuickSearchButton
                onClick={() => searchForRecords('', 1)}
              >
                Search
              </SPUDQuickSearchButton>
            </div>
          </Col>
        </SearchResultsSearchBox>
        <Row>
          <Col direction='row' style={{ padding: '1em 0' }}>
            <Title level={4}>
             Found {totalNoRecords} {pluralize('record', totalNoRecords)}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col direction='row' style={{ padding: '1em 0' }}>
            <Pagination total={[totalNoRecords]} onPageChange={handlePage} pageSize={perPage} page={page} />
          </Col>
        </Row>
        {!loading && <SearchResultsContent>
          <SearchListResultColumn>
            <Title level={3} align='center'>
              Sites <Badge active block content={`${sites.length} found`} />
            </Title>
            <SearchListResultColumnSeperator />
            <SearchListResultList>
              {sites.map((returnedValue: SPUDRecord<SPUDSiteRecordDetails>) =>
                <SearchListResultListItem key={returnedValue.id}>
                  <Row>
                    <Col>
                      <Link
                        to={`/records/${returnedValue.record_type}/record/${
                          fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                      >
                        {returnedValue.update?.data.name || 'Unknown record name'}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      ({returnedValue.update?.data?.location?.suburb})
                    </Col>
                  </Row>
                </SearchListResultListItem>)}
            </SearchListResultList>
          </SearchListResultColumn>
          <SearchListResultColumn>
            <Title level={3} align='center'>
              Services <Badge active block content={`${services.length} found`} />
            </Title>
            <SearchListResultColumnSeperator />
            <SearchListResultList>
              {services.map((returnedValue: SPUDRecord<SPUDServiceRecordDetails>) =>
                <SearchListResultListItem key={returnedValue.id}>
                  <Row>
                    <Col>
                      <Link
                        to={`/records/${returnedValue.record_type}/record/${
                          fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                      >
                        {returnedValue.update?.data.name || 'Unknown record name'}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      ({returnedValue.site?.name})
                    </Col>
                  </Row>
                </SearchListResultListItem>)}
            </SearchListResultList>
          </SearchListResultColumn>
          <SearchListResultColumn>
            <Title level={3} align='center'>
              Organisation <Badge active block content={`${organisations.length} found`} />
            </Title>
            <SearchListResultColumnSeperator />
            <SearchListResultList>
              {organisations.map((returnedValue: SPUDRecord<SPUDOrganisationRecordDetails>) =>
                <SearchListResultListItem key={returnedValue.id}>
                  <Link
                    to={`/records/${returnedValue.record_type}/record/${
                      fetchCorrectIdToDisplay(returnedValue.id, returnedValue.iss_id)}`}
                  >
                    {returnedValue.update?.data.name || 'Unknown record name'}
                  </Link>
                </SearchListResultListItem>)}
            </SearchListResultList>
          </SearchListResultColumn>
        </SearchResultsContent>}
        {loading && 'Loading...'}
      </div>
    </Content>
  </Page>
}

export default withContext(SearchResultsPage)
