import React, { useEffect, useState } from 'react'
import {
  AdvancedFilterType, DATE_OPERATOR_MAP, DateFilterValueType,
  DateSearchOperators,
} from '../RecordFilter.service'
import { Row, Col, Title, Alert } from '@ix/ix-ui'
import { Option } from '../../../components/forms/AddEditRecord.type'
import Select from 'react-select'
import { format, isAfter, isBefore, isValid, parse } from 'date-fns'
import MaskedTextInput from 'react-text-mask'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'

export type DateFilterProps = {
  recordType: string,
  onChangeCallback: (
    filterField: AdvancedFilterType<DateFilterValueType>,
    value: DateFilterValueType,
  ) => void
  filterField: AdvancedFilterType<DateFilterValueType>,
  filterFieldProperties: {
    dateSearchOperators: Array<DateSearchOperators>
  }
}

type DateFilterSearchOperatorsProps = {
  onChange: (searchOperator: Option) => void
  searchOperators: Array<DateSearchOperators>
  value: DateSearchOperators
}

const DateFilterPicker = styled(DatePicker)<{fullWidth: boolean}>`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #3a8ae8;
`

function DateFilterSearchOperators (
  {
    onChange,
    searchOperators,
    value,
  }: DateFilterSearchOperatorsProps) {
  const [options, setOptions] = useState<Array<{id: DateSearchOperators, name: string}>>([])
  const [searchOperator, setSearchOperator] = useState<Option>(
    {
      id: 'before',
      name: DATE_OPERATOR_MAP.before,
    })

  useEffect(() => {
    if (searchOperators) {
      const dateSearchOperations: Array<{id: DateSearchOperators, name: string}> =
          searchOperators?.map(operator => ({
            id: operator,
            name: DATE_OPERATOR_MAP?.[operator],
          }))
      if (value) {
        const foundOperator = dateSearchOperations.find(option => option.id === value)
        if (foundOperator) {
          setSearchOperator(foundOperator)
        }
      }
      setOptions(dateSearchOperations)
    }
  }, [])

  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      backgroundColor: '#f6f6f6',
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
      textAlign: 'left',
    }),
    menuList: () => ({
      textAlign: 'left',
    }),
  }
  return <Col>
    <Row>
      <Col>
        <Title level={4} marginTop='4px'>
          Search operator
        </Title>
      </Col>
    </Row>
    <Row>
      <Col>
        <Select
          // Ignoring because it's complaining that MenuList
          // is not a valid style option which it is
          // https://react-select.com/styles#the-unstyled-prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //  @ts-ignore
          styles={customStyle}
          id='spud-search-modifiers'
          options={options}
          value={searchOperator as Option}
          // The getOptionValue has to be a string
          getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
          isClearable={false}
          defaultValue={searchOperator as Option || null}
          getOptionLabel={(option: Option) => option.name }
          onChange={(option) => {
            setSearchOperator(option as Option)
            onChange(option as Option)
          }}
        />
      </Col>
    </Row>
  </Col>
}

function DateFilter (
  {
    onChangeCallback,
    filterField,
    filterFieldProperties,
  } : DateFilterProps) {
  const validateRange = (): boolean => {
    let validRange = false
    if (
      filterField.value.fromDate &&
      filterField.value.date &&
      filterField.value.dateSearchOperator === 'range'
    ) {
      validRange = isAfter(
        parse(filterField.value.fromDate, 'dd/MM/yyyy', new Date()),
        parse(filterField.value.date, 'dd/MM/yyyy', new Date()),
      )
      if (!validRange) {
        validRange = isBefore(
          parse(filterField.value.date, 'dd/MM/yyyy', new Date()),
          parse(filterField.value.fromDate, 'dd/MM/yyyy', new Date()),
        )
      }
    }
    return validRange
  }

  return (
    <>
      <Row>
        <DateFilterSearchOperators
          searchOperators={filterFieldProperties.dateSearchOperators}
          onChange={(searchOperator) => {
            let updatedFilter = {
              ...filterField.value,
              dateSearchOperator: searchOperator.id as DateSearchOperators,
            }
            if (searchOperator.id === 'range') {
              updatedFilter = {
                ...updatedFilter,
                fromDate: format(new Date(), 'dd/MM/yyyy'),
              }
            }
            onChangeCallback(filterField, updatedFilter)
          }}
          value={filterField.value.dateSearchOperator}
        />
      </Row>
      <Row>
        {filterField.value.dateSearchOperator === 'range' && <Col>
          <DateFilterPicker
            name='fromDate'
            fullWidth={true}
            selected={
              typeof filterField.value.fromDate === 'string'
                ? parse(filterField.value.fromDate, 'dd/MM/yyyy', new Date())
                : new Date()
            }
            dateFormat='dd/MM/yyyy'
            onChange={(date: Date) => {
              let formatDate = date?.toDateString() || null
              if (isValid(date)) {
                formatDate = format(date, 'dd/MM/yyyy')
              }
              onChangeCallback(filterField, {
                ...filterField.value,
                fromDate: formatDate,
              })
            }}
            onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newDate = parse(event.target.value, 'dd/MM/yyyy', new Date())
              const validDate = isValid(newDate)
              if (validDate) {
                const formatDate = format(newDate, 'dd/MM/yyyy')
                onChangeCallback(filterField, {
                  ...filterField.value,
                  fromDate: formatDate,
                })
              }
            }}
            popperModifiers={[
              {
                name: 'arrow',
                options: {
                  padding: 90,
                },
              },
            ]}
            placeholderText='dd/MM/yyyy'
            todayButton="Today"
            isClearable={true}
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            }
            maxDate={
              typeof filterField.value.date === 'string'
                ? parse(filterField.value.date, 'dd/MM/yyyy', new Date())
                : new Date()
            }
            disabled={false}
          />
        </Col>}
        <Col>
          <DateFilterPicker
            name={filterField.name}
            fullWidth={true}
            selected={
              typeof filterField.value.date === 'string'
                ? parse(filterField.value.date, 'dd/MM/yyyy', new Date())
                : new Date()
            }
            dateFormat='dd/MM/yyyy'
            onChange={(date: Date) => {
              let formatDate = date?.toDateString() || null
              if (isValid(date)) {
                formatDate = format(date, 'dd/MM/yyyy')
              }
              if (filterField.value.dateSearchOperator !== 'range') {
                const copyOfFilterFieldValue = { ...filterField.value }
                delete copyOfFilterFieldValue?.fromDate
                onChangeCallback(filterField, {
                  ...copyOfFilterFieldValue,
                  date: formatDate,
                })
              } else {
                onChangeCallback(filterField, {
                  ...filterField.value,
                  date: formatDate,
                })
              }
            }}
            onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newDate = parse(event.target.value, 'dd/MM/yyyy', new Date())
              const validDate = isValid(newDate)
              if (validDate) {
                const formatNewDate = format(newDate, 'dd/MM/yyyy')
                if (filterField.value.dateSearchOperator !== 'range') {
                  const copyOfFilterFieldValue = { ...filterField.value }
                  delete copyOfFilterFieldValue?.fromDate
                  onChangeCallback(filterField, {
                    ...copyOfFilterFieldValue,
                    date: formatNewDate,
                  })
                } else {
                  onChangeCallback(filterField, {
                    ...filterField.value,
                    date: formatNewDate,
                  })
                }
              }
            }}
            popperModifiers={[
              {
                name: 'arrow',
                options: {
                  padding: 90,
                },
              },
            ]}
            placeholderText='dd/MM/yyyy'
            todayButton="Today"
            isClearable={true}
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            }
            disabled={false}
          />
        </Col>
      </Row>
      {validateRange() && <Row>
        <Col>
          <Alert type="error">
            Date range is incorrect
          </Alert>
        </Col>
      </Row>}
    </>)
}

export default DateFilter
