import React, { useState, useEffect, ReactNode } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'

import {
  Alert,
  Card,
  Dropdown,
  Title,
  Header,
  Page,
  Section,
  Content,
  Link as IXLink,
  Row,
  Col,
  Button,
  Table,
  Paragraph,
  Pagination,
  Skeleton,
} from '@ix/ix-ui'

import { withContext } from '../context/AppContext'
import { AppContextType } from '../context/AppContext.type'

type Props = RouteComponentProps & AppContextType & {
  spudFetch: () => [
    {
      isLoading: boolean,
      isError: boolean,
      data: {
        results: Array<{
          username: string,
          id: number
        }>,
        count: number,
      }
      },
    React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  ]
}

function UsersPage (props: Props): React.ReactElement {
  const [dataResults, setDataResults] = useState<Array<{
    username: string,
    action: ReactNode | null,
  }>>([])

  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState('asc')
  const [sorted, setSorted] = useState(0)
  const [page, setPage] = useState(1)
  const perPage = 3
  let sorting = sort === 'asc' ? '' : '-'

  const dataHeaders = [
    {
      name: 'Name',
      key: 'username',
    },
    {
      name: '',
      key: 'action',
      sortable: false,
    },
  ]

  const [{ data, isLoading, isError }, updateParams] = props.spudFetch()

  const handleSort = (param: number) => (e: Event): void => {
    e.preventDefault()
    setSorted(param)
    if (sort === 'asc') {
      setSort('desc')
    } else {
      setSort('asc')
    }
  }

  const handlePage = (param: {currentPage: number}): void => {
    setPage(param.currentPage)
  }

  useEffect(() => {
    sorting = sort === 'asc' ? '' : '-'
    const params = {
      url: 'users',
      params: {
        offset: (page - 1) * perPage,
        limit: perPage,
        ordering: sorting + dataHeaders[sorted].key,
      },
    }
    updateParams(params)
  }, [sort, page])

  useEffect(() => {
    const ellipsis = (<FontAwesomeIcon icon={faEllipsisH as IconProp } fixedWidth />)
    if (data.results) {
      setTotal(data.count)
      const results = data.results.map((item: { username: string; id: number }) => ({
        username: item.username,
        action: (
          <Dropdown transparent iconUp={ellipsis} title="" iconDown={ellipsis} width="30px" height="30px">
            <ul style={{ margin: '10px 20px 10px 0' }}>
              <li><Link to={`/user/${item.id}/edit`}>Edit</Link></li>
              <li><IXLink>Delete</IXLink></li>
            </ul>
          </Dropdown>
        ),
      }))
      setDataResults(results)
    }
  }, [data])

  let results
  if (dataResults.length > 0) {
    results = (
      <Table
        sorted={sorted}
        sort={sort}
        rows={dataResults}
        title="List of users"
        headers={dataHeaders}
        callbackSort={handleSort}
      />
    )
  } else {
    results = (
      <div style={{ textAlign: 'center' }}>
        <Card>

          <Title level={2} align="center">No users found</Title>

          <Paragraph style={{ textAlign: 'center' }}>Oops, we couldn&apos;t find any users.</Paragraph>

          <Paragraph style={{ textAlign: 'center' }}><Button primary>Add a user</Button></Paragraph>
        </Card>
      </div>
    )
  }

  return (
    <Page>
      <Content>
        <Header>
          <Row>
            <Col>
              <Title level={1}>
                Users
              </Title>
            </Col>
            <Col>
              <Button
                style={{ marginLeft: 'auto' }}
                primary
                to="/admin/user/add"
              >
                Add user
              </Button>
            </Col>
          </Row>
        </Header>
        <Section>

          {isError && <Alert type="error">{isError}</Alert>}

          {isLoading
            ? (
              <Card><Skeleton /></Card>
            )
            : (
              results
            )}

          <Row>
            <Col>
              <Row justify="flex-end">
                <Pagination total={[total]} onPageChange={handlePage} pageSize={perPage} />
              </Row>
            </Col>
          </Row>
        </Section>
      </Content>
    </Page>
  )
}

export default withContext(UsersPage)
