import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withContext } from '../context/AppContext'

import { Skeleton, Avatar } from '@ix/ix-ui'
import styled from 'styled-components'

type Props = {
  user: {
    // eslint-disable-next-line camelcase
    first_name: string,
    // eslint-disable-next-line camelcase
    last_name: string,
    id: string | null,
  }
  dark?: boolean
  disableAction?: boolean
}

const SPUDAvatarTooltip = styled.div`
  position: absolute;
  z-index: 2;
  font-size: 1.2em;
  right: -5em;
  padding: 0.2em;
  top: -1em;
  border: 1px solid #6e6e6e;
  background-color: #000;
  color: #fff;
`

function SpudAvatar ({ user, dark, disableAction }: Props): React.ReactElement {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    user?.first_name
      ? (
        disableAction
          ? <div onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}>
            <Avatar round name={`${user.first_name} ${user.last_name}`} abbreviate dark={dark}/>
            {showTooltip && <SPUDAvatarTooltip>{user.first_name} {user.last_name}</SPUDAvatarTooltip>}
          </div>
          : <Link to={`/user/${user.id}`}>
            <Avatar round name={`${user.first_name} ${user.last_name}`} abbreviate dark={dark}/>
          </Link>
      )
      : <Skeleton type="circle" style={{ width: '30px', height: '30px' }}/>
  )
}

export default withContext(SpudAvatar)
