import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { areIntervalsOverlapping, format, isDate, parse } from 'date-fns'
import { OutsideComponentClick } from '../../../effects/OutsideComponentClick'
import { Alert, Button, Col, Row, Text, Title } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDInputField } from '../../../helpers/record'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import {
  parseDateValue,
  checkCloseIsAfterOpen,
  getHighlightedTime,
  scrollToAndHighlightTypedTime, handleMonthContainer,
} from './OpeningHours.service'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'

const OpenHoursDayPopperContainer = styled.div`
  padding: 1em;
  min-height: 100px;
  z-index: 2;
  width: 50%;
  position: absolute;
  box-shadow: 0 0 0 1px #eaeaea;
  background-color: #fff;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  bottom: 3em;
`

const CustomTimePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background: none;
  border: 1px solid #3a8ae8;
`
const BottomArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid black;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 1.5em;
`

const OpeningHoursSummaryChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
  :focus {
    border: solid 1px #3a8ae8;
  }
`

type EditOpeningHoursPopupProps = {
  indexes: number[],
  onClose: () => void,
  append: (value: {[x: string] : string}) => void,
  update: (index: number, value: {[x: string] : string}) => void,
  remove: (index: number) => void,
  day: string,
  disabled: boolean,
}

function EditOpeningHoursPopup (
  {
    indexes,
    onClose,
    append,
    update,
    day,
    remove,
    disabled,
  }: EditOpeningHoursPopupProps): React.ReactElement {
  const [comment, setComment] = useState('')
  const [inValidTimes, setInvalidTimes] = useState(false)
  const { control, trigger, getValues } = useFormContext()

  /**
   * Loop through all times for the current day and check if the time
   * being set/edited doesn't overlap previous times
   * @param time
   * @param ignoreIndex
   */
  const checkForOverlappingTimes = (time: {
    open?: Date, close?: Date
  }, ignoreIndex: number): boolean => {
    let inValid = false
    const midnight = new Date(new Date().setHours(0, 0, 0, 0))
    for (const index of indexes) {
      if (getValues(`opening_hours.${index}.open` as string) && getValues(`opening_hours.${index}.close` as string) &&
        time.open && time.close && index !== ignoreIndex) {
        if (!inValid) {
          // Checking whether midnight to midnight has already been set to force it to be invalid
          // Otherwise check for overlapping like normal
          if (getValues(`opening_hours.${index}.open`) === '00:00' &&
            getValues(`opening_hours.${index}.close`) === '00:00') {
            inValid = true
          } else if (
            (time.open.getHours() === midnight.getHours() && time.open.getMinutes() === midnight.getMinutes()) &&
            (time.close.getHours() === midnight.getHours() && time.close.getMinutes() === midnight.getMinutes())
          ) {
            inValid = true
          } else {
            try {
              inValid = areIntervalsOverlapping({
                start: parse(getValues(`opening_hours.${index}.open`), 'HH:mm', new Date()),
                end: parse(getValues(`opening_hours.${index}.close`), 'HH:mm', new Date()),
              }, {
                start: time.open, end: time.close,
              }, { inclusive: true })
            } catch (e) {
              // if the popper is closed with an open is after close error the areIntervalsOverlapping raises an
              // invalid interval execution meaning we will need to re-check CloseIsAfterOpen to
              // indicate that there is something wrong
              return checkCloseIsAfterOpen(time.close, getValues(`opening_hours.${index}`), false)
            }
          }
        }
      }
    }
    return inValid
  }

  useEffect(() => {
    let currentComment = ''
    for (const index of indexes) {
      const savedComment = getValues(`opening_hours.${index}.note`)
      if (currentComment !== savedComment) {
        currentComment = ` ${savedComment}`
      }
      setComment(currentComment)
      const timeSlotAlreadyExists = checkForOverlappingTimes(
        {
          open: parseDateValue(getValues(`opening_hours.${index}.open`)),
          close: parseDateValue(getValues(`opening_hours.${index}.close`)),
        }, index)
      setInvalidTimes(timeSlotAlreadyExists)
    }
  }, [indexes?.length])

  const openingHoursRef = useRef(null)

  const clickedOutsideComponent = OutsideComponentClick(openingHoursRef)

  useEffect(() => {
    if (clickedOutsideComponent) {
      onClose()
    }
  }, [clickedOutsideComponent])

  const timeList = document.getElementsByClassName('react-datepicker__time-list')

  const onTimeChange = (time: Date, index: number, field :ControllerRenderProps, type: 'open'|'close') => {
    const timeToCheck: Date = getHighlightedTime(time, timeList)
    const isValid = checkCloseIsAfterOpen(timeToCheck, getValues(`opening_hours.${index}`), type === 'open')
    let timeSlotAlreadyExists = false
    if (isValid) {
      if (type === 'open') {
        timeSlotAlreadyExists = checkForOverlappingTimes(
          {
            open: timeToCheck,
            close: parseDateValue(getValues(`opening_hours.${index}.close`)),
          }, index)
      } else {
        timeSlotAlreadyExists = checkForOverlappingTimes(
          {
            close: timeToCheck,
            open: parseDateValue(getValues(`opening_hours.${index}.open`)),
          }, index)
      }
    }
    setInvalidTimes(timeSlotAlreadyExists || !isValid)
    if (isDate(time)) {
      field.onChange(format(time, 'HH:mm'))
    } else {
      field.onChange(time)
    }
  }

  return (
    <>
      <OpenHoursDayPopperContainer ref={openingHoursRef}>
        {indexes.map((index) =>
          <Row key={index} justify='space-between' align='center' style={{ margin: '10px 0' }}>
            <Col flex={2}>
              <Controller
                name={`opening_hours.${index}.open` as string}
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <CustomTimePicker
                    selected={parseDateValue(field.value) || field.value}
                    onChange={(time: Date) => {
                      onTimeChange(time, index, field, 'open')
                      trigger(`opening_hours.${index}.open`)
                    }}
                    onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
                      scrollToAndHighlightTypedTime(event.target.value, timeList)
                    }}
                    showTimeSelect
                    onCalendarOpen={handleMonthContainer}
                    timeCaption="Time"
                    dateFormat="hh:mm aaa"
                    isClearable={true}
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [80, 0],
                        },
                      },
                    ]}
                    disabled={disabled}
                  />
                }
              />
            </Col>
            <Col align='center'>
              <FontAwesomeIcon icon={faArrowRight as IconProp} />
            </Col>
            <Col flex={2}>
              <Controller
                name={`opening_hours.${index}.close` as string}
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <CustomTimePicker
                    selected={parseDateValue(field.value) || field.value}
                    onChange={(time: Date) => {
                      onTimeChange(time, index, field, 'close')
                      trigger(`opening_hours.${index}.close`)
                    }}
                    onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
                      scrollToAndHighlightTypedTime(event.target.value, timeList)
                    }}
                    showTimeSelect
                    onCalendarOpen={handleMonthContainer}
                    timeCaption="Time"
                    dateFormat="hh:mm aaa"
                    isClearable={true}
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [80, 0],
                        },
                      },
                    ]}
                    disabled={disabled}
                  />
                }
              />
            </Col>
            {indexes.length > 1 && <Col flex={1}>
              <OpeningHoursSummaryChipButton
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.preventDefault()
                  remove(index)
                }}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </OpeningHoursSummaryChipButton>
            </Col>}
          </Row>,
        )}
        <Row>
          <Col>
            {inValidTimes && <Alert type='error' style={{ marginTop: 0 }}>
              Open/close times are invalid. Check that the times don&apos;t overlap or close is before open
            </Alert>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              active={!inValidTimes}
              style={{ width: '100%' }}
              disabled={inValidTimes}
              onClick={() => {
                append({
                  open: '',
                  close: '',
                  note: comment,
                  day,
                })
              }}
            >
                Add additional
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <SPUDInputField
              label={<Title level={4} marginTop='5px'>Comment <Text>
                (if applicable)
              </Text></Title>}
              value={comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value)
                for (const index of indexes) {
                  const currentObj = getValues(`opening_hours.${index}`)
                  update(index, { ...currentObj, note: event.target.value })
                }
              }}
              fullwidth
            />
          </Col>
        </Row>
      </OpenHoursDayPopperContainer>
      <BottomArrow />
    </>
  )
}

export default EditOpeningHoursPopup
