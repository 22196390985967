import React from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { isValid, parse, isDate } from 'date-fns'
import MaskedTextInput from 'react-text-mask'
import { Field } from '../forms/AddEditRecord.type'
import { ControllerRenderProps } from 'react-hook-form'

export const CustomDatePicker = styled(DatePicker)<{fullWidth: boolean}>`
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  padding: 10px;
  border-radius: 3px;
  background: none;
  border: 1px solid #3a8ae8;
`

type SPUDDatePickerProps = {
  formElement: Field,
  field?: ControllerRenderProps | null,
  fullWidth: boolean,
  disabled: boolean,
  hideTodayButton?: boolean,
  trigger?: (fieldName: string) => void,
}

const MIN_DATE = new Date(2000, 0, 1)

function SPUDDatePicker ({
  formElement,
  field,
  fullWidth,
  disabled,
  hideTodayButton = false,
  trigger,
}: SPUDDatePickerProps): React.ReactElement {
  return (
    <div style={{ marginTop: '5px' }}>
      <CustomDatePicker
        name={formElement.name}
        fullWidth={fullWidth}
        required={typeof formElement.rules?.required === 'boolean' && formElement.rules?.required}
        selected={field?.value && new Date(field?.value)}
        dateFormat={formElement.dateFormat}
        onChange={(date) => {
          if (date && isDate(date)) {
            if (date >= MIN_DATE) {
              field?.onChange(date)
            } else {
              field?.onChange(MIN_DATE)
            }
          } else {
            field?.onChange(date)
          }
          field?.name && trigger?.(field?.name)
        }}
        onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newDate = parse(event.target.value, formElement.dateFormat || 'dd/MM/yyyy', new Date())
          const validDate = isValid(newDate)
          if (validDate) {
            if (newDate >= MIN_DATE) {
              field?.onChange(newDate)
            } else {
              field?.onChange(MIN_DATE)
            }
          }
        }}
        onBlur={() => {
          field?.onBlur()
        }}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: 90,
            },
          },
        ]}
        placeholderText={formElement.dateFormat?.toLowerCase()}
        todayButton={!hideTodayButton ? 'Today' : undefined}
        isClearable={true}
        minDate={MIN_DATE}
        maxDate={formElement.maxDate || new Date(3000, 11, 31)}
        customInput={
          <MaskedTextInput
            type="text"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />
        }
        disabled={disabled}
      />
    </div>
  )
}

export default SPUDDatePicker
