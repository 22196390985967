import { AxiosPromise, AxiosResponse } from 'axios'
import API, { getSPUDAPIUrl } from '../helpers/api'
import { transactionParams } from './transactions.service'

export interface UserType {
    id: number,
    username: string,
    first_name: string,
    last_name: string,
}

function get (userId: number): AxiosPromise {
  return API
    .get(`${getSPUDAPIUrl()}users/${userId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      return response
    })
}

function list (props: transactionParams): AxiosPromise<{
  results: [{
    id: number,
    username: string,
    first_name: string,
    last_name: string,
  }],
  next: string
}> {
  const searchTerm: string | undefined = props?.details?.name
  const startsWithNoUn: boolean = /^(un|no)/i.test(searchTerm as string)
  return API
    .get(`${getSPUDAPIUrl()}users/`, {
      params: {
        limit: props.limit,
        offset: props.offset,
        ordering: 'first_name, last_name',
        search: props?.details?.name,
      },
    })
    .then((response: AxiosResponse<{
        results: [{
          id: number,
          username: string,
          first_name: string,
          last_name: string,
        }],
        next: string,
        previous: string
      }>) => {
      //  If no search or search term starts with 'no/un', then show the 'Nobody(un-allocated)' option in the dropdown list.
      if (!response.data.previous && (!props?.details?.name || startsWithNoUn)) {
        response.data.results.unshift({
          id: 0,
          username: 'unallocated',
          first_name: 'Nobody',
          last_name: '(un-allocated)',
        })
      }
      return response
    })
}

export const userService = {
  get,
  list,
}
