import React, { ReactNode, useEffect, useRef, useState } from 'react'

import {
  Content,
  Header,
  Section,
  Skeleton,
  Text,
  Row,
  Col,
  Card,
  Button,
  Link,
  Title,
  SpudTheme,
} from '@ix/ix-ui'
import { fetchCorrectIdToDisplay, formatAddEditTitle, getPageType } from '../../helpers/record'
import AddEditRecord from '../../components/forms/AddEditRecord'
import { withContext } from '../../context/AppContext'
import { RouteComponentProps } from 'react-router'
import { AppContextType } from '../../context/AppContext.type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AxiosPromise } from 'axios'
import LinkedRecordsList from './ActionPanel/LinkedRecordsList'
import {
  fetchLinkedRecordTableMethod,
  fetchLinkedRecordTableTitle,
  generateAppendableValueObject,
} from './RecordDetails.service'
import { SPUDRecord, SPUDRecordWithData } from '../../../@types/SPUDRecord.type'
import SimpleSPUDRecordCard from '../../components/General/SimpleSPUDRecordCard'
import styled from 'styled-components'
import { formatDatetimeToLocal } from '../../helpers/datetime'
import { IsMobile } from '../../effects/IsMobile'
import SPUDComments from './ActionPanel/Comments/SPUDComments'
import { getScrollPosition } from '../../effects/scrollPosition'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'

type matchParams = {
  recordType?: string | undefined,
  action?: string,
  revisionId?: string,
}

type Props = RouteComponentProps<matchParams> & AppContextType & {
  revisionDetail : () => AxiosPromise,
}

const HeaderBarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const HeaderSpacer = styled.div`
  margin: 1em;
`

const AdditionalInformation = styled(HeaderSpacer)`
  display: flex;
  align-items: flex-end;
  color: #4F4F4F;
`

const RecordDetailsPage = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  background: #F4F6F9;
`

const RecordDetailRightPanelCard = styled(Card)<{sticky: boolean}>`
  height: 100%;
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  overflow-y: scroll;
  top: 0;
`

const RecordForm = styled(Col)`
  &.deleted-record-form {
    input, textarea, .form-autocomplete, .form-geo-coverage,
    .form-opening-hours, .form-accreditations, .form-service-type {
      background-color: ${SpudTheme.colors.grayMedium};
      opacity: 0.9;
    }
    .status-indicator {
      background-color: ${SpudTheme.colors.grayMedium};
    }
  }
`

function RecordDetails (props: Props): React.ReactElement {
  const [revisionData, setRevisionData] = useState<
    SPUDRecordWithData<SPUDOrganisationRecordDetails | SPUDSiteRecordDetails | SPUDServiceRecordDetails>>({
      iss_id: null,
      referral_enabled: false,
      record_type: '',
      name: '',
      is_active: true,
      update: null,
      id: null,
      allocated_user: {
        first_name: '',
        last_name: '',
        id: null,
      },
      revision: null,
      last_updated_date: null,
      date_started: null,
      data: { name: '', abn: null, date_last_updated: null, id: null, iss_id: null, is_active: true },
    })
  const [isLoading, setIsLoading] = useState(false)
  const [linkedListIsLoading, setLinkedListIsLoading] = useState(false)
  const [linkedRecords, setLinkedRecords] = useState<Array<{
    id: ReactNode | number,
    name: string | null,
    recordType: string,
    status: string,
    // eslint-disable-next-line camelcase
    iss_id: number | null,
    is_active: boolean | undefined,
    referral_enabled: boolean
  }>>([])

  const [recordType, setRecordType] = useState<string>()
  const [isNewRecord, setIsNewRecord] = useState<boolean>(false)
  const [showRightPanel, setShowRightPanel] = useState(false)
  const [currentRightPanelTab, setCurrentRightPanelTab] = useState<'linkedRecords'|'comments'>('linkedRecords')
  const isMobile = IsMobile()
  const scrollPosition = getScrollPosition()
  const rightPanelRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setRecordType(props.match.params.recordType)
  }, [])

  useEffect(() => {
    setIsNewRecord(false)
  }, [revisionData.id])

  const fetchRevisionDetails = async (
    revisionId: number | string): Promise<SPUDRecordWithData<SPUDSiteRecordDetails |
      SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>> => {
    const response = await props.recordDetail({ id: revisionId, recordType: props.match.params.recordType })
    return Object.assign(
      { },
      { data: response.data.update?.data || response.data.data || { name: '' } },
      response.data,
    )
  }

  const fetchLinkedRecords = async <
    T extends SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>(
    revisionDetail: SPUDRecord<T>): Promise<void> => {
    const linkedRecordMethod = fetchLinkedRecordTableMethod(props.match.params.recordType, revisionDetail)
    if (linkedRecordMethod?.method && linkedRecordMethod?.params) {
      setLinkedListIsLoading(true)
      const response = await linkedRecordMethod.method(linkedRecordMethod.params)
      setLinkedRecords(
        response.data.results.filter(item => item.id !== revisionDetail.id).map((res) => {
          const recordId = fetchCorrectIdToDisplay(res.id, res.iss_id)
          return {
            id: <Link to={`/records/${res.record_type}/record/${recordId}`}>{recordId}</Link>,
            name: res.update?.data?.name || res.name || 'Unknown record name',
            recordType: res.record_type,
            status: res.revision?.status || 'published',
            iss_id: res.iss_id,
            is_active: res.is_active,
            referral_enabled: res.referral_enabled,
            datasets: res.update?.data.datasets,
          }
        }),
      )
      setLinkedListIsLoading(false)
    }
  }

  const fetchRevision = async (revisionId: number | string) => {
    setIsLoading(true)
    const revisionDetail = await fetchRevisionDetails(revisionId)
    setRevisionData(revisionDetail)
    fetchLinkedRecords(revisionDetail)
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.match.params.revisionId) {
      if (props.match.params.recordType !== recordType) {
        setRecordType(props.match.params.recordType)
      }
      fetchRevision(props.match.params.revisionId)
    }
  }, [props.location.pathname, props.match.params.revisionId])

  const renderForm = (): React.ReactElement => (
    <AddEditRecord
      recordType={recordType}
      appendValue={generateAppendableValueObject(revisionData, recordType)}
      formData={revisionData}
      isNewRecord={isNewRecord}
      loadingForm={isLoading}
      recordId={recordType === props.match.params.recordType ? props.match.params.revisionId : null}
      action={props.match.params.action}
      linkedRecords={linkedRecords}
      onSaveCallback={(response, refetch) => {
        setIsNewRecord(false)
        if (props.match.params.recordType === recordType) {
          let recordId = fetchCorrectIdToDisplay(revisionData?.id, response?.revision.iss_id)
          if (response) {
            recordId = fetchCorrectIdToDisplay(
              response?.revision?.record || response?.revision.record_id || response?.id,
              response?.revision.iss_id,
            )
          }
          props.history.push(
            `/records/${props.match.params.recordType}/record/${
              recordId
            }`)
        } else {
          setRecordType(props.match.params.recordType)
          props.match.params.revisionId && fetchRevision(props.match.params.revisionId)
          fetchLinkedRecords(revisionData)
        }
        if (refetch) {
          props.match.params.revisionId && fetchRevision(props.match.params.revisionId)
        }
      }}
      onShowRightPanel={(show) => setShowRightPanel(show)}
      {...props}
    />
  )

  /**
   * Generates the Heading 1 of the record details page
   */
  const generateTitle = () => {
    if (props.match.params.revisionId || revisionData.id) {
      if (isLoading) {
        return <Skeleton/>
      } else {
        return revisionData.data?.name || 'Record Name'
      }
    } else {
      return formatAddEditTitle(props.match.params.recordType, props.match.params.action)
    }
  }

  const displayLinkedOrganisationOrSite = (
    title: string,
    linkedRecord: SPUDSiteRecordDetails | SPUDOrganisationRecordDetails,
    recordType: string,
  ): React.ReactElement | null => {
    if (!isLoading && linkedRecord && 'name' in linkedRecord) {
      return (
        <HeaderBarSection>
          <SimpleSPUDRecordCard
            recordType={recordType}
            title={
              <Link to={`/records/${recordType}/record/${
                fetchCorrectIdToDisplay(linkedRecord.id, linkedRecord.iss_id)
              }`}
              >
                {linkedRecord.name}
              </Link>
            }
            recordId={linkedRecord.id}
            issId={linkedRecord.iss_id}
            active={linkedRecord.is_active}
            color='#c7c7c7'
            referralEnabled={linkedRecord.referral_enabled}
          />

          <span style={{ margin: '0 2em' }}>
            <FontAwesomeIcon size='2x' color='#8c9bad' icon={faChevronRight as IconProp} />
          </span>
        </HeaderBarSection>
      )
    }
    return isLoading
      ? <HeaderBarSection>
        <Skeleton />
        <span style={{ margin: '0 2em' }}>
          <FontAwesomeIcon size='2x' color='#8c9bad' icon={faChevronRight as IconProp} />
        </span>
      </HeaderBarSection>
      : null
  }

  const shouldRenderPanel = (): boolean => {
    let renderPanel
    if (isMobile && showRightPanel) {
      renderPanel = true
    } else {
      renderPanel = !(isMobile && !showRightPanel)
    }
    return renderPanel
  }

  const disabled = !!(revisionData.id && !revisionData.is_active)

  const fetchRightPanelComponent = () => {
    switch (currentRightPanelTab) {
    case 'linkedRecords':
      return (
        <LinkedRecordsList
          loading={linkedListIsLoading || isLoading}
          title={fetchLinkedRecordTableTitle(props.match.params.recordType)}
          linkedRecords={linkedRecords}
          addNewComponent={recordType === 'site'
            ? (
              <Button
                disabled={linkedListIsLoading || disabled}
                active={!disabled && !linkedListIsLoading}
                onClick={() => {
                  setRecordType('service')
                  setIsNewRecord(true)
                  const siteData = {
                    ...revisionData.data as SPUDSiteRecordDetails,
                    id: revisionData.id,
                    iss_id: revisionData.iss_id,
                  }
                  setRevisionData({
                    ...revisionData,
                    iss_id: null,
                    record_type: '',
                    update: null,
                    id: null,
                    allocated_user: {
                      first_name: '',
                      last_name: '',
                      id: null,
                    },
                    revision: {
                      id: null,
                      status: '',
                      last_modified: null,
                    },
                    site: siteData,
                  })
                  props.history.push('/records/service/add')
                }}
              >
                Add Service
              </Button>
            )
            : null
          }
        />)
    case 'comments':
      return <SPUDComments recordId={props.match.params.revisionId} disabled={disabled} />
    }
  }

  return (
    <RecordDetailsPage>
      <Content>
        <Header style={{ margin: '0 1em 1em', padding: '0.5em 0', minHeight: 'auto' }}>
          <Row>
            <Col direction='row' justify='flex-start' flex={2}>
              <Button light style={{ alignSelf: 'center', marginLeft: '1em' }} onClick={() => {
                props.history.push(`/records/${props.match.params.recordType}`)
              }}
              >
                <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                {' '}
                {getPageType(props.match.params.recordType)} List
              </Button>
              <div style={{ display: 'flex', flexDirection: 'row' }}>

                {revisionData?.organisation && displayLinkedOrganisationOrSite(
                  'Organisation',
                  revisionData.organisation,
                  'organisation',
                )}

                {recordType === 'service' && revisionData?.site && displayLinkedOrganisationOrSite(
                  'Site',
                  revisionData.site,
                  'site',
                )}

                <HeaderBarSection>
                  <SimpleSPUDRecordCard
                    recordType={recordType}
                    title={generateTitle()}
                    recordId={revisionData.id}
                    status={revisionData.revision?.status || 'published'}
                    issId={revisionData.iss_id}
                    active={revisionData.is_active}
                    referralEnabled={revisionData.referral_enabled}
                  />
                </HeaderBarSection>
                {revisionData.date_started && !isNewRecord && <AdditionalInformation>
                  <div>
                    <h3 style={{ margin: 0, fontSize: 18 }}>Date created</h3>
                    <Text>{formatDatetimeToLocal(revisionData.date_started)}</Text>
                  </div>
                </AdditionalInformation>
                }
              </div>
            </Col>
            {recordType === 'site' &&
              <Col align='flex-end' justify='center'>
                <Row>
                  <Col align='flex-end' justify='center'>
                    <Title level={4} marginTop='0'>
                      Allocated to:
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col align='flex-end' justify='center'>
                    {revisionData.allocated_user
                      ? `${revisionData.allocated_user.first_name} ${revisionData.allocated_user.last_name}`
                      : 'Unallocated'
                    }
                  </Col>
                </Row>
              </Col>}
          </Row>
        </Header>
        <Section style={{ padding: '0 0 0 1em' }}>
          <Row style={{ height: '100%' }}>
            <RecordForm className={revisionData.is_active ? 'record-form' : 'deleted-record-form'}>
              {recordType && renderForm()}
            </RecordForm>
            {shouldRenderPanel() && <Col xxl={4} xl={6} sm={7} ref={rightPanelRef}>
              <RecordDetailRightPanelCard
                style={{
                  width: !(scrollPosition > 100) ? 'auto' : rightPanelRef?.current?.clientWidth || '100%',
                }}
                padding='25px 15px'
                marginTop='0'
                sticky={scrollPosition > 100}
              >
                <Row>
                  <Col direction='row'>
                    <Button
                      active={currentRightPanelTab === 'linkedRecords'}
                      onClick={() => setCurrentRightPanelTab('linkedRecords')}
                    >
                      Linked Records
                    </Button>
                    {props.match.params.revisionId && recordType === 'site' && (
                      <Button
                        active={currentRightPanelTab === 'comments'}
                        onClick={() => setCurrentRightPanelTab('comments')}
                      >
                        Comments
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col padding='0'>
                    {fetchRightPanelComponent()}
                  </Col>
                </Row>
              </RecordDetailRightPanelCard>
            </Col>}
          </Row>
        </Section>
      </Content>
    </RecordDetailsPage>
  )
}

export default withContext(RecordDetails)
