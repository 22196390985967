import React from 'react'

import type { Dispatch, SetStateAction } from 'react'
import type { DialogOptions } from '../../context/AppContext.type'
import toast from 'react-hot-toast'
import SPUDToastNotification from '../General/SPUDToastNotification'
import { spudAPI } from '../../services/spud.service'
import { SPUDOnSaveResponseDetails } from '../../../@types/SPUDRecord.type'
import type { History } from 'history'

export const copyRecordHandler = (
  recordId: number | string | null | undefined,
  recordType: string,
  orgId: number | undefined,
  spudCopyMethod: typeof spudAPI.copy,
  setDialogOptions: Dispatch<SetStateAction<DialogOptions>>,
  routeHistory: History,
) => (): void => {
  const onConfirm = (value: string | undefined) => {
    spudCopyMethod(recordId, +(value as string), recordType).then(data => {
      toast.custom(
        <SPUDToastNotification
          title="Success"
          message={
            <span>The {recordType} has been copied.</span>
          }
          success
        />,
        {
          position: 'bottom-center',
          duration: 4000,
        },
      )
      routeHistory.push(`/records/${recordType}/record/SP_${data.data?.id}`)
    }).catch(error => {
      let errorMessage = `An unexpected error occurred while copying the ${recordType}.`
      if (error.response) {
        // Get the first element of the first key in the error response.
        // There may be more than one error in multiple keys but we'll show them one
        // at a time.
        errorMessage = error.response.data[Object.keys(error.response.data)[0]][0]
      }
      toast.custom(
        <SPUDToastNotification
          title="Error"
          message={
            <span>{errorMessage}</span>
          }
          error
        />,
        {
          position: 'bottom-center',
        },
      )
    })
  }

  if (recordType === 'service') {
    setDialogOptions({
      onConfirm,
      title: 'Copy service',
      description: 'Enter the ISS ID of the site you wish to copy this service to:',
      show: true,
      type: 'input',
    })
  } else if (recordType === 'site') {
    onConfirm(orgId?.toString())
  }
}

export const deleteRecordHandler = (
  recordId: number | string | null | undefined,
  recordType: string,
  spudDeleteMethod: (
    recordId: number | string, recordType: string | undefined
  ) => Promise<{ data: SPUDOnSaveResponseDetails}>,
  setDialogOptions: Dispatch<SetStateAction<DialogOptions>>,
  actionCheck: () => void,
  onSaveCallback: (
    response: SPUDOnSaveResponseDetails | null,
    refetch?: boolean | undefined
  ) => void,
  eReferralEnabled: boolean,
) => (): void => {
  const onConfirm = () => {
    let confirmationMessage = `The ${recordType.toLowerCase()} has been marked for deletion.`
    if (recordType === 'site') {
      confirmationMessage += ' Services linked to this site have been queued to be deleted and will be removed shortly.'
    }

    if (recordId) {
      spudDeleteMethod(recordId, recordType).then(() => {
        actionCheck()
        onSaveCallback(null, true)
        toast.custom(
          <SPUDToastNotification
            title="Success"
            message={
              <span>{confirmationMessage}</span>
            }
            success
          />,
          {
            position: 'bottom-center',
          },
        )
      })
    }
  }

  let description = <div>Are you sure you want to delete this entry?</div>
  if (recordType === 'site') {
    if (eReferralEnabled) {
      description = <div>
        One or more services underneath are <strong>referral enabled </strong>
        preventing this site from being deleted
      </div>
    } else {
      description = <div>
        Are you sure you want to delete this entry and ALL services under it?
      </div>
    }
  } else if (recordType === 'service' && eReferralEnabled) {
    description = <div>
      This service is <strong>referral enabled</strong> and can&apos;t be deleted
    </div>
  }

  setDialogOptions({
    onConfirm,
    title: `Delete ${recordType}`,
    description: description,
    show: true,
    type: eReferralEnabled ? 'acknowledge' : 'confirm',
  })
}

export const restoreRecordHandler = (
  recordId: number,
  recordType: string,
  spudRestoreMethod: (
    recordId: number,
    recordType: string | undefined,
  ) => Promise<{ data: SPUDOnSaveResponseDetails}>,
  setDialogOptions: Dispatch<SetStateAction<DialogOptions>>,
  actionCheck: () => void,
  onSaveCallback: (
    response: SPUDOnSaveResponseDetails | null,
    refetch?: boolean | undefined
  ) => void,
) => (): void => {
  const onConfirm = () => {
    const confirmationMessage = `The ${recordType.toLowerCase()} has been marked for restoration.`

    spudRestoreMethod(recordId, recordType).then(() => {
      actionCheck()
      onSaveCallback(null, true)
      toast.custom(
        <SPUDToastNotification
          title="Success"
          message={
            <span>{confirmationMessage}</span>
          }
          success
        />,
        {
          position: 'bottom-center',
        },
      )
    })
  }

  let description = <span>Are you sure you want to restore this entry?</span>

  if (recordType === 'site') {
    description = <>
      <span>{description}</span>
      <br/>
      <strong>All services will need to be restored individually</strong>
    </>
  }

  setDialogOptions({
    onConfirm,
    title: `Restore ${recordType}`,
    description,
    show: true,
    type: 'confirm',
  })
}
