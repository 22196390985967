import React, { useEffect, useRef, useState } from 'react'
import pluralize from 'pluralize'
import {
  Row,
  Col,
  Checkbox,
  Title,
  Text,
  Spinner,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SendMessage } from 'react-use-websocket'
import { useDropzone } from 'react-dropzone'
import { loadCsvHeaders } from '../ListView.service'
import { reportDuplicates, preProcessInstructions } from './ImportRecord.service'
import Papa from 'papaparse'
import DuplicateRows, { PostProcessRowType } from './DuplicateRows'
import { DuplicateType, uploadCSV } from '../../../../services/spud.service'
import CsvDownload from 'react-json-to-csv'
import { format } from 'date-fns'

const getDropzoneColour = ({ isDragAccept, isDragReject, isFocused }: {
  isDragAccept: boolean,
  isDragReject: boolean,
  isFocused: boolean
}) => {
  if (isDragAccept) {
    return '#00e676'
  }
  if (isDragReject) {
    return '#ff1744'
  }
  if (isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

type ImportRecordType = {
  recordType: string
  dismissPopup: () => void,
  sendMessage: SendMessage,
}

const DropZoneContainer = styled.div<
  {
    isDragAccept: boolean,
    isDragReject: boolean,
    isFocused: boolean,
  }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getDropzoneColour(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`

const ImportCard = styled.div<{ uploading: boolean }>`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  min-width: ${props => props.uploading ? '20%' : '40%'};
  min-height: 20%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const ImportHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ImportTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
`

const ImportCloseButtonContainer = styled.div`
  padding: 5px;
`
const ImportCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%;
  }
`

const ImportContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
`

const ImportContent = styled.div<{ uploading?: boolean }>`
  height: 100%;
  width: 100%;
  max-height: 50vh;
  overflow-y: ${props => props.uploading ? 'none' : 'scroll'};
`

const ImportControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #c3c3c3;
  padding: 1em;
`

const ImportControlButton = styled.button<{disabled: boolean}>`
  font-weight: bold;
  border: none;
  background-color: ${props => props.disabled ? props.theme.colors.grayDark : props.theme.colors.active};
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  cursor: ${props => !props.disabled && 'pointer'};
  ${props => !props.disabled && '&:hover {opacity: 0.8;}'}
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

const ImportConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const HeaderContainer = styled.div`
  padding: 1em 0;
`

const HeaderGridContainer = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(1em, auto);
  padding: 1em;
`

const LinkStyleButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`

const ExportCSVButton = styled(CsvDownload)`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`

const ExceedDupeLimitContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2em;
  flex-direction: column;
  font-size: larger;
  background-color: ${props => props.theme.colors.accentColor};
`

const DUPLICATE_LIMIT = 20

function ImportRecords (
  {
    recordType,
    dismissPopup,
    sendMessage,
  }: ImportRecordType) {
  const [csvFile, setCsvFile] = useState<File>()
  const [csvHeaders, setCsvHeaders] = useState<Array<string>>([])
  const [selectedHeaders, setSelectedHeaders] = useState<{ [x: string]: boolean }>({})
  const [uploading, setUploading] = useState(false)
  const [csvFileUploadError, setCsvFileUploadError] = useState(false)
  const [recordTypePresets, setRecordTypePresets] = useState<{[x: string]: boolean }>({})
  const [duplicates, setDuplicates] = useState<DuplicateType>({})
  const [duplicateReport, setDuplicateReportList] = useState<Array<{[x: string]: unknown}>>([])
  const [hasDuplicates, setHasDuplicates] = useState(false)
  const [duplicateLimitReached, setDuplicateLimitReached] = useState(false)
  const [duplicateOverrides, setDuplicateOverrides] = useState<Array<PostProcessRowType>>([])
  const [duplicateRowOverrides, setDuplicateRowOverrides] = useState<Array<number>>([])
  const [totalDuplicates, setTotalDuplicates] = useState<number>(0)
  const [totalRows, setTotalRows] = useState<number>(0)
  const importRecordCardRef = useRef<HTMLDivElement>(null)

  const importDisabled = !csvFile || (hasDuplicates && duplicateLimitReached)

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
    },
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    errors.map(e => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <li key={(file as any).path + '.' + e.code}>{(file as any).path} - {file.size} bytes - {e.message}</li>
    ))
  ))

  useEffect(() => {
    if (acceptedFiles.length) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const binaryStr = fileReader.result as string
        if (binaryStr) {
          let validFile = true
          const csvColumnHeadings = binaryStr.slice(0, binaryStr.indexOf('\n')).split(',')
          const csvFileHeaders = loadCsvHeaders(csvColumnHeadings)
          let presetHeaders = {}
          if (csvFileHeaders.includes('spud_id') && csvFileHeaders.includes('iss_id')) {
            setCsvHeaders(csvFileHeaders)
            presetHeaders = {
              spud_id: true,
              iss_id: true,
            }
          } else {
            validFile = false
            setCsvFileUploadError(true)
          }
          if (presetHeaders && validFile) {
            if (recordType === 'service') {
              presetHeaders = {
                ...presetHeaders,
                site: true,
              }
            }
            setSelectedHeaders(presetHeaders)
            setRecordTypePresets(presetHeaders)
          }
        }
      }
      fileReader.readAsText(acceptedFiles[0])
      setCsvFile(acceptedFiles[0])
    }
  }, [acceptedFiles.length])

  const importRecords = async () => {
    if (csvFile) {
      sendMessage(JSON.stringify({
        type: 'import_record',
        message: 'import',
      }))
      try {
        await uploadCSV(csvFile, recordType, Object.keys(selectedHeaders), duplicateRowOverrides)
        setUploading(false)
        dismissPopup()
      } catch (e) {
        setUploading(false)
      }
    }
  }

  const validateCsv = async () => {
    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setTotalRows(results.data.length)
          const duplicateRows = reportDuplicates(results, recordType)
          if (Object.keys(duplicateRows.duplicateGroups).length) {
            setDuplicates(duplicateRows.duplicateGroups)
            setDuplicateReportList(duplicateRows.duplicateReport)
            setTotalDuplicates(duplicateRows.numberOfDuplicateRows)
            setDuplicateLimitReached(duplicateRows.duplicateReport.length > DUPLICATE_LIMIT)
            setHasDuplicates(true)
          } else {
            setUploading(true)
            importRecords()
          }
        },
      })
    }
  }

  const CsvDuplicateReport = (props: {label: string}) => {
    const csvFileName = `${csvFile?.name.replace('.csv', '')}-duplicates-${format(new Date(), 'd/M/yyyy')}.csv`
    return (
      <ExportCSVButton
        data={duplicateReport}
        aria-label={props.label}
        filename={csvFileName}
      >
        Export duplicate report csv
      </ExportCSVButton>
    )
  }

  return <ImportCard aria-label='import-records' ref={importRecordCardRef} uploading={uploading}>
    {!uploading && <ImportHeader>
      <ImportTitle>
        <div>
          import {pluralize(recordType)}
        </div>
      </ImportTitle>
      <ImportCloseButtonContainer>
        <ImportCloseButton
          title='Close popup'
          onClick={() => {
            dismissPopup()
          }}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </ImportCloseButton>
      </ImportCloseButtonContainer>
    </ImportHeader>}
    <ImportContentContainer>
      {!hasDuplicates && !uploading && <ImportContent uploading={uploading}>
        {!csvFile && <DropZoneContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Click/Drag to upload csv</p>
        </DropZoneContainer>}
        {fileRejectionItems &&
          <Row>
            <Col><Text color='red'><ul>{fileRejectionItems}</ul></Text></Col>
          </Row>
        }
        {csvFile &&
          <Row>
            <Col>
              <strong>File loaded: </strong> {csvFile.name}
            </Col>
            <Col direction='row' justify='flex-end'>
              <LinkStyleButton
                onClick={() => {
                  setCsvFile(undefined)
                  setSelectedHeaders({})
                  setRecordTypePresets({})
                  setCsvFileUploadError(false)
                  acceptedFiles.length = 0
                  acceptedFiles.splice(0, acceptedFiles.length)
                }}
              >
                Clear file
              </LinkStyleButton>
            </Col>
          </Row>}
        {csvFile &&
          <HeaderContainer>
            {!csvFileUploadError && <Row>
              <Col>
                <strong>
                  Csv headers
                  <LinkStyleButton
                    onClick={() => {
                      let allHeaders = {}
                      csvHeaders.forEach((header) => {
                        allHeaders = {
                          ...allHeaders,
                          [header]: true,
                        }
                      })
                      setSelectedHeaders(allHeaders)
                    }}
                  >
                    Select all headers
                  </LinkStyleButton>
                </strong>
              </Col>
              <Col direction='row' justify='flex-end'>
                <LinkStyleButton
                  onClick={() => {
                    let defaultHeaders: { [x: string]: boolean } = {
                      spud_id: true,
                      iss_id: true,
                    }
                    if (recordType === 'service') {
                      defaultHeaders = {
                        ...defaultHeaders,
                        site: true,
                      }
                    }
                    setRecordTypePresets(defaultHeaders)
                    setSelectedHeaders(defaultHeaders)
                  }}
                >
                  Clear all headers
                </LinkStyleButton>
              </Col>
            </Row>}
            <Row>
              <Col>
                {!csvFileUploadError && <HeaderGridContainer>
                  {csvHeaders.map(header =>
                    <div key={header}>
                      <Checkbox
                        label={header}
                        id={header}
                        name={header}
                        disabled={recordTypePresets[header]}
                        checked={selectedHeaders?.[header] || false}
                        onChange={(checked: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedHeadersCopy = selectedHeaders
                          if (!recordTypePresets?.[header]) {
                            if (!checked.target.checked) {
                              delete selectedHeadersCopy[header]
                              setSelectedHeaders({ ...selectedHeadersCopy })
                            } else {
                              setSelectedHeaders({
                                ...selectedHeaders,
                                [header]: checked.target.checked,
                              })
                            }
                          }
                        }}
                      />
                    </div>,
                  )}
                </HeaderGridContainer>}
                {csvFileUploadError && <Row>
                  <Col align='center'>
                    <Title level={4}>Invalid CSV - Clear file and try again</Title>
                    <p>
                      <strong>Reason: </strong>
                      Missing <strong>spud_id</strong>
                      {' '}and <strong>iss_id</strong> columns
                    </p>
                  </Col>
                </Row>}
              </Col>
            </Row>
            <Row>
              <Col>
                <ExceedDupeLimitContainer>
                  {preProcessInstructions(recordType)}
                </ExceedDupeLimitContainer>
              </Col>
            </Row>
          </HeaderContainer>
        }
      </ImportContent>}
      {hasDuplicates && <ImportContent uploading={uploading}>
        <Row>
          <Col>
            <Row>
              <Col>
                <Title level={3} marginTop='0'>
                  Duplicate rows found in file {totalDuplicates}/{totalRows}
                </Title>
              </Col>
              <Col align='end'>
                <CsvDuplicateReport label='primary' />
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>
                  Select duplicate rows and hit import to force an import.
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {Object.entries(duplicates).map(([duplicateType, duplicateGroup], index) => {
              if ((index + 1) <= DUPLICATE_LIMIT) {
                return (
                  <DuplicateRows
                    key={duplicateType}
                    duplicateType={duplicateType}
                    duplicateGroup={duplicateGroup}
                    recordType={recordType}
                    duplicateOverrides={duplicateOverrides}
                    duplicateRowOverrides={duplicateRowOverrides}
                    setDuplicateOverrides={setDuplicateOverrides}
                    setDuplicateRowOverrides={setDuplicateRowOverrides}
                    duplicateCheckType='pre'
                  />
                )
              }
              return null
            })}
            {duplicateLimitReached &&
              <ExceedDupeLimitContainer>
                Duplicate limit reached, please correct csv duplicate errors and try again
                <CsvDuplicateReport label='warning' />
              </ExceedDupeLimitContainer>}
          </Col>
        </Row>
      </ImportContent>}
      {uploading && <ImportContent uploading={uploading}>
        <Row>
          <Col>
            <Row>
              <Col>
                <Spinner type='circleSpinner' backgroundColor='#fff'/>
              </Col>
            </Row>
            <Row>
              <Col align='center'>
                <Title level={4}>
                  Uploading {pluralize(recordType, totalRows, true)}
                </Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </ImportContent>}
    </ImportContentContainer>
    {!uploading && <ImportControlBar>
      <ImportConfirmButtonGroup>
        {hasDuplicates &&
          <ImportControlButton
            disabled={false}
            onClick={() => {
              setHasDuplicates(false)
              setDuplicates({})
              setDuplicateOverrides([])
            }}
          >
            Go Back
          </ImportControlButton>}
        <ImportControlButton
          disabled={importDisabled}
          onClick={() => {
            if (hasDuplicates) {
              setUploading(true)
              setHasDuplicates(false)
              importRecords()
              setDuplicates({})
              setDuplicateOverrides([])
            } else {
              validateCsv()
            }
          }}
        >
          {hasDuplicates ? 'Import records' : 'Validate/Import records'}
        </ImportControlButton>
      </ImportConfirmButtonGroup>
    </ImportControlBar>}
  </ImportCard>
}

export default ImportRecords
