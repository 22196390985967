import React, { useEffect, useMemo, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Title,
  Alert,
  Text,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faLevelUpAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useFieldArray, useFormContext } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { format, isDate, parse } from 'date-fns'
import _ from 'lodash'
import { SPUDInputField } from '../../../helpers/record'
import EditOpeningHoursPopup from './EditOpeningHoursPopup'
import {
  checkForExistingOrOverlappingTimes,
  DAYS_OF_THE_WEEK,
  parseDateValue,
  checkCloseIsAfterOpen,
  scrollToAndHighlightTypedTime,
  getHighlightedTime,
  convert24hrTo12hr, handleMonthContainer,
} from './OpeningHours.service'
import { SPUDSiteRecordDetails } from '../../../../@types/Site.type'

export type OpenHoursType = {
  id?: string,
  day: string,
  open: string,
  close: string,
  note: string,
}

export type SPUDOpeningHoursProps = {
  title: string,
  recordType: string,
  siteValues: SPUDSiteRecordDetails,
  disabled: boolean,
}

const DaysOfTheWeekButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const DayOfTheWeekButton = styled(Button)`
  margin: 5px;
  width: 100%;
`
const CustomTimePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background: none;
  border: 1px solid #3a8ae8;
`

const OpeningHoursAddNewWidget = styled.div`
  background-color: #F3F3F3;
  border-radius: 3px;
  padding: 10px;
`

const OpeningHoursSummary = styled.div.attrs({
  className: 'form-opening-hours',
})`
  background-color: #F3F3F3;
  border-radius: 3px;
  padding: 10px;
  min-height: 200px;
`
const OpeningHoursSummaryChip = styled.div<{ clicked: boolean, readonly?: boolean}>`
  border-radius: 3px;
  border: ${props => props.clicked ? '2px' : '1px'} solid #3a8ae8;
  padding: 5px;
  color: #4c1811;
  font-size: 14px;
  margin: 0.3em;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
  width: 100%;
  position: relative;
  :hover {
    cursor: ${props => !props.readonly ? 'pointer' : 'inherit'};
  }
`

const OpeningHoursSummaryChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const OpeningHoursSummaryChipDay = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin: 0 10px;
`

const OpeningHoursSummaryChipTime = styled.span`
  font-size: 16px;
  margin-right: 10px;
`

const OpeningHoursSummaryContent = styled.div`
  display: flex;
  align-items: center;
`

const AngledArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
`

const OpeningHoursLinkButton = styled(Button)`
  margin: 7px 5px;
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  :hover {
    text-decoration: none;
    border: none;
    background: none;
  }
  :focus {
    box-shadow: none;
  }
`

function SPUDOpeningHours ({ title, siteValues, disabled, recordType }: SPUDOpeningHoursProps): React.ReactElement {
  const [daysSelected, setDaysSelected] = useState<{[x: string]: boolean}>({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    'Public Holiday': false,
  })
  const [showPopper, setShowPopper] = useState('')
  const [globalComment, setGlobalComment] = useState('')
  const [addNewTimes, setAddNewTimes] = useState<{ open: string | number, close: string | number }>({
    open: '',
    close: '',
  })
  const [invalidDateConfig, setInvalidDateConfig] = useState(false)
  const [siteOpeningHours, setSiteOpeningHours] = useState<Array<OpenHoursType>>([])
  const [tempOpeningHours, setTempOpeningHours] = useState([])

  useEffect(() => {
    siteValues?.opening_hours && setSiteOpeningHours(siteValues.opening_hours)
  }, [siteValues])

  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'opening_hours',
  })

  const clearDays = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Public Holiday']
    const newDaysSelected = { ...daysSelected }
    for (const day of days) {
      newDaysSelected[day] = false
    }
    setDaysSelected(newDaysSelected)
  }

  const loadDayPreset = (preset: 'mon-fri'|'seven-days'|'weekend-public-holidays') => {
    let days: Array<string> = []
    switch (preset) {
    case 'mon-fri':
      days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
      break
    case 'seven-days':
      days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Public Holiday']
      break
    case 'weekend-public-holidays':
      days = ['Sunday', 'Saturday', 'Public Holiday']
    }

    // Resetting the days so then when a preset is selected only those days are selected
    const newDaysSelected: { [x: string]: boolean } = {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      'Public Holiday': false,
    }
    for (const day of days) {
      newDaysSelected[day] = true
    }
    setDaysSelected(newDaysSelected)
  }

  const loadTimePreset = (preset: '24hr'|'9-5'): void => {
    let openClose = { open: '', close: '' }
    switch (preset) {
    case '24hr':
      openClose = {
        open: format(new Date().setHours(0, 0, 0, 0), 'HH:mm'),
        close: format(new Date().setHours(0, 0, 0, 0), 'HH:mm'),
      }
      break
    case '9-5':
      openClose = {
        open: format(new Date().setHours(9, 0, 0, 0), 'HH:mm'),
        close: format(new Date().setHours(17, 0, 0, 0), 'HH:mm'),
      }
      break
    }
    let timeSlotAlreadyExists = false
    for (const day of Object.keys(daysSelected)) {
      if (daysSelected[day]) {
        timeSlotAlreadyExists = checkForExistingOrOverlappingTimes(
          {
            open: parse(openClose.open, 'HH:mm', new Date()),
            close: parse(openClose.close, 'HH:mm', new Date()),
          }, day, getValues('opening_hours'))
      }
    }
    setAddNewTimes({ ...addNewTimes, ...openClose })
    setInvalidDateConfig(timeSlotAlreadyExists)
  }

  // Ignoring because even the shape of field is known the type can't be set
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupDays = (): any => {
    return _.groupBy(fields, 'day')
  }

  const renderNote = (day: string, site?: boolean) => {
    let hours
    if (site) {
      hours = _.groupBy(siteOpeningHours, 'day')?.[day]
    } else {
      hours = groupDays()?.[day]
    }

    // ensure that the most recent note is displayed
    const dayNotes = hours.reduce(
      (previousValue: OpenHoursType, currentValue: OpenHoursType) =>
        previousValue?.note ? currentValue.note || previousValue.note : currentValue.note,
    )

    if (typeof dayNotes?.note === 'string') {
      return `${dayNotes.note && '|'} ${dayNotes.note}`
    }
    return dayNotes?.note ? `${dayNotes.note && '|'} ${dayNotes.note}` : `${dayNotes && '|'}  ${dayNotes}`
  }

  const getIndexes = (day: string): number[] => {
    return fields.reduce((accumulator: number[], field, index) => {
      // Ignoring because even the shape of field is known the type can't be set
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (field.day === day) {
        accumulator.push(index)
      }
      return accumulator
    }, [])
  }

  const daysSet = useMemo(
    () => {
      let areDaysSet = false
      for (const day of Object.keys(daysSelected)) {
        if (daysSelected[day]) {
          areDaysSet = true
          break
        }
      }
      return areDaysSet
    },
    [daysSelected])

  const updateOpeningsHoursWithGlobalComment = (day: string) => {
    const indexes = getIndexes(day)
    for (const index of indexes) {
      if (globalComment) {
        setValue(`opening_hours.${index}.note`, globalComment)
      }
    }
  }

  const validateTimeConfig = (days: { [x: string]: boolean }, time?: Date, isOpen?: boolean): boolean => {
    let timeSlotAlreadyExists = false
    for (const day of Object.keys(days)) {
      if (!timeSlotAlreadyExists && days[day]) {
        if (time) {
          if (isOpen && addNewTimes.close) {
            timeSlotAlreadyExists = checkForExistingOrOverlappingTimes(
              {
                close: parseDateValue(addNewTimes.close),
                open: time,
              },
              day, getValues('opening_hours'))
          } else if (!isOpen && addNewTimes.open) {
            timeSlotAlreadyExists = checkForExistingOrOverlappingTimes(
              {
                close: time,
                open: parseDateValue(addNewTimes.open),
              },
              day, getValues('opening_hours'))
          }
        } else {
          timeSlotAlreadyExists = checkForExistingOrOverlappingTimes(
            {
              close: parseDateValue(addNewTimes.close),
              open: parseDateValue(addNewTimes.open),
            },
            day, getValues('opening_hours'))
        }
      }
    }
    return timeSlotAlreadyExists
  }

  const commentAlreadyExists = useMemo(
    () => {
      const daysWithNotes = []
      for (const day of Object.keys(daysSelected)) {
        if (daysSelected[day]) {
          // Ignoring because even the shape of field is known the type can't be set
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const field = fields.find(field => field.day === day && field.note)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          field && daysWithNotes.push(field.day)
        }
      }
      return daysWithNotes
    },
    [daysSelected])

  const timeList = document.getElementsByClassName('react-datepicker__time-list')

  return (
    <div>
      <Row>
        <Col direction='row' align='baseline'>
          <Title level={4}>{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <OpeningHoursAddNewWidget>
            <Row>
              <Col direction='row' align='center' justify='space-between'>
                <Title level={5} marginTop='0px'>Day(s) Selection Shortcut</Title>
                <OpeningHoursLinkButton onClick={() => {
                  clearDays()
                  setAddNewTimes({
                    open: '',
                    close: '',
                  })
                  setGlobalComment('')
                  setInvalidDateConfig(false)
                }}
                >
                  Clear
                </OpeningHoursLinkButton>
              </Col>
            </Row>
            <Row>
              <Col direction='row' align='baseline'>
                <div>
                  <Button
                    onClick={() => loadDayPreset('mon-fri') }
                    title='This will select the days Monday to Friday'
                  >
                    Mon - Fri
                  </Button>
                  <Button
                    onClick={() => loadDayPreset('seven-days') }
                    title='This will select the days Monday to Friday including the weekends'
                  >
                    Seven Days
                  </Button>
                  <Button
                    onClick={() => loadDayPreset('weekend-public-holidays') }
                    title='This will select the weekends and public holidays'
                  >
                    Sat, Sun, Public Holidays
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <DaysOfTheWeekButtonContainer>
                  <div style={{ width: '100%' }}>
                    <Title level={4}>Select Day(s)</Title>
                  </div>
                  {DAYS_OF_THE_WEEK.map((day) => (
                    <DayOfTheWeekButton
                      key={day.name}
                      active={daysSelected?.[day.name]}
                      onClick={() => {
                        const newDaysSelected = { ...daysSelected, [day.name]: !daysSelected[day.name] }
                        setDaysSelected(newDaysSelected)
                        setInvalidDateConfig(validateTimeConfig(newDaysSelected))
                      }}
                    >
                      {day.name}
                    </DayOfTheWeekButton>
                  ))}
                </DaysOfTheWeekButtonContainer>
              </Col>
            </Row>
            <Row>
              <Col direction='row'>
                <div style={{ marginRight: 10 }}>
                  <Title level={4}>Select Times</Title>
                </div>
                <Button
                  primary
                  onClick={() => loadTimePreset('24hr')}
                  title="This will prefill the open and close with 12:00 am to 12:00 am"
                >
                  24hrs
                </Button>
                <Button
                  primary
                  onClick={() => loadTimePreset('9-5')}
                  title="This will prefill the open and close with 9:00 am to 5:00 pm"
                >
                  9 - 5
                </Button>
                <Row style={{ width: 'auto' }}>
                  <Col direction='row' align='center'>
                    <CustomTimePicker
                      selected={parseDateValue(addNewTimes.open)}
                      showTimeSelect
                      timeCaption="Time"
                      dateFormat="hh:mm aaa"
                      id='add-widget-open-time'
                      isClearable={true}
                      onCalendarOpen={handleMonthContainer}
                      onChange={(time: Date) => {
                        const timeToUse: Date = getHighlightedTime(time, timeList)
                        const isValid = checkCloseIsAfterOpen(timeToUse, addNewTimes, true)
                        setInvalidDateConfig(validateTimeConfig(daysSelected, timeToUse, true) || !isValid)
                        if (isDate(timeToUse)) {
                          setAddNewTimes({ ...addNewTimes, open: format(timeToUse, 'HH:mm') })
                        } else {
                          setAddNewTimes({ ...addNewTimes, open: '' })
                          setInvalidDateConfig(false)
                        }
                      }}
                      onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
                        scrollToAndHighlightTypedTime(event.target.value, timeList)
                      }}
                      popperModifiers={[
                        {
                          name: 'offset',
                          options: {
                            offset: [80, 0],
                          },
                        },
                      ]}
                      disabled={disabled}
                    />
                    <div style={{ margin: '0 10px' }}>
                      <FontAwesomeIcon icon={faArrowRight as IconProp} />
                    </div>
                    <CustomTimePicker
                      selected={parseDateValue(addNewTimes.close)}
                      showTimeSelect
                      timeCaption="Time"
                      dateFormat="hh:mm aaa"
                      isClearable={true}
                      onCalendarOpen={handleMonthContainer}
                      id='add-widget-close-time'
                      onChange={(time: Date) => {
                        const timeToUse: Date = getHighlightedTime(time, timeList)
                        const isValid = checkCloseIsAfterOpen(timeToUse, addNewTimes, false)
                        setInvalidDateConfig(validateTimeConfig(daysSelected, timeToUse, false) || !isValid)
                        if (isDate(timeToUse)) {
                          setAddNewTimes({ ...addNewTimes, close: format(timeToUse, 'HH:mm') })
                        } else {
                          setAddNewTimes({ ...addNewTimes, close: '' })
                        }
                      }}
                      onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
                        scrollToAndHighlightTypedTime(event.target.value, timeList)
                      }}
                      popperModifiers={[
                        {
                          name: 'offset',
                          options: {
                            offset: [80, 0],
                          },
                        },
                      ]}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                {invalidDateConfig && <Alert type='error' style={{ marginTop: 0 }}>
                  Open/close times are invalid. Check that the times don&apos;t overlap or close is before open
                </Alert>}
              </Col>
            </Row>
            <Row>
              <Col>
                <SPUDInputField
                  label={<Title level={4} marginTop='5px'>Comment <Text>
                    (if applicable for all selected days)
                  </Text></Title>}
                  value={globalComment}
                  name='opening_hours_global_comment'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGlobalComment(event.target.value)}
                  fullwidth
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {commentAlreadyExists.length > 0 && <Alert type='error'>There’s already a comment added to {
                  commentAlreadyExists.map(day => `${day} `)
                } entering global comment will override anything existing</Alert>}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  title='Add opening hours'
                  active={addNewTimes.open && addNewTimes.close && daysSet && !invalidDateConfig}
                  disabled={invalidDateConfig || !daysSet}
                  style={{ width: '100%' }}
                  onClick={() => {
                    const selectedDays = Object.keys(daysSelected)
                    for (const selectedDay of selectedDays as Array<string>) {
                      if (daysSelected[selectedDay]) {
                        const indexes = getIndexes(selectedDay)
                        const note: Array<string> = []
                        indexes.forEach(index => {
                          note.push(getValues(`opening_hours.${index}.note`))
                        })
                        append({
                          day: selectedDay,
                          open: addNewTimes.open,
                          close: addNewTimes.close,
                          note: globalComment || note?.[0] || '',
                        })
                        updateOpeningsHoursWithGlobalComment(selectedDay)
                      }
                    }
                    clearDays()
                    setAddNewTimes({ open: '', close: '' })
                    setGlobalComment('')
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </OpeningHoursAddNewWidget>
        </Col>
      </Row>
      {siteOpeningHours.length > 0 && recordType === 'service' && <Row>
        <Row>
          <Col><Title level={4}>Site opening hours summary</Title></Col>
        </Row>
        <Row>
          <Col>
            <div>
              {DAYS_OF_THE_WEEK.map((dayOfTheWeek, index) =>
                _.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name]?.length &&
                  <OpeningHoursSummaryContent key={`site_${dayOfTheWeek.name}_${index}`}>
                    <OpeningHoursSummaryChip clicked={false} readonly={true}>
                      <div style={{ display: 'flex' }}>
                        <OpeningHoursSummaryChipDay>
                          {_.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name].length && dayOfTheWeek.name} |
                        </OpeningHoursSummaryChipDay>
                        {_.groupBy(siteOpeningHours, 'day')?.[dayOfTheWeek.name].map((siteTime, index) =>
                          <OpeningHoursSummaryChipTime key={`siteTime_${dayOfTheWeek.name}_${index}`}>
                            {convert24hrTo12hr(siteTime.open)} - {convert24hrTo12hr(siteTime.close)}
                          </OpeningHoursSummaryChipTime>,
                        )}
                        {renderNote(dayOfTheWeek.name, true)}
                      </div>
                    </OpeningHoursSummaryChip>
                  </OpeningHoursSummaryContent>,
              )}
            </div>
          </Col>
        </Row>
      </Row>}
      <Row>
        <Col direction='row' justify='space-between' align='center'>
          <Title level={4}>Opening Hours Summary</Title>
          <OpeningHoursLinkButton onClick={() => {
            if (!tempOpeningHours.length) {
              setTempOpeningHours(getValues('opening_hours'))
              setValue('opening_hours', [])
            } else {
              setValue('opening_hours', tempOpeningHours)
              setTempOpeningHours([])
            }
          }}
          >
            {tempOpeningHours.length ? 'Undo' : 'Clear all'}
          </OpeningHoursLinkButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <OpeningHoursSummary aria-label='Opening hours summary'>
            {DAYS_OF_THE_WEEK.map((dayOfTheWeek, index) => (
              groupDays()?.[dayOfTheWeek.name]?.length &&
              <OpeningHoursSummaryContent
                key={`${dayOfTheWeek.name}_${index}`}
              >
                <AngledArrowWrapper>
                  <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
                </AngledArrowWrapper>
                <OpeningHoursSummaryChip
                  clicked={showPopper === dayOfTheWeek.name}
                  onClick={() => setShowPopper(dayOfTheWeek.name)}
                >
                  <div style={{ display: 'flex' }}>
                    <div>
                      <OpeningHoursSummaryChipDay>
                        {groupDays()?.[dayOfTheWeek.name]?.length ? dayOfTheWeek.name : ''} |
                      </OpeningHoursSummaryChipDay>
                      {getIndexes(dayOfTheWeek.name).map((index: number) => (
                        <OpeningHoursSummaryChipTime key={`${dayOfTheWeek.name}_${index}`}>
                          {convert24hrTo12hr(getValues(`opening_hours.${index}.open`))} - {
                            convert24hrTo12hr(getValues(`opening_hours.${index}.close`))}
                          {getIndexes(dayOfTheWeek.name).length > 1 && '; '}
                        </OpeningHoursSummaryChipTime>
                      ))}
                      <span>{renderNote(dayOfTheWeek.name)}</span>
                    </div>
                  </div>
                  <OpeningHoursSummaryChipButton
                    disabled={disabled}
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault()
                      remove(getIndexes(dayOfTheWeek.name))
                      setShowPopper('')
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                  </OpeningHoursSummaryChipButton>
                  {showPopper === dayOfTheWeek.name &&
                    <EditOpeningHoursPopup
                      indexes={getIndexes(dayOfTheWeek.name)}
                      day={dayOfTheWeek.name}
                      append={append}
                      update={update}
                      remove={remove}
                      onClose={() => {
                        setShowPopper('')
                      }}
                      disabled={disabled}
                    />}
                </OpeningHoursSummaryChip>
              </OpeningHoursSummaryContent>
            ))}
          </OpeningHoursSummary>
        </Col>
      </Row>
    </div>
  )
}

export default SPUDOpeningHours
