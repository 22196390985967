import locationApi from '../helpers/LocationAPI'
import keycloak from '../../keycloak'
import { PlaceResult } from './googleAPI.service'

const STREET_SUFFIX = ['Central', 'East', 'North', 'South', 'West']

export const searchLocation = async (
  searchTerm: string,
  offset: number,
  state?: string,
  locationTypes?: Array<string>,
): Promise<{
  next: string,
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string,
    state: string,
    // eslint-disable-next-line camelcase
    location_type: string,
  }>
}> => {
  let searchURL = `search/all/suggest/?search=${searchTerm}&offset=${offset}`
  if (state) {
    searchURL += `&state=${state}&limit=10`
  }
  if (locationTypes) {
    for (const locationType of locationTypes) {
      searchURL += `&location_type=${locationType}`
    }
  }
  const response = await locationApi.get(searchURL, {
    headers: {
      Authorization: `Keycloak ${keycloak.token}`,
    },
  })
  return response.data
}

export const SearchLGAs = async (state: string, page: number): Promise<{
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode?: string
  }>
}> => {
  if (page === 1) {
    page = 0
  } else if (page > 1) {
    page -= 1
  }
  const response = await locationApi.get(
    `search/lgas/suggest/?state=${state}&limit=10&offset=${page * 10}`,
    {
      headers: {
        Authorization: `Keycloak ${keycloak.token}`,
      },
    })
  return response.data
}

export const SearchSuburbs = async (state: string, lga: string, page: number): Promise<{
  count: number,
  results: Array<{
    id: string,
    name: string,
    postcode: string
  }>
}> => {
  if (page === 1) {
    page = 0
  } else if (page > 1) {
    page -= 1
  }
  const response = await locationApi.get(
    `search/suburbs/suggest/?state=${state}&lga=${lga}&limit=10&offset=${page * 10}`,
    {
      headers: {
        Authorization: `Keycloak ${keycloak.token}`,
      },
    })
  return response.data
}

/**
 * converts the Address components that forms the Google Place details into the location structure
 *
 * An example of the address components
 * {
 *     address_components: [
 *       {
 *         long_name: '6',
 *         short_name: '6',
 *         types: [
 *           'street_number',
 *         ],
 *       },
 *       {
 *         long_name: 'Warrego Street',
 *         short_name: 'Warrego St',
 *         types: [
 *           'route',
 *         ],
 *       },
 *       {
 *         long_name: 'Parramatta Park',
 *         short_name: 'Parramatta Park',
 *         types: [
 *           'locality',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Cairns Regional',
 *         short_name: 'Cairns',
 *         types: [
 *           'administrative_area_level_2',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Queensland',
 *         short_name: 'QLD',
 *         types: [
 *           'administrative_area_level_1',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: 'Australia',
 *         short_name: 'AU',
 *         types: [
 *           'country',
 *           'political',
 *         ],
 *       },
 *       {
 *         long_name: '4870',
 *         short_name: '4870',
 *         types: [
 *           'postal_code',
 *         ],
 *       },
 *     ],
 *   }
 * @param googleMapsAddress
 */
export const processGoogleAddressComponents = (googleMapsAddress: PlaceResult): {
  /* eslint-disable camelcase */
  flat_unit: string,
  floor_level: string,
  building_name: string,
  street_number: string,
  street_suffix: string,
  street_name: string,
  street_type: string,
  suburb: string,
  state: string,
  postcode: string,
  /* eslint-enable camelcase */
} => {
  const AddressComponentTypes = [
    'street_number', 'locality', 'administrative_area_level_1', 'postal_code', 'route',
    'subpremise', 'street_address', 'premise',
  ]
  const addressObj = {
    flat_unit: '',
    floor_level: '',
    building_name: '',
    street_number: '',
    street_suffix: '',
    street_name: '',
    street_type: '',
    suburb: '',
    state: '',
    postcode: '',
  }
  googleMapsAddress?.address_components?.forEach(addressComponent => {
    for (const type of addressComponent.types as typeof AddressComponentTypes) {
      switch (type) {
      case 'subpremise':
        addressObj.flat_unit = addressComponent.short_name
        break
      case 'street_number':
        addressObj.street_number = addressComponent.short_name
        break
      case 'locality':
        addressObj.suburb = addressComponent.short_name
        break
      case 'administrative_area_level_1':
        addressObj.state = addressComponent.short_name
        break
      case 'postal_code':
        addressObj.postcode = addressComponent.short_name
        break
      case 'route':
        // eslint-disable-next-line no-case-declarations
        const routeComp = addressComponent.long_name.split(' ')
        // eslint-disable-next-line no-case-declarations
        const streetType = routeComp[routeComp.length - 1]
        if (STREET_SUFFIX.includes(streetType)) {
          addressObj.street_suffix = streetType
          routeComp.pop()
        }
        addressObj.street_type = routeComp[routeComp.length - 1]
        routeComp.pop()
        addressObj.street_name = routeComp.join(' ')
        break
      }
    }
  })
  const isBuilding = googleMapsAddress?.types.some((type: string) => !AddressComponentTypes.includes(type))
  if (isBuilding) {
    addressObj.building_name = googleMapsAddress.name
  }
  if (!addressObj.state) {
    const islandState = googleMapsAddress?.address_components?.find(
      addressComp => addressComp.types.includes('country'))
    if (islandState?.short_name) {
      addressObj.state = islandState.short_name
    }
  }
  return addressObj
}
