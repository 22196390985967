import React, { useEffect, useState } from 'react'
import {
  AvatarIconWithStatusContent, AvatarIconWithStatusWrapper,
  Col,
  Section,
  Title,
  Row,
} from '@ix/ix-ui'
import styled from 'styled-components'
import { fetchRecordIcon } from '../../../helpers/record'
import SPUDRecordStatus from '../../../components/General/SPUDRecordStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { LinkedRecord } from '../../../../@types/LinkedRecord.type'

type Props = {
  title: string,
  loading: boolean,
  addNewComponent: React.ReactElement | null,
  linkedRecords: Array<LinkedRecord>
}

const LinkedRecordCardContainer = styled.div`
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #478FE5;
  background-color: #E3EDFA;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LinkedRecordCardIcon = styled.div`
  margin: 0 10px;
`

const LinkedRecordCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
`

const LinkedRecordCardID = styled.h4`
  font-size: 1.2em;
  margin: 0;
`

const LinkedRecordListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const LinkedRecordsContainer = styled.div`
  overflow-y: scroll;
  height: 80vh;
`

function LinkedRecordsList ({ title, addNewComponent, linkedRecords, loading }: Props): React.ReactElement {
  const [records, setRecords] = useState<Array<LinkedRecord>>([])

  useEffect(() => {
    if (loading) {
      setRecords([])
    } else {
      setRecords(linkedRecords)
    }
  }, [linkedRecords, loading])

  return (
    <Section style={{ padding: '0' }}>
      <LinkedRecordListTitleContainer>
        <div>
          <Title level={3} marginTop='0'>{title}</Title>
          {records.length > 0 && <span>Total ({records.length})</span>}
        </div>
        {addNewComponent}
      </LinkedRecordListTitleContainer>
      {loading && 'Loading...'}
      <LinkedRecordsContainer>
        {
          records.map((record: LinkedRecord, index: number) => (
            <LinkedRecordCardContainer key={`${record.name}_${index}`}>
              <Row align="center">
                <Col xs={3} xl={2} xxl={2} padding="0px">
                  <LinkedRecordCardIcon>
                    <AvatarIconWithStatusWrapper md backgroundColour='#fff' style={{ marginLeft: '-5px' }}>
                      <AvatarIconWithStatusContent>
                        {fetchRecordIcon(record.recordType, '2x')}
                      </AvatarIconWithStatusContent>
                    </AvatarIconWithStatusWrapper>
                  </LinkedRecordCardIcon>
                </Col>
                <Col xs={5} xl={6} xxl={6} padding="0px">
                  <LinkedRecordCardDetails>
                    <LinkedRecordCardID>
                      <span><strong>ID: </strong> {record.id}</span>
                    </LinkedRecordCardID>
                    <Title level={4} marginTop='0'>
                      {record.name}
                    </Title>
                    {record.referral_enabled && <div>
                      Referral enabled <FontAwesomeIcon icon={faNetworkWired as IconProp} color='#4F4F4F'/>
                    </div>}
                  </LinkedRecordCardDetails>
                </Col>
                <Col xs={4} xl={4} xxl={4} padding="0px">
                  <SPUDRecordStatus
                    status={record.status || 'Not set'}
                    active={record.is_active}
                    issId={record.iss_id}
                  />
                </Col>
              </Row>
            </LinkedRecordCardContainer>
          ))
        }
      </LinkedRecordsContainer>
    </Section>
  )
}

export default LinkedRecordsList
