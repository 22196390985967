import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Title,
  Button, Text,
} from '@ix/ix-ui'
import CsvDownload from 'react-json-to-csv'
import styled from 'styled-components'
import { format } from 'date-fns'
import {
  DuplicateRowType,
  DuplicateType,
  FailedUpdateType, spudAPI,
  SuccessfulImportType,
} from '../../../../services/spud.service'
import DuplicateRows, { PostProcessRowType } from '../ImportRecords/DuplicateRows'
import { SendMessage } from 'react-use-websocket'

type Props = {
  failedResults: Array<FailedUpdateType>,
  successfulResults?: Array<SuccessfulImportType>,
  duplicates?: DuplicateType,
  recordType: string
  actionType: string,
  sendMessage: SendMessage
  dismissPopup: () => void
}

const ExportCSVButton = styled(CsvDownload)`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`
const ExpandMessagesButton = styled(Button)`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
    background: none;
    border: none;
  }
  :focus {
    box-shadow: none;
  }
`

const DuplicateContainer = styled.div`
  padding: 2em;
  border: 2px solid ${props => props.theme.colors.accentColor};
  background-color: lightgrey;
  width: 100%;
`

function BulkUpdateFindReplaceReview (
  {
    failedResults,
    recordType,
    actionType,
    successfulResults,
    duplicates,
    sendMessage,
    dismissPopup,
  }: Props) {
  const [expandFailedMessages, setExpandFailedMessages] = useState(true)
  const [expandSuccessMessages, setExpandSuccessMessages] = useState(false)
  const [duplicateOverrides, setDuplicateOverrides] = useState<Array<PostProcessRowType>>([])
  const [loading, setLoading] = useState(false)
  const [reImported, setReImported] = useState(false)

  const csvExportFailedFileName = `failed-${recordType}-bulk-update-${format(new Date(), 'd/M/yyyy')}.csv`
  const csvExportSuccessFileName = `${recordType}-bulk-update-${format(new Date(), 'd/M/yyyy')}.csv`

  useEffect(() => {
    if (successfulResults && actionType === 'imported') {
      setExpandFailedMessages(false)
    }
  }, [successfulResults])

  const formatReason = (failedResult: FailedUpdateType) => {
    if (Array.isArray(failedResult.reason)) {
      return failedResult.reason.map((reasonObj) => {
        if (!Array.isArray(reasonObj)) {
          return Object.entries(reasonObj).map(([reasonKey, reasonValue]) => (
            <li key={reasonKey}>
              {reasonValue} (field: {reasonKey})
            </li>
          ))
        } else {
          return reasonObj.map((nestedObj, rowIndex: number) => {
            return Object.entries(nestedObj).map(([nestedKey, nestedValue]) => (
              <li key={nestedKey}>
                Failed to push to ISS4 due to:{' '}
                {nestedValue} (field: {nestedKey} {rowIndex + 1})
              </li>
            ),
            )
          },
          )
        }
      })
    }
    return <li>{failedResult.reason} (field: {failedResult?.field})</li>
  }

  const CsvDuplicateReport = (props: {label: string, duplicates: DuplicateType}) => {
    const csvFileName = `duplicates-${format(new Date(), 'd/M/yyyy')}.csv`
    const duplicateValues = Object.values(props.duplicates).flatMap((dupe: Array<DuplicateRowType>) => {
      return dupe.map((value: DuplicateRowType) => {
        if (value.matchingRow && Array.isArray(value.matchingRow)) {
          return {
            ...value,
            matchingRow: `[${value.matchingRow.join(', ')}]`,
          }
        }
        return value
      })
    })
    return (
      <ExportCSVButton
        data={duplicateValues}
        aria-label={props.label}
        filename={csvFileName}
      >
        Export duplicate report csv
      </ExportCSVButton>
    )
  }

  return (
    <Row>
      <Col>
        {failedResults.length > 0 && <Row>
          <Col>
            <Row>
              <Col
                direction='row'
                justify='space-between'
                align='baseline'
              >
                <Title level={3} marginTop='0'>
                  Failed {recordType}s
                </Title>
                <div>
                  <ExpandMessagesButton onClick={() => setExpandFailedMessages(!expandFailedMessages)}>
                    {expandFailedMessages ? 'hide errors' : 'show errors'}
                  </ExpandMessagesButton>
                  {failedResults.length > 0 && <ExportCSVButton data={failedResults} filename={csvExportFailedFileName}>
                    Export csv
                  </ExportCSVButton>}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>}
        {failedResults.length > 0 &&
          <Row style={{ maxHeight: expandFailedMessages ? '20vh' : 'auto', overflowY: 'scroll' }}>
            {expandFailedMessages && <div>
              <strong>Errors</strong>
              <ol>
                {failedResults.map(failedResult => (
                  <li key={failedResult.record_id}>
                    {failedResult?.name} ({failedResult.record_id})
                    <ul>
                      {formatReason(failedResult)}
                    </ul>
                  </li>
                ))}
              </ol>
            </div>}
          </Row>}
        {actionType === 'Imported' && duplicates && Object.keys(duplicates).length > 0 && !reImported &&
          <Row>
            <DuplicateContainer>
              <Row>
                <Col>
                  <Title level={3} marginTop='0'>
                    Duplicate rows
                  </Title>
                </Col>
                <Col align='end'>
                  <CsvDuplicateReport label='primary' duplicates={duplicates} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text>
                    Select duplicate rows and hit re-import to force an import on those rows.
                  </Text>
                </Col>
              </Row>
              <Row style={{ maxHeight: '20vh', overflowY: 'scroll' }}>
                <Col>
                  {Object.entries(duplicates).map(([duplicateType, duplicatesValue], index) => (
                    <DuplicateRows
                      key={`${duplicateType}_${index}`}
                      duplicateType={duplicateType}
                      recordType={recordType}
                      duplicateGroup={duplicatesValue}
                      duplicateOverrides={duplicateOverrides}
                      setDuplicateOverrides={setDuplicateOverrides}
                      duplicateCheckType='post'
                    />
                  ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    loading={loading}
                    active={duplicateOverrides.length}
                    disabled={!duplicateOverrides.length}
                    onClick={() => {
                      try {
                        setLoading(true)
                        sendMessage(JSON.stringify({
                          type: 'import_record',
                          message: 'import',
                        }))
                        spudAPI.reImportRows(recordType, duplicateOverrides)
                        setReImported(true)
                        setLoading(false)
                        dismissPopup()
                      } catch (e) {
                        setLoading(false)
                      }
                    }}
                  >
                    Re-import
                  </Button>
                </Col>
              </Row>
            </DuplicateContainer>
          </Row>}
        {actionType === 'Imported' && successfulResults && <Row>
          <Col>
            <Row>
              <Col
                direction='row'
                justify='space-between'
                align='baseline'
              >
                <Title level={3} marginTop='0'>
                  Imported {recordType}s
                </Title>
                <div>
                  <ExpandMessagesButton onClick={() => setExpandSuccessMessages(!expandSuccessMessages)}>
                    {expandSuccessMessages ? 'hide successes' : 'show successes'}
                  </ExpandMessagesButton>
                  {successfulResults.length > 0 &&
                    <ExportCSVButton data={successfulResults} filename={csvExportSuccessFileName}>
                      Export csv
                    </ExportCSVButton>}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>}
        {actionType === 'Imported' && successfulResults &&
          <Row style={{ maxHeight: expandSuccessMessages ? '20vh' : 'auto', overflowY: 'scroll' }}>
            {expandSuccessMessages && <div>
              <strong>Successful imports</strong>
              <ol>
                {successfulResults.map(successfulResult => (
                  <li key={successfulResult.record_id}>
                    {successfulResult?.name} ({successfulResult.record_id})
                    <ul>
                      {successfulResult.message}
                    </ul>
                  </li>
                ))}
              </ol>
            </div>}
          </Row>}
      </Col>
    </Row>
  )
}

export default BulkUpdateFindReplaceReview
