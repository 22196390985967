import * as React from 'react'
import { Router } from 'react-router-dom'
import { SpudTheme, StyleProvider } from '@ix/ix-ui'
import history from './helpers/history'
import Routes from './routes/Routes'
import AppContextProvider from './components/AppContextProvider'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from '../keycloak'

const App = (): React.ReactElement => {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={{
      checkLoginIframe: false,
      enableLogging: true,
      silentCheckSsoFallback: false,
      onLoad: 'login-required',
    }}
    >
      <AppContextProvider>
        <Router history={history}>
          <StyleProvider theme={SpudTheme}>
            <Routes />
          </StyleProvider>
        </Router>
      </AppContextProvider>
    </ReactKeycloakProvider>
  )
}

export default App
