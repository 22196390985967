
import React, { useEffect, useState } from 'react'
import Select, { ActionMeta, GroupBase, components, InputProps } from 'react-select'
import { ComponentProps, UseAsyncPaginateParams, withAsyncPaginate } from 'react-select-async-paginate'
import { fetchFormDataStructure, getFormConfig, getPageType } from '../../helpers/record'
import { Option } from '../forms/AddEditRecord.type'
import {
  Title, Alert, Button,
} from '@ix/ix-ui'
import { SPUDOnSaveResponseDetails, SPUDRecord } from '../../../@types/SPUDRecord.type'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { useAuth } from '../../helpers/auth'
import { LinkedRecord } from '../../../@types/LinkedRecord.type'
import { UserType } from '../../services/user.service'
import { transactionParams } from '../../services/transactions.service'

type Props = {
  label: string,
  highlight?: boolean | undefined,
  required?: boolean | {
      value: boolean,
      message: string
    } | undefined,
  fetchOptionsAsync?: ((
    params: transactionParams,
    filter: string | null,
    abortController: AbortController
  ) => Promise<{
    data: {
      results: Array<UserType> | Array<SPUDRecord<
          SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails
        >> | Array<{ name: string}>,
      next: string | null,
    },
  }>),
  options?: Option[],
  onChange?: (values: number | string | null | Option | (Array<string | number | Option | null>)) => void,
  selectedOption: Array<Option> | undefined | Option | null,
  isMulti: boolean,
  disabled: boolean,
  recordType?: string | undefined,
  fieldName?: string | undefined,
  canCreateNew?: boolean,
  createNewFunction?: (
    recordType: string | undefined,
    formData: { data: { [x: string]: unknown } }
  ) => Promise<{
    data: SPUDOnSaveResponseDetails
  }>,
  asyncParams?: {
    recordType: string,
  },
  formatDisplayName?: <T extends Record<string, string>>(value: Option & T) => string,
  formatValueName?: (value: Option) => string,
  formData?: SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>
  linkedRecords?: Array<LinkedRecord>,
  customStyleOverride?: { [x: string]: unknown },
  isBulkUpdate?: boolean,
  isClearable?: boolean,
  customIdentifier?: 'id' | 'iss_id'
}

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean,
  > =
    & CreatableProps<OptionType, IsMulti, Group>
    & UseAsyncPaginateParams<OptionType, Group, Additional>
    & ComponentProps<OptionType, Group, IsMulti>
    & { cacheOptions: boolean }

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false,
  >(props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>) => React.ReactElement;

const SPUDAsyncPaginateCreatable = withAsyncPaginate(CreatableSelect) as AsyncPaginateCreatableType
export const SPUDAsyncSelect = withAsyncPaginate(Select) as AsyncPaginateCreatableType
const Input = (props: InputProps<Option>) => <components.Input {...props} isHidden={false} />

function SPUDAutoComplete (
  {
    label,
    highlight,
    options,
    fetchOptionsAsync,
    onChange,
    selectedOption,
    isMulti,
    createNewFunction,
    recordType,
    canCreateNew = false,
    asyncParams = {
      recordType: '',
    },
    formatDisplayName,
    formatValueName,
    fieldName,
    formData,
    disabled,
    linkedRecords,
    customStyleOverride,
    isBulkUpdate,
    isClearable = true,
    customIdentifier,
  }: Props): React.ReactElement {
  const { userRole } = useAuth()

  const [warningMessage, setWarningMessage] = useState<React.ReactElement | null>(null)
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [itemsToRemove, setItemsToRemove] = useState<readonly Option[]>([])

  const generateElementId = (): string => (
    `spud-autocomplete-${label}`
  )

  const [storedOptions, setStoredOptions] = useState<Array<Option>>([])
  const [inputValue, setInputValue] = useState('')

  const onUpdateWithCreate = (
    selection: Option | null,
  ) => {
    if (selection) {
      if (selection.__isNew__) {
        const defaultPayload = fetchFormDataStructure(fieldName)
        const formConfig = getFormConfig(fieldName)
        const payloadObj: {[x: string]: unknown} = {}
        formConfig?.forEach(group => {
          const hiddenFields = group.fields.filter(field => field.type === 'hidden')
          hiddenFields.forEach(hiddenField => {
            payloadObj[hiddenField.name] = hiddenField.defaultValue
          })
        })
        payloadObj.date_last_updated = new Date()
        payloadObj.name = selection.name
        const formData = { ...defaultPayload, data: payloadObj }
        createNewFunction?.(fieldName, formData).then(created => {
          const newId = created?.data && created?.data.revision.record
          if (newId) {
            onChange?.(newId)
            setStoredOptions([{ id: newId, name: selection.name }, ...storedOptions])
          }
        })
      } else {
        onChange?.(selection.id)
      }
    } else {
      onChange?.(null)
    }
    setInputValue(selection ? selection.name : '')
  }

  const onUpdate = (
    selection: Option | null,
  ) => {
    onChange?.(selection?.id || selection)
  }

  const onMultiUpdate = <T extends readonly Option[]>(
    selections: T,
    actionMeta: ActionMeta<Option>,
  ) => {
    const { action, removedValue } = actionMeta
    let performUpdate = true
    if ((action === 'remove-value' || action === 'clear') && !!(formData?.iss_id)) {
      if (['Updater', 'Editor'].includes(userRole) && fieldName === 'datasets') {
        performUpdate = false
        setWarningMessage(<span>Dataset within a published record cannot be deleted.</span>)
      } else if (userRole === 'Administrator' && recordType === 'site') {
        performUpdate = false
        if (removedValue?.name && linkedRecords?.find(record => record.datasets?.includes(removedValue?.name))) {
          setWarningMessage(
            <span>
              <em style={{ padding: '0 3px' }}>{removedValue?.name || ''}</em> is also mapped to the
              {' '}
              linked service(s). Are you sure you want to delete?
            </span>,
          )
        } else {
          setWarningMessage(
            <span>
              Are you sure you want to remove the
              <em style={{ padding: '0 3px' }}> {removedValue?.name || ''}</em> dataset from this site?
            </span>,
          )
        }
        setDisplayConfirmation(true)
        setItemsToRemove(selections)
      }
    } else {
      setWarningMessage(null)
      setDisplayConfirmation(false)
      setItemsToRemove([])
    }
    if (performUpdate) {
      onChange?.(selections.map((item: Option) => formatValueName?.(item) || item))
    }
  }

  const loadOptions = async <T, >(
    loadedOptions: ReadonlyArray<T>,
    inputValue: string, newOptions: Array<Option> = [],
  ) => {
    const abortController = new AbortController()

    const numberOfLoadedOptions = (fieldLabel: string, numLoadedOptions: number): number => {
      // 'Nobody' option in the 'Allocated to' Field caused the numLoadedOptions to exceed the actual number of options by one
      // Obtain the correct number of options by subtracting one
      if (fieldLabel === 'Allocated to' && numLoadedOptions > 15) {
        return numLoadedOptions - 1
      }
      return numLoadedOptions
    }

    const response = await fetchOptionsAsync?.({
      ...asyncParams,
      details: {
        name: inputValue,
      },
      parentId: formData?.site?.id,
      recordType: asyncParams.recordType,
      limit: 15,
      offset: numberOfLoadedOptions(label, loadedOptions.length),
      isBulkUpdate: isBulkUpdate,
    }, null, abortController)
    if (response) {
      for (const option of response.data.results as
        Array<SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails>> &
        Array<Option> & Array<Record<string, string>>) {
        if (asyncParams?.recordType && asyncParams.recordType !== 'service') {
          newOptions.push({
            id: option.id,
            name: option?.update?.data?.name || option?.name || 'Unknown record',
          })
        } else if (customIdentifier) {
          newOptions.push({
            id: option?.[customIdentifier],
            name: formatDisplayName?.(option) || option?.name,
          })
        } else {
          newOptions.push({
            id: option.name,
            name: formatDisplayName?.(option) || option?.name,
          })
        }
      }
      setStoredOptions([...storedOptions, ...newOptions])
    }
    return {
      newOptions,
      hasMore: response ? !!response.data.next : false,
    }
  }

  const fetchDropdownOptions = async <T, >(
    inputValue: string,
    loadedOptions: ReadonlyArray<T>,
  ): Promise<{options: Array<Option>, hasMore: boolean}> => {
    const { newOptions, hasMore } = await loadOptions(loadedOptions, inputValue, [])
    return {
      options: newOptions,
      hasMore: hasMore,
    }
  }

  /**
   * Format an array of strings into the react-select { value,label } format
   *
   * for example the datasets are saved as ['QLD', 'VIC'] in the record data object
   * so it needs to be changed into:
   * [
   *  {
   *    name: 'QLD',
   *    id: 'QLD',
   *  },{
   *    name: 'VIC',
   *    id: 'VIC',
   *  }
   * ]
   *
   * Otherwise it will just return what ever is passed into it
   * @param selectedValues
   */
  const mapValuesToReactSelectOptions = (
    selectedValues: Array<string> | Array<Option>,
  ): Array<Option> => {
    return selectedValues.map((val) =>
      (typeof val === 'string'
        ? { id: val, name: options?.find(option => option && option.id === val)?.name || val }
        : val),
    )
  }

  const mapValueToReactSelectOption = (
    selectedValue: string | Option | Option[],
  ): Option | undefined => {
    if (typeof selectedValue === 'string') {
      const option = options?.find(option => option && option.id === selectedValue)
      return { id: selectedValue, name: (option && option.name) || selectedValue }
    }
    return selectedValue as Option
  }

  const showHighlight = (): boolean => {
    if (highlight) {
      if ((Array.isArray(selectedOption) && !selectedOption.length) || !selectedOption) {
        return true
      }
    }
    return false
  }

  const customStyle = {
    control: () => ({
      border: `${showHighlight() ? '3px' : '1px'} solid ${showHighlight() ? '#ff9595' : '#3a8ae8'}`,
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
    }),
  }

  /***
   * Because we store the linked record as an ID and
   * the dropdown requires the data to be in the form of
   *
   * {
   *   id: number
   *   name: string
   * }
   *
   * We need to convert the id to that format.
   * Either via the linked record on the record details page
   * or by searching for the selected option in the currently loaded options.
   * @param option
   */
  const getLinkedRecordValue = (option: Array<Option> | undefined | Option | null): Option | null => {
    const linkedRecordType = fieldName as 'organisation' | 'site'
    if (fieldName && formData && formData?.[linkedRecordType]) {
      if (!Array.isArray(option) && formData?.[linkedRecordType]?.id === option) {
        if (typeof formData[linkedRecordType]?.name === 'string') {
          return {
            id: formData[linkedRecordType]?.id as number,
            name: formData[linkedRecordType]?.name as string,
          }
        }
      }
    }
    return storedOptions.find(opt => opt.id === option) || null
  }

  /**
   * Highlight text workaround thanks to this codesandbox
   * https://codesandbox.io/s/react-select-editable-and-selectable-6nfdv?file=/src/App.js:966-979
   * @param inputValue
   * @param action
   */
  const onInputChange = (inputValue: string, { action }: { action: string }) => {
    if (action === 'input-change') {
      setInputValue(inputValue)
    }
  }

  /**
   * A unique use case to ensure that the saved org gets set
   */
  useEffect(() => {
    if (fetchOptionsAsync) {
      if (canCreateNew) {
        setInputValue(formData?.organisation ? formData?.organisation.name : '')
      }
    }
  }, [formData?.organisation])

  const renderSelect = () => {
    if (fetchOptionsAsync) {
      if (canCreateNew) {
        return (
          <SPUDAsyncPaginateCreatable
            cacheOptions
            styles={customStyleOverride || customStyle}
            debounceTimeout={1000}
            id={generateElementId() || 'autocomplete'}
            className='form-autocomplete'
            loadOptions={fetchDropdownOptions}
            defaultOptions
            value={getLinkedRecordValue(selectedOption)}
            getOptionLabel={(option: Option) => option.name }
            // The getOptionValue has to be a string
            getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString() }
            formatCreateLabel={ (inputValue: string) => (
              `Create ${inputValue}`
            ) }
            onCreateOption={(inputValue: string) => {
              onUpdateWithCreate({ id: null, name: inputValue, __isNew__: true })
            }}
            getNewOptionData={(inputValue: string) => ({
              id: null,
              name: `Create new ${getPageType(fieldName)} '${inputValue}'`,
              __isNew__: true,
            })}
            isClearable={isClearable}
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            onChange={onUpdateWithCreate}
            isDisabled={disabled}
            inputValue={inputValue}
            onInputChange={onInputChange}
            controlShouldRenderValue={false}
            components={{
              Input,
            }}
          />
        )
      } else {
        if (isMulti) {
          return (
            <SPUDAsyncSelect
              cacheOptions={false}
              styles={customStyleOverride || customStyle}
              id={generateElementId() || 'autocomplete'}
              className='form-autocomplete'
              loadOptions={fetchDropdownOptions}
              debounceTimeout={1000}
              value={selectedOption && Array.isArray(selectedOption)
                ? mapValuesToReactSelectOptions(selectedOption)
                : []}
              getOptionLabel={(option: Option) => option.name}
              // The getOptionValue has to be a string
              getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
              onChange={onMultiUpdate}
              isMulti={isMulti}
              closeMenuOnSelect={false}
              isDisabled={disabled}
              isClearable={isClearable}
            />
          )
        } else {
          return (
            <SPUDAsyncSelect
              cacheOptions
              styles={customStyleOverride || customStyle}
              id={generateElementId() || 'autocomplete'}
              className='form-autocomplete'
              loadOptions={fetchDropdownOptions}
              defaultOptions
              debounceTimeout={1000}
              value={selectedOption || null}
              getOptionLabel={(option: Option) => option.name}
              // The getOptionValue has to be a string
              getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
              onChange={onUpdate}
              isDisabled={disabled}
              isClearable={isClearable}
            />
          )
        }
      }
    } else {
      if (isMulti) {
        return (
          <Select
            styles={customStyleOverride || customStyle}
            id={generateElementId() || 'autocomplete'}
            className='form-autocomplete'
            options={options}
            value={selectedOption && Array.isArray(selectedOption) ? mapValuesToReactSelectOptions(selectedOption) : []}
            getOptionLabel={(option: Option) => option.name }
            // The getOptionValue has to be a string
            getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString() }
            onChange={onMultiUpdate}
            isMulti={true}
            isClearable={isClearable}
            closeMenuOnSelect={false}
            isDisabled={disabled}
          />
        )
      }
      return (
        <Select
          styles={customStyleOverride || customStyle}
          id={generateElementId() || 'autocomplete'}
          className='form-autocomplete'
          onChange={onUpdate}
          options={options}
          isClearable={isClearable}
          defaultValue={selectedOption && mapValueToReactSelectOption(selectedOption)}
          value={selectedOption && mapValueToReactSelectOption(selectedOption)}
          getOptionLabel={(option: Option) => option.name }
          // The getOptionValue has to be a string
          getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
          isDisabled={disabled}
        />
      )
    }
  }

  return (
    <div>
      <label htmlFor={generateElementId() || 'autocomplete'}>
        <Title level={4}>{label}</Title>
      </label>
      {warningMessage && <Alert type='error'>
        <Title level={4} marginTop='0' colour='#fff'>
          {warningMessage}
        </Title>
        {displayConfirmation && <div>
          <Button
            onClick={() => {
              onChange?.(itemsToRemove.map((item: Option) => formatValueName?.(item) || item))
              setWarningMessage(null)
              setDisplayConfirmation(false)
              setItemsToRemove([])
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setWarningMessage(null)
              setDisplayConfirmation(false)
              setItemsToRemove([])
            }}
          >
            No
          </Button>
        </div>}
      </Alert>}
      {renderSelect()}
    </div>
  )
}

export default SPUDAutoComplete
