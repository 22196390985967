import React from 'react'
import { Alert, Button, Col, Row, Title } from '@ix/ix-ui'
import { Controller, ControllerRenderProps, SubmitHandler, useForm, FieldErrors } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDInputField } from '../../../helpers/record'
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import SPUDAutoComplete from '../../../components/General/SPUDAutoComplete'
import SPUDDatePicker from '../../../components/General/SPUDDatePicker'
import { Option } from '../../../components/forms/AddEditRecord.type'
import toast, { Toaster } from 'react-hot-toast'
import SPUDToastNotification from '../../../components/General/SPUDToastNotification'
import { isAfter } from 'date-fns'

type EmailCampaignFormData = {
  state: string | null,
  fromDate: Date | null,
  toDate: Date | null,
  num_sites: number | null,
  max_services: number | null,
  date: Date | null,
}

const STATE_OPTIONS = [
  { id: 'ACT', name: 'Australian Capital Territory' },
  { id: 'NSW', name: 'New South Wales' },
  { id: 'NT', name: 'Northern Territory' },
  { id: 'QLD', name: 'Queensland' },
  { id: 'SA', name: 'South Australia' },
  { id: 'TAS', name: 'Tasmania' },
  { id: 'VIC', name: 'Victoria' },
  { id: 'WA', name: 'Western Australia' },
]

const FieldDesc = styled.div`
  padding: 0.3em 0 2em;
  color: #666;
`

const customStyle = {
  control: () => ({
    border: '1px solid #3a8ae8',
    borderRadius: 3,
    display: 'flex',
    padding: 1,
    marginTop: 5,
    textAlign: 'left',
  }),
}

const StyledButton = styled(Button)`
  margin-bottom: 3em;
  &.disabled-button {
    cursor: not-allowed;
    &:hover {
      background: #E0E6EE;
    }
  }
`

// Allow only numbers
const patternRegex = /^[0-9]+$/

function EmailCampaignForm () {
  const methods = useForm<EmailCampaignFormData>({
    mode: 'all',
  })

  // Set the default FromDate value to the first date of six months ago
  const defaultFromDate = new Date()
  defaultFromDate.setMonth(defaultFromDate.getMonth() - 6)
  defaultFromDate.setDate(1)

  // Set the default ToDate value to the last date of six months ago
  const defaultToDate = new Date()
  defaultToDate.setMonth(defaultToDate.getMonth() - 6)
  defaultToDate.setDate(new Date(defaultToDate.getFullYear(), defaultToDate.getMonth() + 1, 0).getDate())

  const validateRange = (fromDate: Date | null, toDate: Date | null): boolean => {
    let validRange = true
    if (
      fromDate && toDate
    ) {
      validRange = isAfter(toDate, fromDate)
    }
    return validRange
  }

  const validateDate = (value: Date | null): boolean => {
    if (value && (value > new Date())) {
      return false
    }
    return true
  }

  const renderError = (errorLabel: string, formField: string) => {
    const { formState: { errors } } = methods
    const error = errors?.[formField as keyof FieldErrors<EmailCampaignFormData>]

    if (error) {
      let errorMessage = ''

      if (error.type === 'required') {
        errorMessage = `${errorLabel} is required.`
      } else if (error.type === 'min') {
        errorMessage = 'Please select a value that is no less than 0.'
      } else if (error.type === 'pattern') {
        errorMessage = error.message || ''
      } else if (error.type === 'dateNotFuture') {
        errorMessage = 'Future dates not allowed'
      }

      if (errorMessage) {
        return (
          <Alert type="error" style={{ marginTop: -15, marginBottom: 20 }}>
            {errorMessage}
          </Alert>
        )
      }
    }

    return null
  }

  const onSubmit: SubmitHandler<EmailCampaignFormData> = (data) => {
    // Will replace console.log with endpoint
    console.log('form data', data)
    methods.reset()
    toast.custom(
      <SPUDToastNotification
        title="Success"
        message={
          <span>
            Email campaign has been created
          </span>
        }
        success
      />,
      {
        position: 'bottom-right',
      },
    )
  }

  return (
    <form
      style={{ maxWidth: 545 }}
      onSubmit={methods.handleSubmit((data) => onSubmit(data))}
    >
      <Title level={2}>New email campaign</Title>
      <Row>
        <Col style={{ marginBottom: '2em' }}>
          <Controller
            name='state'
            control={methods.control}
            defaultValue={null}
            rules={{ required: true }}
            render={({ field }) =>
              <SPUDAutoComplete
                label="Select State"
                customStyleOverride={customStyle}
                options={STATE_OPTIONS}
                isMulti={false}
                disabled={false}
                selectedOption={methods.getValues('state') as Option | null}
                {...field}
              />}
          />
          {methods.formState.errors?.state &&
            <Alert type="error">
              Please select state
            </Alert>
          }
        </Col>
      </Row>
      <Row>
        <Col md={5} xs={6}>
          <Controller
            name='fromDate'
            control={methods.control}
            defaultValue={defaultFromDate}
            rules={{
              required: true,
              validate: {
                dateNotFuture: validateDate,
                dateRange: (value) => validateRange(value, methods.getValues('toDate')),
              },
            }}
            render={({ field }) =>
              <Row>
                <Col style={{ padding: 0 }} align='baseline'>
                  <div style={{ marginRight: '1em' }}>
                    <Title level={4} marginTop='0px'>Site Last Updated from</Title>
                  </div>
                  <SPUDDatePicker
                    formElement={{
                      name: 'fromDate',
                      label: 'Site Last Updated from',
                      type: 'date',
                      rules: { required: true },
                      defaultValue: defaultFromDate,
                      maxDate: new Date(),
                      dateFormat: 'dd/MM/yyyy',
                    }}
                    fullWidth={false}
                    field={field as unknown as ControllerRenderProps}
                    disabled={false}
                    trigger={(value:string) => methods.trigger(value as 'fromDate')}
                  />
                  <FieldDesc>dd/mm/yyyy</FieldDesc>
                </Col>
              </Row>
            }
          />
          {renderError('Date', 'fromDate')}
        </Col>
        <Col justify='center' align='left' md={2} xs={6}
          style={{ alignSelf: 'center', marginBottom: '17px', paddingLeft: '10px' }}
        >
          <div>
            <FontAwesomeIcon icon={faArrowRight as IconProp} size='2x'/>
          </div>
        </Col>
        <Col md={5} xs={12}>
          <Controller
            name='toDate'
            control={methods.control}
            defaultValue={defaultToDate}
            rules={{
              required: true,
              validate: {
                dateNotFuture: validateDate,
                dateRange: (value) => validateRange(methods.getValues('fromDate'), value),
              },
            }}
            render={({ field }) =>
              <Row>
                <Col style={{ padding: 0 }} align='baseline'>
                  <div style={{ marginRight: '1em' }}>
                    <Title level={4} marginTop='0px'>Site Last Updated to</Title>
                  </div>
                  <SPUDDatePicker
                    formElement={{
                      name: 'toDate',
                      label: 'Site Last Updated to',
                      type: 'date',
                      rules: { required: true },
                      defaultValue: defaultToDate,
                      maxDate: new Date(),
                      dateFormat: 'dd/MM/yyyy',
                    }}
                    fullWidth={false}
                    field={field as unknown as ControllerRenderProps}
                    disabled={false}
                    trigger={(value:string) => methods.trigger(value as 'toDate')}
                  />
                  <FieldDesc>dd/mm/yyyy</FieldDesc>
                </Col>
              </Row>
            }
          />
          {renderError('Date', 'toDate')}
        </Col>
      </Row>

      {!validateRange(
        methods.getValues('fromDate'),
        methods.getValues('toDate'),
      ) && <Row style={{ marginTop: -20 }}>
        <Col>
          <Alert type="error">
            Date range is incorrect
          </Alert>
        </Col>
      </Row>}

      <Row>
        <Col>
          <Controller
            name='num_sites'
            control={methods.control}
            defaultValue={20}
            rules={{
              required: true,
              min: 0,
              pattern: {
                value: patternRegex,
                message: 'Please enter a valid number.',
              },
            }}
            render={({ field }) =>
              <SPUDInputField
                type="number"
                label='Number of Sites'
                required={true}
                min={0}
                {...field}
              />}
          />
          <FieldDesc>Number of sites with matching email</FieldDesc>
          {renderError('Number of Sites', 'num_sites')}
        </Col>
      </Row>
      <Row>
        <Col>
          <Controller
            name='max_services'
            control={methods.control}
            defaultValue={20}
            rules={{
              required: true,
              min: 0,
              pattern: {
                value: patternRegex,
                message: 'Please enter a valid number.',
              },
            }}
            render={({ field }) =>
              <SPUDInputField
                type="number"
                label='Max Number of Services'
                required={true}
                min={0}
                {...field}
              />}
          />
          <FieldDesc>Max number of services with per site</FieldDesc>
          {renderError('Max Number of Services', 'max_services')}
        </Col>
      </Row>
      <Toaster />
      <StyledButton
        aria-label='Create Campaign'
        active={methods.formState.isValid}
        disabled={!methods.formState.isValid}
        type='submit'
        className={methods.formState.isValid ? '' : 'disabled-button'}
      >
        Create campaign
      </StyledButton>
    </form>
  )
}

export default EmailCampaignForm
