import React, { useEffect, useState } from 'react'
import pluralize from 'pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import BulkUpdateFindReplaceReview from './BulkUpdateFindReplaceReview'
import { DuplicateType, FailedUpdateType, SuccessfulImportType } from '../../../../services/spud.service'
import styled from 'styled-components'
import { SPUDWebSocketMessage } from '../../../../context/AppContext.type'
import { SendMessage } from 'react-use-websocket'

const BulkUpdateReviewCard = styled.div`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  min-width: 40%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const BulkUpdateReviewHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const BulkUpdateReviewTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
`

const BulkUpdateReviewCloseButtonContainer = styled.div`
  padding: 5px;
`
const BulkUpdateReviewCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%;
  }
`

const BulkUpdateReviewContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
`

const BulkUpdateReviewContent = styled.div`
  height: 100%;
  width: 100%;
`

const BulkUpdateReviewControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.colors.accentColor};
  padding: 1em;
`

const BulkUpdateReviewControlButton = styled.button<{confirm: boolean}>`
  font-weight: bold;
  border: none;
  background-color: ${props => props.confirm ? props.theme.dialogConfirmColour : props.theme.colors.active};
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

const BulkUpdateReviewConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

type BulkUpdateReviewProps = {
  dismissPopup: () => void,
  websocketMessages: Array<SPUDWebSocketMessage<FailedUpdateType & SuccessfulImportType & DuplicateType>>
  backgroundTaskResults: SPUDWebSocketMessage<{
    updated: number,
    failed: number,
    action: string,
    record_type: string
  }>,
  sendMessage: SendMessage
}

function BulkUpdateReview (
  {
    websocketMessages,
    backgroundTaskResults,
    dismissPopup,
    sendMessage,
  }: BulkUpdateReviewProps) {
  const [failedUpdates, setFailedUpdates] = useState<Array<FailedUpdateType>>([])
  const [successfulResults, setSuccessfulResults] = useState<Array<SuccessfulImportType>>([])
  const [duplicates, setDuplicates] = useState<DuplicateType>({})
  const actionType = backgroundTaskResults.message.action
  const recordType = backgroundTaskResults.message.record_type
  const numUpdated = backgroundTaskResults.message.updated
  const numUpdatedTotal = (backgroundTaskResults.message.failed + backgroundTaskResults.message.updated) || 0

  useEffect(() => {
    if (websocketMessages) {
      const failedPushes: Array<FailedUpdateType> = websocketMessages?.filter(message => {
        return message.type === 'task.failed' && typeof message.message === 'object' && message.message?.reason
      }).map(failedPush => failedPush.message)
      const successfulImports: Array<SuccessfulImportType> = websocketMessages?.filter(message => {
        return message.type === 'task.success' && typeof message.message === 'object'
      }).map(successfulImport => successfulImport.message)
      const duplicateImports: SPUDWebSocketMessage<DuplicateType> | undefined = websocketMessages?.find(message => {
        return message.type === 'task.duplicate' && typeof message.message === 'object'
      })
      setFailedUpdates(failedPushes)
      setSuccessfulResults(successfulImports)
      if (duplicateImports && duplicateImports.message) {
        setDuplicates(duplicateImports.message as DuplicateType)
      }
    }
  }, [websocketMessages])

  return <BulkUpdateReviewCard aria-label='update-records'>
    <BulkUpdateReviewHeader>
      <BulkUpdateReviewTitle>
        <div>
          Bulk {actionType?.toLowerCase() || 'action'} results
        </div>
        {recordType && numUpdatedTotal && <div>
          {numUpdated}/{numUpdatedTotal} {pluralize(recordType, numUpdatedTotal)}
          {' '}
          {actionType?.toLowerCase() || 'action'}
        </div>}
      </BulkUpdateReviewTitle>
      <BulkUpdateReviewCloseButtonContainer>
        <BulkUpdateReviewCloseButton
          onClick={() => {
            setFailedUpdates([])
            dismissPopup()
          }}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </BulkUpdateReviewCloseButton>
      </BulkUpdateReviewCloseButtonContainer>
    </BulkUpdateReviewHeader>
    <BulkUpdateReviewContentContainer>
      <BulkUpdateReviewContent>
        <BulkUpdateFindReplaceReview
          failedResults={failedUpdates}
          successfulResults={successfulResults}
          duplicates={duplicates}
          recordType={recordType}
          actionType={actionType}
          sendMessage={sendMessage}
          dismissPopup={dismissPopup}
        />
      </BulkUpdateReviewContent>
    </BulkUpdateReviewContentContainer>
    <BulkUpdateReviewControlBar>
      <BulkUpdateReviewConfirmButtonGroup>
        <BulkUpdateReviewControlButton
          confirm={true}
          onClick={() => {
            setFailedUpdates([])
            dismissPopup()
          }}
        >
          Close
        </BulkUpdateReviewControlButton>
      </BulkUpdateReviewConfirmButtonGroup>
    </BulkUpdateReviewControlBar>
  </BulkUpdateReviewCard>
}

export default BulkUpdateReview
