
import React from 'react'
import styled from 'styled-components'

interface SPUDRecordStatusContainerProps {
  header: boolean | undefined
  inline: boolean | undefined
  table: boolean | undefined
}

const SPUDRecordStatusContainer = styled.div<SPUDRecordStatusContainerProps>`
  padding: ${props => {
    if (props.inline) return '0px 0px 0px 1em'
    if (props.table) return '0'
    return '20px 15px'
  }};
  height: ${props => props.inline ? '2em' : '100%'};
  justify-content: ${props => props.table ? 'flex-start' : 'center'};
  align-items: center;
  background-color: ${props => props.header ? '#E3EDFA' : 'auto'};
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1440px) {
    padding: ${props => props.inline ? '0px 0px 0px 1em' : '0 5px'};
    justify-content: flex-start;
  }
`

interface SPUDRecordStatusIndicatorProps {
  status: string | undefined
}

const SPUDRecordStatusIndicator = styled.div<SPUDRecordStatusIndicatorProps>`
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-right: 10px;
  background-color: ${props => {
    if (props.status === 'draft') return '#478fe5'
    if (props.status === 'published') return '#25af06'
    if (props.status === 'deleted') return '#d90e0e'
    return 'orange'
  }};
  @media (max-width: 1440px) {
    width: 0.7em;
    height: 0.7em;
    margin-right: 5px;
  }
`

const SPUdRecordStatusBrackets = styled.span`
  font-size: 2em;
`

type Props = {
  status: string,
  issId: number | null,
  active?: boolean | undefined,
  header?: boolean
  inline?: boolean
  table?: boolean
 }

function SPUDRecordStatus ({ status, active, header, inline, table, issId }: Props): React.ReactElement {
  const currentStatus = active ? status || 'draft' : 'deleted'

  const formatStatus = (): React.ReactElement => {
    return table
      ? <h4 style={{ margin: '3px 0px' }}>{currentStatus.toUpperCase()}  {!issId ? '(new)' : ''}</h4>
      : <h4>{status.toUpperCase()}  {!issId ? '(new)' : ''}</h4>
  }

  const renderInlineHeaderStatus = () => (
    <>
      <SPUdRecordStatusBrackets>( </SPUdRecordStatusBrackets>
      <SPUDRecordStatusIndicator status={currentStatus} /> {formatStatus()}
      <SPUdRecordStatusBrackets>) </SPUdRecordStatusBrackets>
    </>
  )

  return (
    <SPUDRecordStatusContainer header={header} inline={inline} table={table}>
      {inline
        ? renderInlineHeaderStatus()
        : <><SPUDRecordStatusIndicator status={currentStatus} /> {formatStatus()}</>
      }
    </SPUDRecordStatusContainer>
  )
}

export default SPUDRecordStatus
