import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Skeleton } from '@ix/ix-ui'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar as faStarSolid,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { CommentType, fetchComments } from '../../../../services/comment.service'
import SpudAvatar from '../../../../components/Avatar'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import SPUDToastNotification from '../../../../components/General/SPUDToastNotification'
import SPUDAddNewComment from './SPUDAddNewComment'
import { CommentIconButton, CommentLinkButton, SPUDTextArea } from './SPUDCommentHelperComponents'
import SPUDCommentTableActions from './SPUDCommentTableActions'
import { AxiosErrorWithObjectResponse, isAxiosErrorWithObjectResponsePayload } from '../../../../helpers/api'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const FormTableSeperator = styled.hr`
  width: 100%;
  border: 1px solid #8c9bad;
  height: 1px;
`

const CommentTableContainer = styled.div`
  border: solid 1px #8c9bad;
  font-size: 14px;
`

const CommentTableHeadingRow = styled(Row)`
  padding: 10px;
  font-weight: bold;
  font-size: 0.9em;
  @media screen and (max-width: 1500px) {
    font-size: 0.85em;
  }
`

const CommentTableRow = styled(Row)<{expanded: boolean, starred: boolean}>`
  padding: 10px;
  min-height: ${props => props.expanded ? 'auto' : '7em'};
  border-top: 1px solid #8c9bad;
  background: ${props => props.starred ? '#E3F5FF' : 'inherit'}
`

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #3a8ae8;
`

const CommentReadMore = styled(Button)`
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  line-height: 0;
  font-weight: bold;
  color: #2e6eb9;
  min-height: 10px;
  :hover {
    text-decoration: none;
    border: none;
    background: none;
    color: #3a8ae8;
  }
  :focus {
    box-shadow: none;
  }
`

type SPUDCommentsProps = {
  recordId: string | undefined,
  disabled: boolean,
}

function SPUDComments ({ recordId, disabled }: SPUDCommentsProps): React.ReactElement {
  const [comments, setComments] = useState<Array<CommentType>>([])
  const [editingComments, setEditingComments] = useState<{[id: number]: CommentType}>({})
  const [currentlyExpandedComment, setCurrentlyExpandedComment] = useState<{[id: number]: boolean}>({})
  const [loading, setLoading] = useState(false)
  const [changeOrderLoading, setChangeOrderLoading] = useState(false)
  const [tableOrdering, setTableOrdering] = useState({
    starred: '-starred',
    commenting_user: '',
    comment_date: '-comment_date',
  })

  useEffect(() => {
    const getRecordComments = async () => {
      !changeOrderLoading && setLoading(true)
      const response = await fetchComments(recordId, tableOrdering)
      let comments = response.results
      if (tableOrdering.starred === '-starred') {
        const starredComments = response.results.filter(
          result => result.starred)
        const unStarredComments = response.results.filter(
          result => !result.starred)
        comments = starredComments.concat(unStarredComments)
      }
      setComments(comments)
      setLoading(false)
      setChangeOrderLoading(false)
    }

    if (recordId) {
      getRecordComments()
    }
  }, [tableOrdering, recordId])

  // Reset comments and start loading when the record id changes
  // This happens when changing between record types
  useEffect(() => {
    setComments([])
    setLoading(true)
  }, [recordId])

  const processError = (error: AxiosErrorWithObjectResponse | Error) => {
    let errorMessage
    if (isAxiosErrorWithObjectResponsePayload(error)) {
      const errorData = error?.response?.data
      if (typeof errorData.detail === 'string') {
        errorMessage = errorData?.detail || ''
      }
    } else {
      errorMessage = error.message
    }
    toast.custom(
      <SPUDToastNotification
        title="Error"
        message={
          <span>
            {errorMessage}
          </span>
        }
        error
      />,
      {
        position: 'bottom-right',
      },
    )
  }

  const renderCell = (
    commentObj: CommentType,
    type: 'starred'|'comment_date'|'comment',
  ): React.ReactElement | string => {
    switch (type) {
    case 'starred':
      if (editingComments?.[commentObj.id]) {
        return (
          <CommentIconButton onClick={() => {
            editingComments[commentObj.id].starred = !editingComments[commentObj.id].starred
            setEditingComments({ ...editingComments })
          }}
          >
            {editingComments[commentObj.id].starred
              ? <FontAwesomeIcon color='#3a8ae8' icon={faStarSolid as IconProp} />
              : <FontAwesomeIcon icon={faStar as IconProp} /> }
          </CommentIconButton>
        )
      }
      return commentObj.starred
        ? <FontAwesomeIcon color='#3a8ae8' icon={faStarSolid as IconProp} />
        : <FontAwesomeIcon icon={faStar as IconProp} />
    case 'comment_date':
      if (editingComments?.[commentObj.id]) {
        return (
          <CustomDatePicker
            dateFormat='dd/MM/yyyy'
            selected={new Date(editingComments[commentObj.id].comment_date)}
            maxDate={new Date()}
            onChange={(date: Date) => {
              let commentDate = date
              if (!commentDate) {
                commentDate = commentObj.comment_date
              }
              editingComments[commentObj.id].comment_date = commentDate
              setEditingComments({ ...editingComments })
            }}
            disabled={disabled}
          />
        )
      }
      return format(new Date(commentObj.comment_date), 'dd/MM/yyyy')
    case 'comment':
      if (editingComments?.[commentObj.id]) {
        return (
          <SPUDTextArea
            placeholder='Enter a comment...'
            value={editingComments[commentObj.id].comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              editingComments[commentObj.id].comment = event.target.value
              setEditingComments({ ...editingComments })
            }}
            disabled={disabled}
          />
        )
      }
      if (commentObj.comment.length > 150) {
        return <span style={{ wordBreak: 'break-all' }}>
          {currentlyExpandedComment?.[commentObj.id]
            ? commentObj.comment + ' '
            : commentObj.comment.substring(0, 100) + '... '}
          <CommentReadMore onClick={() => {
            if (!currentlyExpandedComment?.[commentObj.id]) {
              setCurrentlyExpandedComment({ ...currentlyExpandedComment, [commentObj.id]: true })
            } else {
              setCurrentlyExpandedComment({
                ...currentlyExpandedComment,
                [commentObj.id]: !currentlyExpandedComment?.[commentObj.id],
              })
            }
          }}
          >
            {currentlyExpandedComment?.[commentObj.id] ? 'Read less' : 'Read more' }
          </CommentReadMore>
        </span>
      }
      return <span style={{ wordBreak: 'break-all' }}>
        {commentObj.comment}
      </span>
    }
  }

  return (
    <div>
      <Row>
        <SPUDAddNewComment
          loading={loading}
          setComments={setComments}
          processError={processError}
          comments={comments}
          recordId={recordId}
          tableOrdering={tableOrdering}
          disabled={disabled}
        />
      </Row>
      <Row>
        <Col padding='0'>
          <FormTableSeperator />
        </Col>
      </Row>
      <Row>
        <Col padding='0'>
          <Button
            disabled={changeOrderLoading}
            active={tableOrdering.starred === '-starred'}
            onClick={() => {
              const starredValue = tableOrdering.starred === '-starred' ? 'starred' : '-starred'
              setTableOrdering({
                ...tableOrdering,
                starred: starredValue,
                comment_date: '',
                commenting_user: '',
              })
              setChangeOrderLoading(true)
            }}
          >
            Starred to the top
          </Button>
        </Col>
      </Row>
      <Row>
        <Col padding='0'>
          <CommentTableContainer>
            <CommentTableHeadingRow>
              <Col justify='center' align='center'>
                Starred
              </Col>
              <Col justify='center' align='center'>
                <CommentLinkButton disabled={changeOrderLoading} onClick={() => {
                  let orderValue = ''
                  if (!tableOrdering.commenting_user) {
                    orderValue = 'commenting_user'
                  } else if (tableOrdering.commenting_user === 'commenting_user') {
                    orderValue = '-commenting_user'
                  } else if (tableOrdering.commenting_user === '-commenting_user') {
                    orderValue = 'commenting_user'
                  }
                  setTableOrdering({ ...tableOrdering, commenting_user: orderValue })
                  setChangeOrderLoading(true)
                }}
                >
                  User {tableOrdering.commenting_user === 'commenting_user'
                    ? <FontAwesomeIcon icon={faChevronUp as IconProp} />
                    : <FontAwesomeIcon icon={faChevronDown as IconProp} />
                  }
                </CommentLinkButton>
              </Col>
              <Col flex={2} justify='center' align='flex-start'>
                <CommentLinkButton disabled={changeOrderLoading} onClick={() => {
                  let orderValue = ''
                  if (!tableOrdering.comment_date) {
                    orderValue = 'comment_date'
                  } else if (tableOrdering.comment_date === 'comment_date') {
                    orderValue = '-comment_date'
                  } else if (tableOrdering.comment_date === '-comment_date') {
                    orderValue = 'comment_date'
                  }
                  setChangeOrderLoading(true)
                  setTableOrdering({ ...tableOrdering, comment_date: orderValue })
                }}
                >
                  Date {tableOrdering.comment_date === 'comment_date'
                    ? <FontAwesomeIcon icon={faChevronUp as IconProp} />
                    : <FontAwesomeIcon icon={faChevronDown as IconProp} />
                  }
                </CommentLinkButton>
              </Col>
              <Col flex={5} >
                Comment
              </Col>
              <Col justify='center' align='center'>
                Actions
              </Col>
            </CommentTableHeadingRow>
            {loading && !comments.length && <CommentTableRow>
              <Col justify='center' align='center'>
                <FontAwesomeIcon icon={faCircleNotch as IconProp} fixedWidth spin size='2x' />
                <span style={{ marginTop: 5 }}>Loading comments...</span>
              </Col>
            </CommentTableRow>}
            {comments.map((commentObj: CommentType, index: number) => (
              <CommentTableRow
                align='center'
                key={`${commentObj.id}_${index}`}
                expanded={!!editingComments?.[commentObj.id] || currentlyExpandedComment?.[commentObj.id]}
                starred={
                  editingComments?.[commentObj.id]
                    ? editingComments[commentObj.id].starred
                    : commentObj.starred
                }
              >
                <Col justify='center' align='center'>
                  {changeOrderLoading ? <Skeleton /> : renderCell(commentObj, 'starred')}
                </Col>
                <Col justify='center' align='center' aria-label={commentObj.commenting_user.username}>
                  {changeOrderLoading
                    ? <Skeleton type='circle' />
                    : <SpudAvatar user={commentObj.commenting_user} disableAction />}
                </Col>
                <Col flex={2} justify='center' align='center' aria-label={commentObj.comment_date}>
                  {changeOrderLoading ? <Skeleton /> : renderCell(commentObj, 'comment_date')}
                </Col>
                <Col flex={5} justify='center' aria-label={commentObj.comment}>
                  {changeOrderLoading ? <Skeleton /> : renderCell(commentObj, 'comment')}
                </Col>
                <Col justify='center' align='center'>
                  {changeOrderLoading
                    ? <Skeleton />
                    : (
                      <SPUDCommentTableActions
                        comment={commentObj}
                        editingComments={editingComments}
                        setEditingComments={setEditingComments}
                        processError={processError}
                        comments={comments}
                        setComments={setComments}
                        recordId={recordId}
                        disabled={disabled}
                      />
                    )}
                </Col>
              </CommentTableRow>))}
          </CommentTableContainer>
        </Col>
      </Row>
    </div>
  )
}

export default SPUDComments
