import React, { useRef, useState } from 'react'
import { Title, Text } from '@ix/ix-ui'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Option } from '../forms/AddEditRecord.type'
import { withContext } from '../../context/AppContext'
import { searchForAddress } from '../../services/googleAPI.service'
import { ActionMeta, SelectInstance, SingleValue } from 'react-select'

type SPUDAddressAutocompleteProps = {
  label: string,
  onChange: (option: SingleValue<Option>,
             actionMeta: ActionMeta<unknown>) => void,
  findAddress?: typeof searchForAddress,
  googleAPI?: { isLoaded: boolean }
}

function SPUDAddressAutocomplete (
  {
    label,
    findAddress,
    googleAPI,
    onChange,
  }: SPUDAddressAutocompleteProps): React.ReactElement {
  const [error, setError] = useState('')
  const searchAddress = async (inputValue: string):
    Promise<{options: Array<Option>, hasMore: boolean}> => {
    if (googleAPI?.isLoaded) {
      try {
        const response = await findAddress?.(inputValue)
        if (response) {
          return {
            options: response.predictions.map(place => ({ id: place.place_id, name: place.description })),
            hasMore: false,
          }
        }
      } catch (error) {
        console.error(error)
        if (error instanceof Error) setError(error?.message)
      }
    }
    return {
      options: [],
      hasMore: false,
    }
  }

  const SPUDAddressAutocompleteRef = useRef<SelectInstance<Option>>(null)

  const elementId = `spud-location-autocomplete-${label}` || 'autocomplete'

  const customStyle = {
    control: () => ({
      border: '1px solid #3a8ae8',
      borderRadius: 3,
      display: 'flex',
      padding: 1,
      marginTop: 1,
    }),
  }

  return <>
    <label htmlFor={elementId}>
      <Title level={4} marginTop='1.35em'>{label}</Title>
      <Text>Search to automatically fill in the site address</Text>
    </label>
    <AsyncPaginate
      selectRef={SPUDAddressAutocompleteRef}
      id={elementId}
      className='form-autocomplete'
      loadOptions={searchAddress}
      debounceTimeout={1000}
      styles={customStyle}
      getOptionLabel={(option: Option) => option.name }
      getOptionValue={(option: Option) => !option?.id ? '' : option.id.toString()}
      isClearable={true}
      openMenuOnClick={false}
      noOptionsMessage={() =>
        'No locations found'
      }
      onChange={(value, actionMeta) => {
        onChange(value, actionMeta)
        if (actionMeta.action === 'clear') {
          // Due to a bug in the react-select component, blurring and then focusing
          // the select box is the only way to retain the cursor when the selected option is cleared
          // https://github.com/JedWatson/react-select/issues/3871#issuecomment-757648697
          SPUDAddressAutocompleteRef?.current?.blur()
          SPUDAddressAutocompleteRef?.current?.focus()
        }
      }}
    />
    {error}
  </>
}

export default withContext(SPUDAddressAutocomplete)
