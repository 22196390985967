import { areIntervalsOverlapping, format, isBefore, isDate, parse } from 'date-fns'
import { OpenHoursType } from './SPUDOpeningHours'
import _ from 'lodash'

export type DayOfWeek = {
  name: string,
  abbreviation: string,
}

export const DAYS_OF_THE_WEEK: Array<DayOfWeek> = [
  { name: 'Monday', abbreviation: 'M' },
  { name: 'Tuesday', abbreviation: 'T' },
  { name: 'Wednesday', abbreviation: 'W' },
  { name: 'Thursday', abbreviation: 'T' },
  { name: 'Friday', abbreviation: 'F' },
  { name: 'Saturday', abbreviation: 'S' },
  { name: 'Sunday', abbreviation: 'S' },
  { name: 'Public Holiday', abbreviation: 'PUB' },
]

export const parseDateValue = (val: string | number): Date | undefined => {
  if (val) {
    if (typeof val === 'number') {
      return new Date(val)
    } else {
      const parsedDate = parse(val, 'HH:mm', new Date())
      if (isDate(parsedDate)) {
        return parsedDate
      }
    }
  }
}

/**
 * Validate currently entered time to solve these potential issues:
 *  1. If the closed time is before the open time
 *  2. If the open time is after the close time
 * @param time
 * @param openCloseHours
 * @param isOpenHours
 * @return boolean
 */
export const checkCloseIsAfterOpen = (
  time: Date | undefined, openCloseHours: {
    open: string | number,
    close: string | number,
  }, isOpenHours: boolean): boolean => {
  if (time && isDate(time)) {
    if (openCloseHours?.open && !isOpenHours) {
      const parsedOpenTime = parseDateValue(openCloseHours.open)
      return !(parsedOpenTime && isBefore(time, parsedOpenTime))
    } else if (openCloseHours?.close && isOpenHours) {
      const parsedCloseTime = parseDateValue(openCloseHours.close)
      return !!(parsedCloseTime && isBefore(time, parsedCloseTime))
    }
  }
  return true
}

/**
 * Check if the time that is going to be added, doesn't overlap
 * e.g if 9 am - 5 pm is already added for a particular day check that
 * the new time doesn't overlap or is within 9 - 5
 * @param time
 * @param day
 * @param currentOpeningHours
 */
export const checkForExistingOrOverlappingTimes = (
  time: { open?: Date, close?: Date },
  day: string,
  currentOpeningHours: Array<OpenHoursType>,
): boolean => {
  const openingHours = _.groupBy(currentOpeningHours, 'day')
  const midnight = new Date(new Date().setHours(0, 0, 0, 0))
  return openingHours?.[day]?.some(hour => {
    if (hour?.open && hour?.close && time?.open && time?.close) {
      // Checking whether midnight to midnight has already been set to force it to be invalid
      // Otherwise check for overlapping like normal
      if (hour.open === '12:00 am' &&
        hour.close === '12:00 am') {
        return true
      } else if (
        (time.open.getHours() === midnight.getHours() && time.open.getMinutes() === midnight.getMinutes()) &&
        (time.close.getHours() === midnight.getHours() && time.close.getMinutes() === midnight.getMinutes())
      ) {
        return true
      } else {
        try {
          return areIntervalsOverlapping({
            start: parse(hour.open, 'HH:mm', new Date()),
            end: parse(hour.close, 'HH:mm', new Date()),
          }, {
            start: time.open, end: time.close,
          }, { inclusive: true })
        } catch (e) {
          return !checkCloseIsAfterOpen(time.close, hour, false)
        }
      }
    }
    return false
  })
}

// eslint-disable-next-line no-undef
export const scrollToAndHighlightTypedTime = (value: string, timeList: HTMLCollectionOf<Element>): void => {
  let node: HTMLElement

  const checkForAmOrPM = (child: HTMLElement) => {
    if (value.toLowerCase().includes('p') || value.toLowerCase().includes('m')) {
      if (child.innerHTML.toLowerCase().includes('pm')) {
        if (!node) {
          node = child
        }
      }
    } else {
      if (child.innerHTML.toLowerCase().includes('am')) {
        if (!node) {
          node = child
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeList[0]?.childNodes?.forEach((child: any) => {
    child.classList.remove('react-datepicker__time-list-item--selected')
    if (child.innerHTML.toLowerCase().includes(value.toLowerCase())) {
      // To ensure that 1 am is selected before 12 am we need to check the length of the value
      // entered and ensure that it selects the first time with a single digit hour
      if (value.length === 1 &&
        child.innerHTML.toLowerCase().substring(0, 2)[value.substring(0, 2).length] === ':' &&
        child.innerHTML.toLowerCase()[0].includes(value.toLowerCase())) {
        !node && checkForAmOrPM(child)
        // To ensure that 12 am is selected when two digits are entered (if the second digit is either a 1 or 2)
      } else if (value.length === 2 && child.innerHTML.toLowerCase().substring(0, 2).includes(value.toLowerCase())) {
        !node && checkForAmOrPM(child)
      } else if (value.length > 2) {
        if (value.substring(0, 2)[value.substring(0, 2).length] === ':' &&
          child.innerHTML.toLowerCase().substring(0, 2).includes(value.substring(0, 2))) {
          if (value.toLowerCase().includes('a') || value.toLowerCase().includes('p')) {
            node = child
          } else {
            checkForAmOrPM(child)
          }
        } else if (child.innerHTML.toLowerCase().substring(0, 2).includes(value.substring(0, 2))) {
          !node && checkForAmOrPM(child)
        }
      }
    }
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (node) {
    timeList[0].scrollTop = (node.offsetTop - 50)
    node.classList.add('react-datepicker__time-list-item--selected')
    node.setAttribute('aria-selected', 'true')
  }
}

// eslint-disable-next-line no-undef
export const getHighlightedTime = (time: Date, timeList: HTMLCollectionOf<Element>): Date => {
  let timeToUse: Date = time
  let currentlyHighlightedTime: Date | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeList[0]?.childNodes?.forEach((child: any) => {
    if (child.classList.contains('react-datepicker__time-list-item--selected')) {
      if (!currentlyHighlightedTime) {
        currentlyHighlightedTime = parse(child.innerHTML, 'hh:mm aaa', new Date())
      }
    }
  })
  if (currentlyHighlightedTime) {
    timeToUse = currentlyHighlightedTime
  }
  return timeToUse
}

/**
 * Convert to the saved 24hr time to 12hr to make it more readable
 * @param twentyFourHourTime
 */
export const convert24hrTo12hr = (twentyFourHourTime: string): string => {
  if (twentyFourHourTime && !isDate(twentyFourHourTime)) {
    try {
      return format(parse(twentyFourHourTime, 'HH:mm', new Date()), 'hh:mm aaa')
    } catch (e) {
      return twentyFourHourTime
    }
  }
  return twentyFourHourTime
}

export const handleMonthContainer = () => {
  // We need to remove the calendar from showing as there's a known bug
  // Which Has supposedly been fixed, but it's not
  // https://github.com/Hacker0x01/react-datepicker/issues/3759
  // where the time entered manually would cause an invalid Date if a letter is
  // added when the showTimesOnly prop is set
  // This did not happen in the past not sure why it's happening now
  // TODO comeback to this later on when we re-design the time pickers
  const monthContainer = document.getElementsByClassName('react-datepicker__month-container')
  if (monthContainer && monthContainer.length) {
    const month = monthContainer[0] as HTMLElement
    month.style.display = 'none'
  }
}
