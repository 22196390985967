import React, { useEffect, useState, useCallback } from 'react'
import {
  Title,
  Row,
  Col,
  Pagination,
  Button,
  Text,
  Skeleton,
} from '@ix/ix-ui'
import SPUDAutoComplete from './SPUDAutoComplete'
import { SPUDInputField } from '../../helpers/record'
import { SearchLGAs, searchLocation, SearchSuburbs } from '../../services/location.service'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Option } from '../forms/AddEditRecord.type'
import debounce from 'lodash.debounce'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'

export type SPUDGeographicCoverageProps = {
  title: string,
  recordType: string,
  formKey: string,
  highlight?: boolean,
  value: Array<string> | undefined,
  siteValues: SPUDSiteRecordDetails,
  callback: (name: string, params: Array<string>) => void,
  onFormSubmitted: boolean,
  disabled: boolean,
}

type GeographicType = {
  id: string,
  name: string,
  postcode?: string,
  state?: string,
  lga?: string,
  // eslint-disable-next-line camelcase
  location_type?: string,
}

const STATES = [
  { id: 'ACT', name: 'Australian Capital Territory' },
  { id: 'NSW', name: 'New South Wales' },
  { id: 'NT', name: 'Northern Territory' },
  { id: 'QLD', name: 'Queensland' },
  { id: 'SA', name: 'South Australia' },
  { id: 'TAS', name: 'Tasmania' },
  { id: 'VIC', name: 'Victoria' },
  { id: 'WA', name: 'Western Australia' },
]

const FILTER_STATES = [
  { id: 'ACT', name: 'ACT' },
  { id: 'NSW', name: 'NSW' },
  { id: 'NT', name: 'NT' },
  { id: 'QLD', name: 'QLD' },
  { id: 'SA', name: 'SA' },
  { id: 'TAS', name: 'TAS' },
  { id: 'VIC', name: 'VIC' },
  { id: 'WA', name: 'WA' },
]

const LocaleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  :hover {
    font-weight: bold;
  }
`

const LocaleChip = styled.div`
  border-radius: 3px;
  border: 1px solid #3a8ae8;
  padding: 5px;
  color: #4c1811;
  font-size: 14px;
  margin: 0.3em;
  background-color: #fff;
  height: 2em;
`

const LocaleChipButton = styled.button`
  margin-left: 1em;
  border: none;
  background: none;
  cursor: pointer;
  :hover {
    background: #e3edfa;
  }
`

const LocaleChipContainer = styled.div<{ highlight: boolean | undefined }>`
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #f7f4f4;
  padding: 5px;
  border: ${props => props.highlight ? '3px solid #ff9595' : 'inherit'};
  border-radius: 3px;
  width: 100%;
  min-height: 100px;
`
const SiteLocaleChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PAGE_SIZE = 10

function SPUDGeographicCoverage (
  {
    title,
    recordType,
    value,
    callback,
    formKey,
    siteValues,
    highlight,
    disabled,
    onFormSubmitted = false,
  }: SPUDGeographicCoverageProps): React.ReactElement {
  const [locations, setLocations] = useState<Array<GeographicType>>(STATES)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState('')
  const [selectedLga, setSelectedLga] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(0)

  const [filteredState, setFilteredState] = useState<Option | undefined | null>()

  const [selectedLocales, setSelectedLocales] = useState<Array<{
    state: string,
    name: string,
  }>>([])

  const fetchLga = async () => {
    setLoading(true)
    const response = await SearchLGAs(selectedState, page)
    setLoading(false)
    setTotal(response.count)
    setLocations(response.results)
  }

  const fetchSuburbs = async () => {
    setLoading(true)
    const response = await SearchSuburbs(selectedState, selectedLga, page)
    setLoading(false)
    setTotal(response.count)
    setLocations(response.results)
  }

  const searchForLocation = async (search: string, state: Option) => {
    if (state) {
      setLoading(true)
      const response = await searchLocation(search, (page - 1) * PAGE_SIZE, state.name)
      setLoading(false)
      setTotal(response.count)
      setLocations(response.results)
    }
  }

  useEffect(() => {
    if (searchTerm !== '' && filteredState) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      search(searchTerm, filteredState)
    }
  }, [searchTerm, filteredState])

  const search = useCallback(
    debounce((search: string, state: Option) => {
      searchForLocation(search, state)
    }, 200),
    [],
  )

  const reset = () => {
    setPage(0)
    setTotal(0)
    setSelectedState('')
    setSelectedLga('')
    setLocations(STATES)
  }

  useEffect(() => {
    if (selectedState && !selectedLga) {
      fetchLga()
    }
  }, [selectedState, page])

  useEffect(() => {
    if (selectedLga) {
      fetchSuburbs()
    }
  }, [selectedLga, page])

  useEffect(() => {
    if (filteredState && searchTerm) {
      searchForLocation(searchTerm, filteredState)
    }
  }, [page, filteredState])

  useEffect(() => {
    if (value) {
      const formattedValues = value.map(val => {
        const localeArray = val.split(', ')
        return {
          state: localeArray[0],
          name: localeArray[1],
        }
      })
      setSelectedLocales([...formattedValues])
    }
  }, [value])

  useEffect(() => {
    if (onFormSubmitted) {
      setFilteredState(null)
      setSearchTerm('')
      reset()
    }
  }, [onFormSubmitted])

  const LocationButton = ({ location }: { location: GeographicType }) => (
    <LocaleButton
      disabled={loading}
      onClick={() => {
        setTotal(0)
        if (!selectedState && location.id !== selectedState) {
          setSelectedState(location.id)
        } else if (!selectedLga && location.id !== selectedLga) {
          setSelectedLga(location.name)
        }
      }}
    >
      {loading ? <Skeleton /> : location.name}
    </LocaleButton>
  )

  const flattenLocales = (locales: Array<{
    state: string,
    name: string,
  }>): Array<string> => {
    return locales.map(locale => {
      return `${locale.state}, ${locale.name}`
    })
  }

  /**
   * Ensure that the correct value gets displayed in the column depending on what stage
   * the state -> suburb flow or the location has been searched
   * @param column
   * @param location
   * @return the column value
   */
  const renderColumnValue = (column: string, location: GeographicType): React.ReactElement | string => {
    const validLocationTypes = ['suburb', 'state', 'local government area', 'local health district']

    switch (column) {
    case 'state':
      if (selectedState) {
        return selectedState
      } else if (location?.state) {
        return location.state
      } else {
        return <LocationButton location={location}/>
      }
    case 'lga':
      if (loading) {
        return <Skeleton/>
      } else if (!selectedLga && selectedState) {
        return <LocationButton location={location}/>
      } else if (selectedLga) {
        return selectedLga
      } else if (location?.lga) {
        return location.lga
      }
      return '-'
    case 'postcode':
      if (loading) {
        return <Skeleton/>
      } else if (location?.postcode) {
        return location.postcode
      }
      return '-'
    case 'suburb':
      if (loading) {
        return <Skeleton/>
      } else if (selectedLga && selectedState) {
        return <LocationButton location={location}/>
      } else if (location?.postcode) {
        return location.name
      } else if (location.location_type && validLocationTypes.includes(location.location_type)) {
        return `${location.name} (${location.location_type})`
      }
      return '-'
    default:
      return '-'
    }
  }

  const AddButton = ({ location }: {location: GeographicType}) => {
    const alreadySetLocale = selectedLocales.find(selectedLocale =>
      selectedLocale.name === location.name &&
        (selectedLocale.state === location.state || selectedLocale.state === location.id),
    )

    if (alreadySetLocale) {
      return <Text>Already set</Text>
    }
    return (
      <Button
        disabled={loading} onClick={() => {
          const newLocales = [...selectedLocales, {
            state: selectedState || location.state || location.id,
            name: location.name,
          }]
          setSelectedLocales(newLocales)
          callback(formKey, flattenLocales(newLocales))
        }}
      >
        Add
      </Button>
    )
  }

  return <div>
    <Title level={4}>
      {title}
      <Button style={{ margin: '0 10px' }} onClick={() => {
        setSelectedLocales([])
        callback(formKey, [])
      }}
      >
        Delete all
      </Button>
    </Title>
    {siteValues?.catchment && recordType === 'service' && <Row>
      <Col>
        <Title level={5}>
          Site Geographic Coverage
        </Title>
        <SiteLocaleChipContainer>
          {siteValues?.catchment?.map(site =>
            <LocaleChip key={site}>
              {site}
            </LocaleChip>,
          )}
        </SiteLocaleChipContainer>
      </Col>
    </Row>}
    <Row>
      <LocaleChipContainer highlight={highlight && !selectedLocales.length} className='form-geo-coverage'>
        {selectedLocales.map((locale, index) => (
          <LocaleChip key={`${locale}_${index}`}>
            {locale.state}, {locale.name}
            <LocaleChipButton
              onClick={() => {
                const updatedLocales = [...selectedLocales]
                updatedLocales.splice(index, 1)
                setSelectedLocales([...updatedLocales])
                callback(formKey, flattenLocales(updatedLocales))
              }}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </LocaleChipButton>
          </LocaleChip>
        ))}
      </LocaleChipContainer>
    </Row>
    <Row style={{ marginBottom: '1em' }}>
      <Col md={3}>
        <SPUDAutoComplete
          label='State'
          options={FILTER_STATES}
          isMulti={false}
          selectedOption={filteredState}
          onChange={(values) => {
            if (typeof values === 'string') {
              setFilteredState({ id: values, name: values })
            } else {
              setFilteredState(null)
              reset()
            }
          }}
          disabled={disabled}
        />
      </Col>
      <Col md={7}>
        <SPUDInputField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value)
          }}
          id='search'
          name='search'
          label={<Title level={4} marginTop='1em'>Search</Title>}
          fullwidth
          value={searchTerm}
        />
        <Text>
          To search for a location, please select a state from the dropdown and then begin typing,
          or browse using the table below by first selecting a state.
        </Text>
      </Col>
      <Col md={2} style={{ marginTop: '1.5em' }}>
        <Button primary onClick={() => reset()} style={{ height: '3.5em', width: '100%' }}>
          Clear
        </Button>
      </Col>
    </Row>
    <Row>
      <Col direction='row'>
        <LocaleButton
          disabled={loading}
          tabIndex={-1}
          onClick={() => {
            reset()
          }}
        >
          State
        </LocaleButton>
      </Col>
      <Col direction='row'>
        {selectedLga
          ? (
            <LocaleButton
              disabled={loading}
              onClick={() => {
                setSelectedLga('')
                setTotal(0)
                fetchLga()
              }}
            >LGA</LocaleButton>
          )
          : <Text size='14px'>LGA</Text>
        }
      </Col>
      <Col>
        <Text size='14px'>
          Postcode
        </Text>
      </Col>
      <Col direction='row'>
        <Text size='14px'>
          Suburb
        </Text>
      </Col>
      <Col direction='row' justify='flex-end'>
        <Text size='14px'>Action</Text>
      </Col>
    </Row>
    <Row>
      <Col>
        {locations.map(location => (
          <Row key={location.id} align='center' style={{
            border: '1px solid #e0e6ee',
          }}
          >
            <Col>
              <Text padding='1em'>
                {renderColumnValue('state', location)}
              </Text>
            </Col>
            <Col>
              <Text padding='1em'>
                {renderColumnValue('lga', location)}
              </Text>
            </Col>
            <Col>
              <Text padding='1em'>
                {renderColumnValue('postcode', location)}
              </Text>
            </Col>
            <Col direction='row'>
              {renderColumnValue('suburb', location)}
            </Col>
            <Col direction='row' justify='flex-end'>
              <AddButton location={location}/>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
    <Row>
      <Pagination total={[total]} onPageChange={(param: {currentPage: number}) => {
        setPage(param.currentPage)
      }} pageSize={PAGE_SIZE}
      />
    </Row>
  </div>
}

export default SPUDGeographicCoverage
