import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import UserListPage from '../pages/UsersPage'
import PrivateRoute from './PrivateRoute'
import LoginPage from '../pages/LoginPage'
import SideNav from '../components/SideNav'
import { Page, Content, Dialog } from '@ix/ix-ui'
import DashboardPage from '../pages/DashboardPage'
import EmailCampaignPage from '../pages/AdminPage/EmailCampaign'
import AdminHomePage from '../pages/AdminPage/AdminHomePage'
import RecordsPage from '../pages/RecordsPage/ListView'
import RecordDetails from '../pages/RecordsPage/RecordDetails'
import AppContext from '../context/AppContext'
import { AppContextType } from '../context/AppContext.type'
import SearchResultsPage from '../pages/SearchResultsPage'

type NoMatchType = {
    location: {
        pathname: string,
    },
}

const NoMatch = ({ location }: NoMatchType, props: AppContextType): React.ReactElement | null => (
  <Page>
    <SideNav onClearFailedMessages={function (): void {
      throw new Error('Function not implemented.')
    }} {...props}
    />
    <Content>
      <h1>Page not found: {location.pathname}</h1>
    </Content>
  </Page>
)

const Routes = (): React.ReactElement => {
  const appContext = useContext(AppContext)

  useEffect(() => {
    appContext?.dialogOptions.show && window.scrollTo(0, 0)
  }, [appContext?.dialogOptions])

  return (
    <>
      <Switch>
        <Redirect
          from="/"
          to="/dashboard"
          exact
        />
        <PrivateRoute
          path="/dashboard"
          component={DashboardPage}
          appContext={appContext}
          pageType='dashboard'
        />
        <PrivateRoute
          path="/records/:recordType/record/:revisionId"
          exact
          component={RecordDetails}
          appContext={appContext}
          pageType='record-details'
        />
        <PrivateRoute
          path="/records/:recordType/:action"
          component={RecordDetails}
          appContext={appContext}
          pageType='record-details-add-edit'
          exact
        />
        <PrivateRoute
          path="/records/:recordType"
          component={RecordsPage}
          appContext={appContext}
          pageType='record-list'
        />
        <PrivateRoute
          path="/administration/emailcampaign"
          component={EmailCampaignPage}
          appContext={appContext}
          pageType='emailcampaign'
        />
        <PrivateRoute
          path="/:accessgroup/admin/users"
          component={UserListPage}
          appContext={appContext}
          exact
          pageType='users'
        />
        <PrivateRoute
          path="/administration"
          component={AdminHomePage}
          appContext={appContext}
          pageType='administration'
          exact
        />
        <PrivateRoute
          path="/search/:searchTerm"
          component={SearchResultsPage}
          appContext={appContext}
          exact
          pageType='search'
        />
        <Route
          path="/login"
          render={(props) => <LoginPage {...props}/>}
        />
        <Route render={(props) =>
          <NoMatch {...props}/>}
        />
      </Switch>
      {
        appContext?.dialogOptions?.show && (
          <Dialog
            onDismiss={() => {
              appContext.setDialogOptions({ ...appContext.dialogOptions, show: false })
              appContext.dialogOptions.onDismiss && appContext.dialogOptions.onDismiss()
            }}
            title={appContext.dialogOptions.title}
            message={appContext.dialogOptions.description}
            type={appContext.dialogOptions.type || 'acknowledge'}
            onConfirm={(value: string) => {
              appContext.setDialogOptions({ ...appContext.dialogOptions, show: false })
              appContext.dialogOptions.onConfirm && appContext.dialogOptions.onConfirm(value)
            }}
          />
        )
      }
    </>
  )
}

export default Routes
