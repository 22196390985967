import React from 'react'

import {
  Button, Col, Row, Title, Toggle,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLevelUpAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { SPUDInputField } from '../../helpers/record'
import { SingleValue } from 'react-select'
import { Option } from '../forms/AddEditRecord.type'
import SPUDLocationAutocomplete, { LocationOptionType } from './SPUDLocationAutocomplete'
import styled from 'styled-components'
import { AngledArrowContainer } from './AngledArrowContainer'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type SPUDPostalAddressProps = {
  title: string,
  highlight: boolean,
  disabled: boolean,
}

export interface SPUDPostalAddressType {
  confidential: boolean
  line1: string
  line2: string
  state: string
  suburb: string
  postcode: string
}

const PostalAddressCard = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 0 6px 3px #eaeaea;
  padding: 1em;
`

const PostalAddressCardHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PostalAddressConfidentialToggle = styled(Toggle)`
  label {
    padding: 0;
  }
`

function SPUDPostalAddress ({ title, disabled, highlight = false }: SPUDPostalAddressProps): React.ReactElement {
  const { control, setValue, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'postal_addresses',
  })

  const mapValueToReactSelectOption = (
    selectedValue: string | undefined,
  ): Option => {
    return { id: selectedValue || null, name: selectedValue || '' }
  }

  return (
    <div>
      <Row style={{ marginTop: '1.5rem' }}>
        <Col direction='row'>
          <Title level={4}>
            {title}
          </Title>
          <div style={{ margin: '0 10px' }}>
            <Button active={!disabled} onClick={() => {
              append({
                confidential: false,
                address_line_1: '',
                address_line_2: '',
                state: '',
                suburb: '',
                postcode: '',
              })
            }}
            >
              <FontAwesomeIcon icon={faEnvelope as IconProp}/> Add Postal Address
            </Button>
          </div>
        </Col>
      </Row>
      {fields.map((postalAddress, index) => (
        <Row key={postalAddress.id} align='center'>
          <Col direction='row'>
            <AngledArrowContainer>
              <FontAwesomeIcon size='2x' rotation={90} icon={faLevelUpAlt as IconProp} color='#A3A3A3'/>
            </AngledArrowContainer>
            <PostalAddressCard>
              <PostalAddressCardHeader>
                <Button onClick={() => remove(index)} active={!disabled}>
                  <FontAwesomeIcon icon={faTimes as IconProp}/> Remove
                </Button>
              </PostalAddressCardHeader>
              <Row>
                <Col>
                  <Controller
                    name={`postal_addresses.${index}.address_line_1`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='addressLine1'
                        placeholder="Address Line 1"
                        type='text'
                        label={<Title level={4}>Address Line 1</Title>}
                        fullwidth
                        {...field}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Controller
                    name={`postal_addresses.${index}.address_line_2`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='addressLine2'
                        placeholder="Address Line 2"
                        type='text'
                        label={<Title level={4}>Address Line 2</Title>}
                        fullwidth
                        {...field}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Controller
                    name={`postal_addresses.${index}.suburb`}
                    control={control}
                    rules={{ required: false }}
                    render={() =>
                      <SPUDLocationAutocomplete
                        placeholder="Suburb"
                        value={mapValueToReactSelectOption(getValues(`postal_addresses.${index}.suburb`))}
                        label='Suburb'
                        highlight={highlight}
                        onChange={(value: SingleValue<LocationOptionType>) => {
                          if (value) {
                            setValue(`postal_addresses.${index}.suburb`, value.name)
                            setValue(`postal_addresses.${index}.state`, value.state)
                            setValue(`postal_addresses.${index}.postcode`, value.postcode)
                          }
                        }}
                        required
                        disabled={disabled}
                        multi={false}
                      />
                    }
                  />
                </Col>
                <Col>
                  <Controller
                    name={`postal_addresses.${index}.state`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='postalAddressState'
                        placeholder="State"
                        type='text'
                        label={<Title level={4}>State</Title>}
                        fullwidth
                        {...field}
                      />
                    }
                  />
                </Col>
                <Col>
                  <Controller
                    name={`postal_addresses.${index}.postcode`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) =>
                      <SPUDInputField
                        id='postalAddressPostcode'
                        placeholder="Postcode"
                        type='text'
                        label={<Title level={4}>Postcode</Title>}
                        fullwidth
                        {...field}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 25, marginLeft: -5 }}>
                  <Controller
                    name={`postal_addresses.${index}.confidential`}
                    control={control}
                    rules={{ required: false }}
                    render={() =>
                      <PostalAddressConfidentialToggle
                        padding='0'
                        offColor="#E3EDFA"
                        label={<Title level={4} marginTop='0'>Confidential</Title>}
                        handleChange={(checked: boolean) => {
                          setValue(`postal_addresses.${index}.confidential`, checked)
                        }}
                        checked={getValues(`postal_addresses.${index}.confidential`) || false}
                        onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
                          const checked = getValues(`postal_addresses.${index}.confidential`) || false
                          if (key.key === 'Enter') {
                            setValue?.(`postal_addresses.${index}.confidential`, !checked)
                          }
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                </Col>
              </Row>
            </PostalAddressCard>
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default SPUDPostalAddress
